import React from "react";
import ReportManager from "commons/components/ReportManager";

const filters = [
  {
    type: "select",
    name: "customers",
    key: "customer_id",
  },
];

const columns = [
  {
    name: "name",
    type: "text",
  },
  {
    name: "operations_count",
    type: "number",
  },
  {
    name: "operations_avg",
    type: "money",
  },
  {
    name: "operations_min",
    type: "money",
  },
  {
    name: "operations_max",
    type: "money",
  },
  {
    name: "operations_total",
    type: "money",
    highlight: true,
  },
  {
    name: "payments_count",
    type: "number",
  },
  {
    name: "payments_avg",
    type: "money",
  },
  {
    name: "payments_min",
    type: "money",
  },
  {
    name: "payments_max",
    type: "money",
  },
  {
    name: "payments_total",
    type: "money",
    highlight: true,
  },
  {
    name: "balance",
    type: "balance",
    highlight: true,
  },
];

export default function CustomersReport() {
  return (
    <ReportManager
      url="balance"
      title="customersReport"
      columns={columns}
      filters={filters}
      totals={[
        "operations_count",
        "operations_total",
        "payments_count",
        "payments_total",
        "balance",
      ]}
      filterByDate
      initialQuery={{ type: "customers" }}
    />
  );
}
