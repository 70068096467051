import React from "react";
import ReportManager from "commons/components/ReportManager";

const columns = [
  {
    name: "name",
    type: "text",
  },
  {
    name: "salaries_total",
    type: "money",
  },
  {
    name: "sales_total",
    type: "money",
    highlight: true,
  },
  {
    name: "purchases_total",

    type: "number",
  },
  {
    name: "payments_total",
    type: "money",
    highlight: true,
  },
  {
    name: "balance",
    type: "balance",
    highlight: true,
  },
];

export default function EmployeesReport({
  title = "employeesReport",
  permanent = true,
}) {
  return (
    <ReportManager
      url="balance"
      title={title}
      columns={columns}
      filters={[
        {
          type: "select",
          name: "employees",
          key: "employee_id",
          filters: { permanent },
          extras: {},
        },
      ]}
      totals={[
        "salaries_total",
        "sales_total",
        "purchases_total",
        "payments_total",
        "balance",
      ]}
      filterByDate
      initialQuery={{ type: "employees" }}
    />
  );
}
