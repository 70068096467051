export default {
  developed_by: "Developed By",
  language: "Language",
  dashboard: "Dashboard",
  settings: "Settings",
  username: "Username",
  password: "Password",
  oldPassword: "Old Password",
  passwordConfirm: "Password Confirmation",
  password_confirmation: "Password Confirmation",
  changePassword: "Change Password",
  login: "Login",
  logout: "Logout",
  roles: "Roles",
  "app-settings": "App Settings",
  appSettings: "App Settings",
  hr: "HR",
  manage: "Manage",
  finance: "Finance",
  policies: "Price Policies",
  policy: "Price Policy",
  policy_id: "Price Policy",
  sale_policy_id: "Sale Policy",
  purchase_policy_id: "Purchase Policy",
  "policies.name": "Price Policy",
  "customer-groups": "Customer Groups",
  customer_groups: "Customer Groups",
  "customer-group": "Customer Group",
  "customer_groups.name": "Customer Group",
  "users.username": "Username",
  group_id: "Customer Group",
  suppliers: "Suppliers",
  supplier: "Supplier",
  supplier_id: "Supplier",
  "supplier-payment": "Supplier Payment",
  customers: "Customers",
  customer: "Customer",
  accounts: "Accounts",
  account: "Account",
  transactions: "Transactions",
  transaction: "Transaction",
  departments: "Departments",
  department: "Department",
  department_id: "Department",
  "departments.name": "Department",
  employees: "Employees",
  employee: "Employee",
  users: "Users",
  facilities: "Facilities",
  facility: "Facility",
  businessPlan: "Business Plan",
  contractors: "Contractors",
  contractor: "Contractor",
  "form-templates": "Form Templates",
  "form-template": "Form Template",
  "print-templates": "Print Templates",
  "print-template": "Print Template",
  forms: "Forms",
  form: "Form",
  attendances: "Attendances",
  attendance: "Attendance",
  leaves: "Leaves",
  evaluations: "Evaluations",
  evaluation: "Evaluation",
  salaries: "Salaries",
  salary: "Salary",
  salary_lines: "Salary Items",
  salary_line: "Salary item",
  tasks: "Tasks",
  task: "Task",
  shifts: "Shifts",
  shift: "Shift",
  shift_id: "Shift",
  "shifts.name": "Shift",
  schedules: "Schedules",
  schedule: "Schedule",
  "leave-types": "Leave Types",
  "leave-type": "Leave Type",
  leave_type: "Leave Type",
  leaveType: "Leave Type",
  leave_type_id: "Leave Type",
  leave: "Leave",
  grades: "Grades",
  grade: "Grade",
  grade_id: "Grade",
  "grade-lines": "Grade Lines",
  "grades.name": "Grade",
  "vacation-days": "Vacation Days",
  vacationDay: "Vacation Day",
  "vacation-day": "Vacation Day",
  reports: "Reports",
  actionsReport: "Actions Report",
  "actions-report": "Actions Report",
  overviewReport: "Overview Report",
  "overview-report": "Overview Report",
  salesReport: "Sales Report",
  purchasesReport: "Purchases Report",
  formsReport: "Forms Report",
  tasksReport: "Tasks Report",
  saleLine: "Sale Line",
  priceInline: "Price Inline",
  chargeInline: "Charge Inline",
  id: "ID",
  name: "Name",
  address: "Address",
  type: "Type",
  phone: "Phone",
  primary_phone: "Primary Phone",
  secondary_phone: "Secondary Phone",
  email: "Email",
  noResources: "No Matchin Resources Found",
  rowsPerPage: "Rows per page",
  of: "Of",
  print: "Print",
  advancedSearch: "Advanced Search",
  field: "Field",
  fields: "Fields",
  before: "Before",
  after: "After",
  and: "And",
  or: "Or",
  line: "Line",
  contains: "Contains",
  exact: "Exact",
  value: "Value",
  applied_value: "Applied Value",
  equals: "Equals",
  less: "Less",
  greater: "Greater",
  search: "Search",
  cancel: "Cancel",
  andCondition: "Apply All Conditions",
  export: "Export",
  columns: "Columns",
  description: "Description",
  show: "Show",
  add: "Add",
  edit: "Edit",
  editor: "Edit",
  remove: "Remove",
  user: "المستخدم",
  role: "Role",
  permissions: "Permissions",
  filter: "Filter",
  label: "Label",
  selected: "Selected",
  new: "New",
  list: "List",
  delete: "Delete",
  loading: "Loading...",
  notes: "Notes",
  previous: "Previous",
  next: "Next",
  history: "History",
  report: "Report",
  credit_limit: "Credit Limit",
  dob: "Date of birth",
  joined: "Joined",
  terminated: "Terminated",
  qualifications: "Qualificaitons",
  custody: "Custody",
  nid: "National ID",
  avatar: "Avatar",
  date: "Date",
  reason: "Reason",
  categories: "Categories",
  category: "Category",
  "categories.name": "Category",
  category_id: "Category",
  products: "Products",
  "products.name": "Product",
  sales: "Sales",
  SALES: "Sales",
  purchases: "Purchases",
  PURCHASES: "Purchases",
  purchase: "Purchase",
  storage: "Storage",
  image: "Image",
  saleable: "Saleable",
  purchasable: "Purchasable",
  stockable: "Stockable",
  active: "Active",
  code: "Code",
  supplier_code: "Supplier Code",
  storage_code: "Storage Code",
  purchase_price: "Purchase Price",
  cost_price: "Cost Price",
  cost: "Cost",
  msrp: "MSRP",
  composite: "Composite",
  allow_fractions: "Allow Fractions",
  sale_price: "Sale Price",
  storage_ratio: "Storage Ratio",
  units: "Units",
  variants: "Variants",
  components: "Components",
  product_ratio: "Product Ratio",
  variant: "Varint",
  variant_ratio: "Variant Ratio",
  component: "Component",
  component_ratio: "Component Ratio",
  inventory: "Inventory",
  transfers: "Transfers",
  transfer: "Transfer",
  reference: "Reference",
  "sales.reference": "Reference",
  deduct: "Deduct",
  move: "Move",
  assemble: "Assemble",
  disassemble: "Disassemble",
  from: "From",
  to: "To",
  min_level: "Min Level",
  max_level: "Max Level",
  max_allowed: "Max Allowed",
  reorder_level: "Reorder Level",
  max_in_sale: "Max in sale",
  current_qty: "Current Quantity",
  change_qty: "Change",
  after_qty: "Quantity After",
  notice: "Notes",
  summary: "Summary",
  serial: "Serial",
  mfg_date: "MFG Date",
  exp_date: "EXP Date",
  quantity: "Quantity",
  showChangesOnly: "Show Changes Only",
  searchByCode: "Search by Code",
  searchByName: "Search By Name",
  showHasQuantityOnly: "عرض له رصيد",
  sale: "Sale",
  services: "Services",
  service: "Service",
  reference_number: "Reference",
  count: "Count",
  subtotal: "Subtotal",
  price: "Price",
  discount_total: "Discount Total",
  charge_total: "Charge Total",
  total: "Total",
  paid: "Paid",
  remaining: "Remainign",
  customer_balance: "Customer Balance",
  balance: "Balance",
  pay: "Pay",
  collect: "Collect",
  returns: "Returns",
  returned_amount: "Returned",
  firstBackupPath: "First Backup Path",
  secondBackupPath: "Second Backup Path",
  backupDuration: "Backup Duration",
  autoPrintOnPayment: "Auto Print On Payment",
  autoStockChange: "Auto Stock Change",
  autoFulfilStocks: "Auto Fulfil Stocks",
  autoPrintOnStockChange: "Auto Print On Stock Change",
  debit: "Debit",
  credit: "Credit",
  organize: "Organize",
  contacts: "Contacts",
  contact: "Contact",
  messages: "Messages",
  attachments: "Attachments",
  attachment: "Attachment",
  inbox: "Inbox",
  outbox: "Outbox",
  sender: "Sender",
  recipient: "Recipient",
  read: "Read Time",
  path: "Path",
  basic_salary: "Base Salary",
  base_salary: "Base Salary",
  sale_commission: "Sale Commission",
  commission: "Commission",
  commission_value: "Commission",
  allowed_late_minutes: "Allowed Late Minutes",
  annual_allowed: "Allowed Annually",
  applied: "Applied",
  min: "Min",
  max: "Max",
  always: "Always",
  extraHours: "Extra Hours",
  extraShifts: "Extra Shifts",
  "employees.name": "Employee",
  employee_id: "Employee",
  rate: "Rate",
  startsWith: "Starts With",
  starts: "Starts",
  started: "Started",
  ends: "Ends",
  finished: "Finished",
  poor: "Poor",
  fair: "Fair",
  good: "Good",
  excellent: "Excellent",
  due_amount: "Due Amount",
  payments: "Payments",
  refunds: "Refunds",
  amount: "Amount",
  amount_in_currency: "Amount In Currency",
  "form_templates.name": "Form Template",
  form_templates: "Form Templates",
  template: "Template",
  form_template_id: "Form Template",
  created: "Created",
  create: "Create",
  updated: "Updated",
  update: "Update",
  assignee: "Assignee",
  assigned_to: "Assigner",
  body: "Body",
  created_by: "Created By",
  assigner: "Assigner",
  due: "Due",
  reminder: "Reminder",
  completed: "Completed",
  not_completed: "Not Completed",
  duration_in_hours: "Duration in hours",
  active_from: "Active From",
  active_to: "Active To",
  facility_id: "Facility",
  enter: "Enter",
  exit: "Exit",
  hours: "Hours",
  hour: "Hour",
  operations: "Operations",
  catalog: "Catalog",
  discounts: "Discounts",
  discount: "Discount",
  taxes: "Taxes",
  tax: "Tax",
  installments: "Installments",
  installment: "Installemnt",
  print_name: "Print Name",
  conditions: "Conditions",
  min_price: "Min Price",
  max_price: "Max Price",
  avg_price: "AVG Price",
  avg_cost_price: "AVG Cost",
  min_quantity: "Min QTY",
  max_value: "Max Value",
  reward: "Reward",
  rewards: "Rewards",
  penalties: "Penalties",
  discount_value: "Discount Value",
  charge_value: "Charge Value",
  absolute: "Absolute Value",
  percentage: "Percentage",
  product: "Product",
  minimum_charge: "Minimum Charge",
  "asset-groups": "Asset Groups",
  "asset-group": "Asset Group",
  asset_group_id: "Asset Group",
  asset_group: "Asset Group",
  assets: "Assets",
  asset: "Asset",
  acquired: "Acquired",
  disposed: "Disposed",
  customer_id: "Customer",
  "customers.name": "Customer",
  sale_id: "Sale",
  due_date: "Due Date",
  paid_on: "Paid On",
  fines: "Fines",
  sessions: "Sessions",
  session: "Session",
  category_sorting: "Category Sort",
  difference: "Difference",
  offerings: "Offerings",
  subtract: "Subtract",
  charge_type: "Charge Type",
  value_type: "Value Type",
  ONCE: "Once",
  HOURLY: "Hourly",
  DAY: "Day",
  DAILY: "Daily",
  MONTHLY: "Monthly",
  MONTH: "Month",
  YEARLY: "Yearly",
  PIECE: "Piece",
  WEIGHT: "Weight",
  AREA: "Area",
  VOLUME: "Volume",
  threshold: "Threshold",
  allowNegativeStocks: "Allow Negative Stocks",
  utilizations: "Utilizations",
  utilization: "Utilization",
  actions: "Actions",
  action: "Action",
  comment: "Comment",
  unit_value: "Unit Value",
  total_value: "Total Value",
  table_index: "i",
  "stock-levels": "Stock Levels",
  shortages: "Shortages",
  movements: "Movements",
  expired: "Expired",
  entities: "Entities",
  misc: "Misc.",
  start: "Start",
  end: "End",
  WEEK: "Week",
  YEAR: "Year",
  CUSTOM: "Custom",
  duration: "Duration",
  duration_start: "Duration Start",
  duration_end: "Duration End",
  salesReports: "Sales Reports",
  purchasesReports: "Purchases Reports",
  salesByOperation: "Sales By Operation",
  salesByProduct: "Sales By Product",
  salesByCustomer: "Sales By Customer",
  salesByCategory: "Sales By Category",
  salesByUser: "Sales By User",
  purchasesByOperation: "Purchases By Operation",
  purchasesByProduct: "Purchases By Product",
  purchasesBySupplier: "Purchases By Supplier",
  purchasesByCategory: "Purchases By Category",
  purchasesByUser: "Purchases By User",
  profit_total: "Profit Total",
  collected: "Collected",
  tendered: "Tendered",
  profit: "Profit",
  print_all: "Print All",
  print_current: "Print Current",
  current: "Current",
  number_of_operations: "Number of Operations",
  CASH: "Cash",
  WIRE_TRANSFER: "Wire Transfer",
  CREDIT_CARD: "Credit Card",
  CHEQUE: "Cheque",
  payment_method: "Payment Method",
  method: "Method",
  minimum_ops: "Min Operation",
  maximum_ops: "Max Operation",
  avg_ops: "Avg Operation",
  accountsBalance: "Accounts Balance",
  customersReport: "Customers Balance",
  suppliersReport: "Suppliers Balance",
  "accounts.code": "Account Code",
  "accounts.name": "Account Name",
  "transactions.date": "Date",
  "sale-line-price": "Sale Line Price",
  "purchase-line-price": "Purchase Line Price",
  "sale-line": "Sale Line",
  "purchase-line": "Purchase Line",
  "suppliers.name": "Supplier",
  operation_type: "Type",
  operation_id: "ID",
  total_quantity: "Total Quantity",
  product_name: "Product Name",
  product_code: "Product Code",
  size: "Size",
  fontSize: "Font Size",
  permanent: "Permanent",
  penalty: "Penalty",
  commissions: "Commissions",
  operations_count: "Operations Count",
  operations_avg: "Avg operation",
  operations_min: "Min Operation",
  operations_max: "Max Operation",
  operations_total: "اجمالي Operations Total",
  payments_count: "Payment Count",
  payments_avg: "Avg Payment",
  payments_min: "Min Payment",
  payments_max: "Max Payment",
  payments_total: "Payments Total",
  add_by_shift: "Add By Shift",
  add_single: "Add Single",
  add_by_import: "Import",
  period: "Period",
  period_start_date: "Period Start Date",
  period_end_date: "Period End Date",
  net_salary: "Net Salary",
  "sales.date": "Date",
  entries: "Entries",
  on_subtotal: "On Subtotal",
  addHeading: "addHeading",
  addTextField: "addTextField",
  addDateField: "addDateField",
  addSingleChoose: "addSingleChoose",
  addMultiChoose: "addMultiChoose",
  addTextarea: "addTextarea",
  addTable: "Add Table",
  addSelect: "Add Select",
  addUpload: "Add File",
  rows: "Rows",
  showIndex: "Show Index",
  defaultValue: "Default Value",
  rowSpan: "Row Span",
  colSpan: "Column Span",
  addRow: "Add Row",
  addColumn: "Add Column",
  disabled: "Disabled",
  highlighted: "Highlighted",
  steps: "Steps",
  elapsed_duration: "Elapsed Duration",
  minute: "Minute",
  notification: "Notification",
  notifications: "Notifications",
  projects: "Projects",
  project: "Project",
  logo: "Logo",
  company_name: "Company Name",
  theme: "Theme",
  brand: "Brand",
  theme_main_color: "Theme Main Color",
  theme_secondary_color: "Theme Secondary Color",
  theme_background_color: "Theme Background Color",
  theme_surface_color: "Theme Surface Color",
  save: "Save",
  length: "Length",
  width: "Width",
  height: "Height",
  currency: "Currency",
  debit_in_currency: "Currency Debit",
  credit_in_currency: "Currency Credit",
  creator: "Creator",
  approver: "Approver",
  approve: "Approve",
  "service-locations": "Service Locations",
  service_locations: "Service Locations",
  "service-location": "Service Location",
  service_location: "Service Location",
  fulfilled: "Fulfilled",
  "audit-logs": "Audit Logs",
  auditable_type: "Item",
  auditable_id: "ID",
  event: "Event",
  currencies: "Currencies",
  currency_rate: "Currency Rate",
  currenciesBalance: "Currencies balance",
  mustHaveLines: "Operation Must Have Lines",
  "my-tasks": "My Tasks",
  "customer-payment": "Customer Payment",
  "employee-payment": "Employee Payment",
  "contractor-payment": "Contractor Payment",
  "access-groups": "Access Groups",
  access_groups: "Access Groups",
  "access-group": "Access Groups",
  "facilities.name": "Facilities",
  header: "Header",
  footer: "Footer",
  addText: "Add Text",
  addImage: "Add Image",
  addLine: "Add Line",
  content: "Content",
  MAX_IN_SALE: "Max in sale",
  NO_STOCKS: "No Enough Stocks",
  RETURN_QUANTITY: "Return Quantity",
  CANNOT_RETURN: "Can not return quantity",
  returned: "Returned",
  actual: "Actual",
  complete: "Complete",
  removeRow: "Remove Row",
  removeColumn: "Remove Column",
  closed: "Closed",
  connect: "Connect",
  contractorsReport: "Contractors Report",
  employeesReport: "Employees Report",
  sales_total: "Sales Total",
  purchases_total: "Purchases Total",
  salaries_total: "Salaries Total",
  close: "Close",
  open: "Open",
  "ongoing-services": "Ongoing Services",
  "ending-services": "Ending Services",
  "rules.required": "Required",
  "rules.min": "Doesn't Match minimum",
  "rules.max": "Doesn't Match Maximum",
  "rules.confirmed": "Confirmation Error",
  "rules.integer": "Integer Required",
  "rules.debit_credit_match": "Values Must Match",
};
