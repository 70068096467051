import React, { useState, useMemo, useEffect, useCallback } from "react";
import useTranslate from "commons/hooks/useTranslate";
import { sumField } from "commons/helpers/utils";
import dayjs from "dayjs";
import CardSection from "commons/components/CardSection";
import { Divider, Button, Grid, ButtonGroup } from "@material-ui/core";
import Stack from "commons/components/Stack";
import { FormMoneyField } from "commons/components/FormMoneyField";
import { TotalsLine } from "./TotalsCard";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import { DefaultFormSelectField } from "commons/components/FormSelectField";

export default function PaymentCard({ payments = [], total = 0, onPayment }) {
  const { t } = useTranslate();
  const [rate, setRate] = useState(100);
  const [currency, setCurrency] = useState(null);
  const [amountInCurrency, setAmountInCurrency] = useState(0);
  const [currencies] = useResourcesByQuery("currencies", true);

  const paid = useMemo(() => sumField("amount")(payments), [payments]);
  const remaining = useMemo(() => total - paid, [total, paid]);

  const onChangeAmountInCurrency = (val) => {
    setAmountInCurrency(val);
  };

  const onPay = () => {
    const inCurrency = Math.min(toRate(remaining * 100), amountInCurrency);
    const data = {
      date: dayjs(),
      method: "CASH",
      currency_id: currency,
      rate,
      amount_in_currency: inCurrency,
      amount: Math.round((inCurrency * rate) / 100),
    };
    onPayment(data);
    setAmountInCurrency(0);
  };

  const onCollect = () => {
    const data = {
      date: dayjs(),
      method: "CASH",
      currency_id: currency,
      rate,
      amount_in_currency: amountInCurrency,
      amount: Math.round((amountInCurrency * rate) / 100),
    };
    onPayment(data);
    setAmountInCurrency(0);
  };

  const onChangeCurrency = (value) => {
    const currency = currencies.find((cur) => cur.id === value);
    if (currency) {
      setCurrency(value);
      setRate(currency.rate);
      const inCurrency = (remaining / currency.rate) * 100;
      setAmountInCurrency(inCurrency);
    }
  };

  const toRate = useCallback((amount) => Math.round(amount / rate), [rate]);

  useEffect(() => {
    if (remaining >= 0) {
      setAmountInCurrency(toRate(remaining * 100));
    }
  }, [remaining, rate, toRate]);

  return (
    <CardSection p={1}>
      <TotalsLine title={t("paid")} value={paid / 100} />
      <Divider />
      <TotalsLine
        title={t("remaining")}
        value={toRate(remaining * 100) / 100 || 0}
      />
      <Divider />
      <Stack>
        <Grid container spacing={1}>
          {currencies.length > 0 && (
            <>
              <DefaultFormSelectField
                grid={6}
                label="currency"
                options={currencies}
                optionLabel="code"
                value={currency}
                onChange={onChangeCurrency}
                storageKey="currency"
              />
              <FormMoneyField grid={6} label="rate" value={rate} disabled />
            </>
          )}
          <FormMoneyField
            grid={6}
            label="tendered"
            value={amountInCurrency}
            onChange={onChangeAmountInCurrency}
          />
          <FormMoneyField
            grid={6}
            label="returned_amount"
            value={
              amountInCurrency - toRate(remaining * 100) > 0
                ? amountInCurrency - toRate(remaining * 100)
                : 0
            }
            disabled
          />
          {/* <FormMoneyField label="collected" value={amount} disabled /> */}
        </Grid>

        <ButtonGroup fullWidth>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={onPay}
            disabled={amountInCurrency <= 0}
          >
            {t("pay")}
          </Button>
          {amountInCurrency > 0 &&
            amountInCurrency - toRate(remaining * 100) > 0 && (
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={onCollect}
              >
                {t("collect")} : {amountInCurrency / 100}
              </Button>
            )}
        </ButtonGroup>
      </Stack>
      {/* <Button>
          {t("print")}
        </Button> */}
    </CardSection>
  );
}
