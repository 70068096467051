import React from "react";
import { Box, Zoom, Fab, Grid, Button } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import LoadingIndicator from "./LoadingIndicator";
import ErrorAlert from "./ErrorAlert";
import useTranslate from "commons/hooks/useTranslate";

export default function Editor({
  current,
  send,
  children,
  resetOnSave = false,
  embeded = false,
}) {
  const { error } = current.context;
  const isDirty = current.matches("dirty") || current.matches("invalid");
  const isLoading = current.matches("fetching") || current.matches("saving");

  const onSubmit = () => {
    send(resetOnSave ? "SAVEnRESET" : "SAVE");
  };

  return (
    <>
      <LoadingIndicator show={isLoading} />
      <ErrorAlert error={error} />
      <ResourceForm onSubmit={onSubmit} dirty={isDirty} embeded={embeded}>
        {children}
      </ResourceForm>
    </>
  );
}

export function ResourceForm({ onSubmit, dirty, children, embeded }) {
  const { t } = useTranslate();
  const submit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <Box py={2}>
      <form onSubmit={submit} autoComplete="off">
        <Grid container alignItems="flex-start" spacing={2}>
          {children}
        </Grid>
        {embeded ? (
          <Box pt={2} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={submit}
              disabled={!dirty}
              startIcon={<Save />}
            >
              {t("save")}
            </Button>
          </Box>
        ) : (
          <Box position="fixed" bottom={24} right={24}>
            <Zoom in style={{ transitionDelay: "500ms" }}>
              <Fab
                type="submit"
                color="primary"
                onClick={submit}
                disabled={!dirty}
              >
                <Save />
              </Fab>
            </Zoom>
          </Box>
        )}
      </form>
    </Box>
  );
}
