import React, { useEffect, useState } from "react";
import FormTextField from "./FormTextField";
import dayjs from "dayjs";
import { Grid, TextField } from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";

export function FormDateField({
  grid = 12,
  label,
  value,
  onChange,
  error,
  format = "YYYY-MM-DD",
  ...props
}) {
  const { t } = useTranslate();
  const [date, setDate] = useState("");
  useEffect(() => {
    if (value && dayjs(value).isValid()) {
      setDate(dayjs(value).format(format));
    }
  }, [value, format]);

  return (
    <Grid item sm={grid} xs={12}>
      <TextField
        type="date"
        label={t(label)}
        value={date}
        onChange={e => setDate(e.target.value)}
        error={Boolean(error)}
        helperText={Boolean(error) && t("rules." + error)}
        onBlur={e => onChange(e.target.value)}
        InputLabelProps={{
          shrink: true
        }}
        {...props}
      />
    </Grid>
    // <FormTextField
    //   type="date"
    //   value={dayjs(value).isValid() ? dayjs(value).format("YYYY-MM-DD") : value}
    //   InputLabelProps={{
    //     shrink: true,
    //   }}
    //   {...props}
    // />
  );
}

export function FormTimeField({ ...props }) {
  return (
    <FormTextField
      type="time"
      // value={value ? dayjs(value).format("YYYY-MM-DD") : ""}
      InputLabelProps={{
        shrink: true
      }}
      {...props}
    />
  );
}

export function FormDateTimeField({ ...props }) {
  return (
    <FormDateField type="datetime-local" format="YYYY-MM-DDTHH:mm" {...props} />
  );
}
