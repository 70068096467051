import { useEffect } from "react";

import useResourceMachine from "commons/hooks/useResourceMachine";
import { useParams, useHistory } from "react-router-dom";

export default function useControlledResourcePage(
  url,
  initialModel = {},
  autosave = true,
  route = url
) {
  const { id } = useParams();
  const history = useHistory();
  const {
    current,
    send,
    model,
    updateModel,
    mergeModel,
    rules,
  } = useResourceMachine(url, id, initialModel, autosave);

  // The goal is to match the url, to the current model in machine
  // Use Case #1
  // 1- in CREATE and the resource has been saved and has an id
  // 2- in EDIT and the reset has been clicked, the model has no id
  // 3- in Create Trying to move to Edit, e.g Click back button
  // 4- in Edit pressed reset with model has some data other than id

  // model can be {}, {id}, {id, others}, {others}
  // const hasOnlyId = Boolean(model.id) && Object.keys(model).length === 1;

  useEffect(() => {
    if (id === "create" && Boolean(model.id)) {
      history.push(`/s/${route}/${model.id}`);
    }
    if (id !== "create" && !model.hasOwnProperty("id")) {
      history.push(`/s/${route}/create`);
    }
  }, [id, route, model, history]);

  // useEffect(() => {
  //   if (
  //     id !== "create" &&
  //     model.hasOwnProperty("id") &&
  //     parseInt(id) !== parseInt(model.id)
  //   ) {
  //     send("RESET", { data: { id } });
  //   }
  // }, [id, send, model]);

  return {
    current,
    send,
    model,
    updateModel,
    mergeModel,
    rules,
    id,
  };
}
