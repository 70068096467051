import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Typography,
  IconButton,
  Box,
  Popover,
  makeStyles,
  InputAdornment,
} from "@material-ui/core";
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Settings,
  Delete,
  AddLocation,
  Timer,
  SettingsOverscan,
  KeyboardReturn,
} from "@material-ui/icons";
import { adjust, remove, compose, assoc } from "ramda";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import dayjs from "dayjs";
import useTranslate from "commons/hooks/useTranslate";
import { toggle, sumField } from "commons/helpers/utils";
import CardSection from "commons/components/CardSection";
import { FormMoneyField } from "commons/components/FormMoneyField";
import { FormQuantityField } from "commons/components/FormQuantityField";
import FormSelectField from "commons/components/FormSelectField";
import { FormNumberField } from "commons/components/FormNumberField";
import { FormDateTimeField } from "commons/components/FormDateField";
import { calcLine } from "../utils/calculators";
import ExtrasCard from "./ExtrasCard";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  odd: {
    background: "rgba(0,0,0,0.05)",
  },
  even: {
    background: "rgba(0,0,0,0.1)",
  },
}));

function ValueViewer({ value, grid = 1, ...props }) {
  return (
    <Grid item sm={grid}>
      <Typography variant="subtitle1" {...props}>
        {value}
      </Typography>
    </Grid>
  );
}

export default function LinesManager({
  value = [],
  onChange,
  onChangeLineQuantity,
  onChangeReturnedQuantity,
  discounts,
  commissions,
  locations,
  stockLevels,
  hasExtras = false,
  error,
}) {
  const { t, language } = useTranslate();
  const listRef = useRef(null);
  const [openedLines, setOpenedLines] = useState([]);
  const [allOpened, setAllOpened] = useState(false);

  const getItemSize = (index) => {
    const line = value[index];
    const discounts = line && line.discounts ? line.discounts.length : 0;
    const commissions = line && line.commissions ? line.commissions.length : 0;
    // const changes = changesByKey[product.product_id] || [];
    // // return 60 + levels.length * 60 + changes.length * 60 + 60;
    if (isOpened(line.id)) {
      return 60 + Math.max(discounts, commissions) * 60 + 90;
    }
    return 60;
  };

  useEffect(() => {
    if (listRef && listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [value]);

  const toggleLineOpened = (id) => {
    setOpenedLines(toggle(id, openedLines));
    listRef.current.resetAfterIndex(0);
  };

  const isOpened = (product_id) =>
    allOpened || openedLines.includes(product_id);

  // const onRowChange = (product_id) => (changes) => {
  //   onChange(Object.values({ ...changesByKey, [product_id]: changes }).flat());
  // };

  return (
    <div>
      {error && (
        <Alert elevation={6} variant="filled" severity="error">
          <AlertTitle>{t("mustHaveLines")}</AlertTitle>
        </Alert>
      )}
      <CardSection p={0}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs sm="auto">
            {hasExtras && (
              <IconButton size="small" onClick={() => setAllOpened(!allOpened)}>
                {allOpened ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </IconButton>
            )}
          </Grid>
          <Grid item sm>
            <Grid container spacing={1} alignItems="center">
              <ValueViewer value={t("code")} grid={2} align="center" />
              <ValueViewer value={t("name")} grid={4} />
              <ValueViewer value={t("price")} grid={2} align="center" />
              <ValueViewer value={t("quantity")} grid={2} align="center" />
              {/* <ValueViewer value={t("stocks")} align="center" /> */}
              <ValueViewer value={t("total")} grid={2} align="center" />
            </Grid>
          </Grid>
          <Grid item sm="auto">
            <Box p={1}>
              <Settings />
            </Box>
          </Grid>
        </Grid>
      </CardSection>
      <Box minHeight="600px" bgcolor="background.default">
        <AutoSizer disableWidth>
          {({ height }) => (
            <VariableSizeList
              ref={listRef}
              direction={language === "ar" ? "rtl" : "ltr"}
              height={height}
              itemCount={value.length}
              itemSize={getItemSize}
              width="100%"
              overscanCount={8}
              useIsScrolling
              itemData={{
                lines: value,
                // products,
                locations,
                stockLevels,
                hasExtras,
                discounts,
                commissions,
                onChange,
                onChangeLineQuantity,
                onChangeReturnedQuantity,
                toggleLineOpened,
                isOpened,
              }}
            >
              {Row}
            </VariableSizeList>
          )}
        </AutoSizer>
      </Box>
    </div>
  );
}

function PlainWrapper({ children }) {
  return (
    <Box p={1} style={{ background: "rgba(0,0,0,0.05" }}>
      {children}
    </Box>
  );
}

function Row({ data, index, style }) {
  const {
    hasExtras,
    lines,
    // products,
    locations,
    stockLevels,
    onChange,
    onChangeLineQuantity,
    onChangeReturnedQuantity,
    toggleLineOpened,
    isOpened,
    discounts,
    commissions,
  } = data;
  const line = lines[index];
  // const product = products.find((prod) => prod.product_id === line.product_id);
  const classes = useStyles();
  const productCommissions = commissions.filter(
    (com) => com.product_id === line.product_id
  );
  const myLocations = locations.filter(
    (loc) => loc.product_id === line.product_id
  );
  const levels = stockLevels[line.product_id] || [];
  const stocksTotal = sumField("quantity")(levels);

  const updateField = (field) => (value) => {
    onChange(adjust(index, compose(calcLine, assoc(field, value)), lines));
  };

  const deleteRow = () => {
    onChange(remove(index, 1, lines));
  };

  // const reachedMaxInSale =
  //   product && product.max_in_sale > 0 && line.quantity >= product.max_in_sale;

  const updateQuantity = (value) => {
    onChangeLineQuantity(index, value);
  };

  const updateReturnedQuantity = (value) => {
    onChangeReturnedQuantity(index, value);
  };

  const changeQuantityField = (field) => (value) => {
    const updated = { ...line, [field]: value };
    let quantity = 0;
    switch (updated.type) {
      case "AREA":
        quantity = updated.length * updated.width;
        break;
      case "VOLUME":
        quantity = updated.length * updated.width * updated.height;
        break;
      case "HOURLY":
        quantity = getTimeDiff("minute", updated);
        break;
      case "DAILY":
        quantity = getTimeDiff("hour", updated);
        break;
      case "MONTHLY":
        quantity = getTimeDiff("day", updated);
        break;
      case "YEARLY":
        quantity = getTimeDiff("month", updated);
        break;
      default:
        quantity = updated.quantity;
    }
    onChangeLineQuantity(index, quantity, { [field]: value });
  };

  const getTimeDiff = (scale, record) => {
    const { duration_start, duration_end, threshold, quantity } = record;
    const hasTime = Boolean(duration_start) && Boolean(duration_end);
    if (!hasTime) return quantity;
    const diff = dayjs(duration_end).diff(dayjs(duration_start), scale);
    const scaleValue = getScaleValue(scale);
    const extra = threshold > 0 && diff % scaleValue > threshold ? 1 : 0;
    return Math.floor(diff / scaleValue) + extra;
  };

  const getScaleValue = (scale) => {
    switch (scale) {
      case "hour":
        return 24;
      case "day":
        return 30;
      case "month":
        return 12;
      default:
        return 60;
    }
  };

  const canReturn =
    line.savedQty > 0 && Number(line.savedQty) === Number(line.quantity);
  const isReturning = Number(line.savedReturned) !== Number(line.returned);
  const hasReturned = Number(line.returned) > 0;

  return (
    <div style={style} className={index % 2 ? classes.even : classes.odd}>
      <Grid container>
        <Grid item xs sm="auto">
          <Box pt={2}>
            {hasExtras && (
              <IconButton
                size="small"
                onClick={() => toggleLineOpened(line.id)}
              >
                {isOpened(line.id) ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown />
                )}
              </IconButton>
            )}
          </Box>
        </Grid>
        <Grid item sm>
          <Grid container spacing={1} alignItems="center">
            <ValueViewer value={line.code} grid={2} align="center" />
            <Grid item sm={4}>
              <Grid container alignItems="center">
                <Grid item xs>
                  {line.name.substring(0, 50)}
                </Grid>
                {myLocations.length > 0 && (
                  <Grid item xs="auto">
                    <LineLocations
                      line={line}
                      locations={myLocations}
                      onChange={updateField("service_location_id")}
                    />
                  </Grid>
                )}
                {[
                  "AREA",
                  "VOLUME",
                  "ONCE",
                  "HOURLY",
                  "MONTHLY",
                  "DAILY",
                  "YEARLY",
                  "CAR_RENTAL",
                ].includes(line.type) && (
                  <Grid item xs="auto">
                    <LineTypeExtras
                      line={line}
                      updateField={changeQuantityField}
                    />
                  </Grid>
                )}
                {canReturn && (
                  <IconButton
                    size="small"
                    onClick={() => updateReturnedQuantity(1)}
                  >
                    <KeyboardReturn />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            <FormMoneyField
              grid={2}
              size="small"
              margin="dense"
              value={line.price}
              onChange={updateField("price")}
            />
            <FormQuantityField
              grid={hasReturned ? 1 : 2}
              size="small"
              margin="dense"
              value={line.quantity}
              onChange={updateQuantity}
              // disabled={reachedMaxInSale}
              InputProps={{
                endAdornment: hasReturned ? null : (
                  <InputAdornment position="start">
                    <strong>{stocksTotal}</strong>
                  </InputAdornment>
                ),
              }}
              disabled={isReturning}
            />
            {hasReturned && (
              <FormQuantityField
                grid={1}
                size="small"
                margin="dense"
                value={line.returned}
                onChange={updateReturnedQuantity}
                disabled={!canReturn}
              />
            )}
            <ValueViewer value={line.total / 100} grid={2} align="center" />
          </Grid>
          {isOpened(line.id) && (
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <ExtrasCard
                  Wrapper={PlainWrapper}
                  items={line.discounts}
                  options={discounts}
                  title="discounts"
                  onChange={updateField("discounts")}
                />
              </Grid>
              <Grid item xs={6}>
                <ExtrasCard
                  Wrapper={PlainWrapper}
                  items={line.commissions}
                  options={productCommissions}
                  title="commissions"
                  onChange={updateField("commissions")}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item sm="auto">
          <Box pt={1}>
            <IconButton onClick={deleteRow} disabled={line.savedQty > 0}>
              <Delete />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

function LineLocations({ line, locations, onChange }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "line-location-popover-" + line.id : undefined;

  useEffect(() => {
    if (locations.length === 1 && !line.service_location_id) {
      onChange(locations[0].service_location_id);
    }
  }, [locations, line.service_location_id, onChange]);

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <AddLocation />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={1} minWidth={200}>
          <Grid container spacing={1}>
            <FormSelectField
              label="service_location"
              options={locations}
              optionKey="service_location_id"
              value={line.service_location_id}
              onChange={onChange}
            />
          </Grid>
        </Box>
      </Popover>
    </>
  );
}

function LineTypeExtras({ line, updateField }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "line-popover" + line.id : undefined;

  const hasDistance = ["CAR_RENTAL"].includes(line.type);
  const hasLength = ["VOLUME", "AREA"].includes(line.type);
  const hasWidth = ["VOLUME", "AREA"].includes(line.type);
  const hasHeight = line.type === "VOLUME";
  const hasTime = ["ONCE", "HOURLY", "MONTHLY", "DAILY", "YEARLY"].includes(
    line.type
  );

  // useEffect(() => {
  //   if (line.type === "CAR_RENTAL" && line) {
  //     updateField("length");
  //   }
  // }, [locations, line.service_location_id, onChange]);

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        {hasTime ? <Timer /> : <SettingsOverscan />}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={1}>
          <Grid container spacing={1}>
            {hasDistance && (
              <FormNumberField
                label="distance"
                grid="auto"
                xs="auto"
                value={line.length}
                onChange={updateField("length")}
                disabled={true}
              />
            )}
            {hasLength && (
              <FormNumberField
                label="length"
                grid="auto"
                xs="auto"
                value={line.length}
                onChange={updateField("length")}
              />
            )}
            {hasWidth && (
              <FormNumberField
                label="width"
                grid="auto"
                xs="auto"
                value={line.width}
                onChange={updateField("width")}
              />
            )}
            {hasHeight && (
              <FormNumberField
                label="height"
                grid="auto"
                xs="auto"
                value={line.height}
                onChange={updateField("height")}
              />
            )}
            {hasTime && (
              <>
                <FormDateTimeField
                  label="starts"
                  grid="auto"
                  xs="auto"
                  value={line.duration_start}
                  onChange={updateField("duration_start")}
                />
                <FormDateTimeField
                  label="ends"
                  grid="auto"
                  xs="auto"
                  value={line.duration_end}
                  onChange={updateField("duration_end")}
                />
              </>
            )}
          </Grid>
        </Box>
      </Popover>
    </>
  );
}
