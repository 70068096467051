import React, { useState, useEffect } from "react";
import api from "commons/helpers/api";
import { Grid, Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import FormSelectField from "commons/components/FormSelectField";
import FormTextField from "commons/components/FormTextField";
import useTranslate from "commons/hooks/useTranslate";

export default function EntityCard({
  entities,
  onEntityChange,
  model,
  type,
  rules,
}) {
  const { t } = useTranslate();
  const [policy, setPolicy] = useState("");
  const [limit, setLimit] = useState(0);
  const [balance, setBalance] = useState(0);
  const field = type === "customers" ? "customer_id" : "supplier_id";
  const [open, setOpen] = useState(false);

  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  const onEntitySelect = (id) => {
    const entity = entities.find((rec) => rec.id === id);
    if (entity) {
      setPolicy(entity.policy);
      setLimit(entity.credit_limit);
      onEntityChange(entity);
    }
  };

  useEffect(() => {
    if (model[field] && policy === "") {
      const entity = entities.find((rec) => rec.id === model[field]);
      if (entity) {
        setPolicy(entity.policy);
        setLimit(entity.credit_limit);
      }
    }
  }, [model, field, policy, entities]);

  useEffect(() => {
    if (model.customer_id) {
      api
        .service("balance")
        .get(model.customer_id, { query: { type: "customers" } })
        .then((res) => setBalance(res.balance));
    }
  }, [model.customer_id]);

  useEffect(() => {
    if (model.supplier_id) {
      api
        .service("balance")
        .get(model.supplier_id, { query: { type: "customers" } })
        .then((res) => setBalance(res.balance));
    }
  }, [model.supplier_id]);

  return (
    <Grid container spacing={2}>
      <FormSelectField
        label={field}
        options={entities}
        value={model[field]}
        onChange={onEntitySelect}
        error={rules[field]}
        autoHighlight
        onItemDataClick={() => {
          if (model[field]) {
            handleDialogOpen();
          } else {
          }
        }}
      />
      <FormTextField grid={4} label="policy" value={policy} disabled />
      <FormTextField
        grid={4}
        label="credit_limit"
        value={limit / 100 + balance / 100}
        disabled
      />
      <FormTextField grid={4} label="balance" value={balance / 100} disabled />
      <Dialog onClose={handleDialogClose} open={open} maxWidth="xl" fullWidth>
        <DialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
          {t(type)}
        </DialogTitle>
        <DialogContent dividers>
          <iframe
            className="frontdesk-iframe"
            style={{ margin: "1%" }}
            width="98%"
            height="96%"
            src={`/s/${type}/${model[field]}`}
            title={t(type)}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
