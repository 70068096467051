import React from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import FormSelectField from "commons/components/FormSelectField";
import { FormDateTimeField } from "commons/components/FormDateField";
import ResourceRelations from "commons/components/ResourceRelations";
import { FormMoneyField } from "commons/components/FormMoneyField";
import { Grid, IconButton, Box, Button } from "@material-ui/core";
import { sumField } from "commons/helpers/utils";
import { Cancel, CheckCircle, VerifiedUser } from "@material-ui/icons";
import useTranslate from "commons/hooks/useTranslate";
import api from "commons/helpers/api";
import dayjs from "dayjs";

const base = "transactions";

export default function Single({ currency = false }) {
  const { t } = useTranslate();
  const {
    current,
    send,
    model,
    updateModel,
    // mergeModel,
    rules,
  } = useControlledResourcePage(base, { created: dayjs() }, false);
  const isSaved = Boolean(model.id);

  // const onEntriesUpdate = entries => {
  //   const credits = entries.filter(rec => rec.credit > 0);
  //   const debits = entries.filter(rec => rec.debit > 0);
  //   const creditSummary = credits.reduce((acc, curr) =>  {
  //     acc += `${curr.credit}`
  //   }, "[CR]:");
  //   const summary = entries.reduce((acc, curr) =>  {

  //   }, "");
  //   mergeModel({
  //     summary,
  //     entries,
  //   })
  // }

  const onApprove = () => {
    api.service("transactions").patch(model.id, {});
  };

  return (
    <ResourceSinglePage title={base} current={current} send={send}>
      <FormDateTimeField
        grid={6}
        label="created"
        value={model.created}
        onChange={updateModel("created")}
        error={rules.created}
        InputProps={{
          readOnly: isSaved,
        }}
      />
      <FormTextField
        grid={6}
        label="reference"
        value={model.reference}
        onChange={updateModel("reference")}
        error={rules.reference}
        InputProps={{
          readOnly: isSaved,
        }}
      />
      {currency ? (
        <CurrencyEntriesManager
          value={model.entries}
          onChange={updateModel("entries")}
          isSaved={isSaved}
          currency={currency}
        />
      ) : (
        <EntriesManager
          value={model.entries}
          onChange={updateModel("entries")}
          isSaved={isSaved}
          currency={currency}
        />
      )}
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.notes}
        InputProps={{
          readOnly: isSaved,
        }}
      />
      {isSaved && !model.approved && (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <Button
              size="large"
              variant="contained"
              color="primary"
              startIcon={<VerifiedUser />}
              onClick={onApprove}
            >
              {t("approve")}
            </Button>
          </Box>
        </Grid>
      )}
    </ResourceSinglePage>
  );
}

const isEntryFull = (entry) =>
  entry.account_id !== null && (entry.debit > 0 || entry.credit > 0);

function EntriesManager({ value = [], onChange, isSaved }) {
  const [accounts] = useResourcesByQuery("accounts", true, { active: true });

  return (
    <ResourceRelations
      title="entries"
      value={value}
      onChange={onChange}
      isRecordFull={isSaved ? null : isEntryFull}
      model={{
        account_id: null,
        debit: 0,
        credit: 0,
      }}
      disableRemove={isSaved}
      footer={value.length > 0 ? <EntriesManagerFooter value={value} /> : null}
      rowRenderer={(record, onItemChange) => (
        <>
          <FormSelectField
            grid={2}
            options={accounts}
            label="code"
            value={record.account_id}
            optionLabel="code"
            autoHighlight
            onChange={
              !isSaved
                ? onItemChange("account_id")
                : onItemChange(record.account_id)
            }
          />
          <FormSelectField
            grid={4}
            options={accounts}
            label="account"
            value={record.account_id}
            autoHighlight
            onChange={
              !isSaved
                ? onItemChange("account_id")
                : onItemChange(record.account_id)
            }
          />
          <FormMoneyField
            grid={3}
            label="debit"
            value={record.debit}
            onChange={onItemChange("debit")}
            disabled={Boolean(record.credit) || !record.account_id}
            InputProps={{
              readOnly: isSaved,
            }}
          />
          <FormMoneyField
            grid={3}
            label="credit"
            value={record.credit}
            onChange={onItemChange("credit")}
            disabled={Boolean(record.debit) || !record.account_id}
            InputProps={{
              readOnly: isSaved,
            }}
          />
        </>
      )}
    />
  );
}

function CurrencyEntriesManager({ value = [], onChange, isSaved }) {
  const [accounts] = useResourcesByQuery("accounts", true, { active: true });
  const [currencies] = useResourcesByQuery("currencies", true);

  return (
    <ResourceRelations
      title="entries"
      value={value}
      onChange={onChange}
      isRecordFull={isSaved ? null : isEntryFull}
      model={{
        account_id: null,
        debit: 0,
        credit: 0,
        currency_id: null,
        rate: null,
        debit_in_currency: 0,
        credit_in_currency: 0,
      }}
      disableRemove={isSaved}
      footer={value.length > 0 ? <EntriesManagerFooter value={value} /> : null}
      rowRenderer={(record, onItemChange, onItemMerge) => (
        <>
          <FormSelectField
            grid={1}
            options={accounts}
            label="code"
            value={record.account_id}
            optionLabel="code"
            autoHighlight
            onChange={
              !isSaved
                ? onItemChange("account_id")
                : onItemChange(record.account_id)
            }
          />
          <FormSelectField
            grid={3}
            options={accounts}
            label="account"
            value={record.account_id}
            autoHighlight
            onChange={
              !isSaved
                ? onItemChange("account_id")
                : onItemChange(record.account_id)
            }
          />
          <FormSelectField
            grid={1}
            options={currencies}
            label="currency"
            optionLabel="code"
            value={record.currency_id}
            autoHighlight
            onChange={(value) => {
              // !isSaved
              //   ? onItemChange("currency_id")
              //   : onItemChange(record.currency_id)
              const currency = currencies.find((cur) => cur.id === value);
              if (currency) {
                onItemMerge({
                  currency_id: currency.id,
                  rate: currency.rate,
                  debit_in_currency: 0,
                  credit_in_currency: 0,
                  debit: 0,
                  credit: 0,
                });
              }
            }}
          />
          <FormMoneyField
            grid={1}
            label="rate"
            value={record.rate}
            disabled={true}
          />
          <Grid xs={6} item>
            <Grid container spacing={2}>
              <FormMoneyField
                grid={3}
                label="debit_in_currency"
                value={record.debit_in_currency}
                onChange={(value) => {
                  onItemMerge({
                    debit_in_currency: value,
                    debit: (value * record.rate) / 100,
                  });
                }}
                disabled={
                  Boolean(record.credit_in_currency) ||
                  !record.account_id ||
                  !record.rate
                }
                InputProps={{
                  readOnly: isSaved,
                }}
              />
              <FormMoneyField
                grid={3}
                label="credit_in_currency"
                value={record.credit_in_currency}
                onChange={(value) => {
                  onItemMerge({
                    credit_in_currency: value,
                    credit: (value * record.rate) / 100,
                  });
                }}
                disabled={
                  Boolean(record.debit_in_currency) ||
                  !record.account_id ||
                  !record.rate
                }
                InputProps={{
                  readOnly: isSaved,
                }}
              />
              <FormMoneyField
                grid={3}
                label="debit"
                value={record.debit}
                disabled={true}
                InputProps={{
                  readOnly: isSaved,
                }}
              />
              <FormMoneyField
                grid={3}
                label="credit"
                value={record.credit}
                disabled={true}
                InputProps={{
                  readOnly: isSaved,
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    />
  );
}

function EntriesManagerFooter({ value }) {
  const debit_total = sumField("debit")(value);
  const credit_total = sumField("credit")(value);
  const difference = debit_total - credit_total;

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item container sm spacing={2}>
          <FormMoneyField
            grid={6}
            label="difference"
            value={difference}
            error={difference !== 0 ? "debit_credit_match" : false}
            InputProps={{
              readOnly: true,
            }}
          />
          <FormMoneyField
            grid={3}
            label="debit"
            value={debit_total}
            InputProps={{
              readOnly: true,
            }}
          />
          <FormMoneyField
            grid={3}
            label="credit"
            value={credit_total}
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs sm="auto">
          <IconButton disabled size="small">
            {difference !== 0 ? <Cancel /> : <CheckCircle />}
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}
