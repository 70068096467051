import React from "react";
// import Checkbox from "/commons/components/Checkbox";
// import { toggle } from "/commons/helpers/utils";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { toggle } from "commons/helpers/utils";

export default function MultiChoose({ item, onChange }) {
  return (
    <div>
      <h3>{item.label}</h3>
      <div style={{ display: "flex" }}>
        {item.options.map((option) => (
          <div key={option}>
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              label={option}
              value={option}
              labelPlacement="end"
              checked={item.value.includes(option)}
              onChange={(e) => {
                console.log(item.value);
                console.log(e.target.value);
                console.log(toggle(e.target.value, item.value));
                onChange({
                  ...item,
                  value: toggle(e.target.value, item.value),
                });
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
