import React, { useState } from "react";
import ErrorAlert from "commons/components/ErrorAlert";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import useTranslate from "commons/hooks/useTranslate";
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { AccountTree, AddBox } from "@material-ui/icons";
import { CategoryTree } from "..";

export default function FrontdeskMain({
  error,
  base,
  current,
  send,
  products,
  onProductSelect,
  children,
}) {
  const [showCategories, setShowCategories] = useState(false);
  const { t } = useTranslate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuChoose = (resource) => {
    setSelected(resource);
    handleMenuClose();
    handleDialogOpen();
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ErrorAlert
        error={
          error ? { message: `${t(error.message)} - #${error.product}` } : error
        }
      />
      <ResourceSinglePage
        title={base}
        current={current}
        send={send}
        toolbarIcons={
          <>
            <Tooltip title={t("categories")}>
              <IconButton onClick={() => setShowCategories(!showCategories)}>
                <AccountTree />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("fastAdd")}>
              <IconButton onClick={handleMenuClick}>
                <AddBox />
              </IconButton>
            </Tooltip>
          </>
        }
      >
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() =>
              handleMenuChoose(base === "sales" ? "customers" : "suppliers")
            }
          >
            {base === "sales" ? t("customer") : t("supplier")}
          </MenuItem>
          <MenuItem onClick={() => handleMenuChoose("products")}>
            {t("product")}
          </MenuItem>
          <MenuItem onClick={() => handleMenuChoose("discounts")}>
            {t("discount")}
          </MenuItem>
          <MenuItem onClick={() => handleMenuChoose("taxes")}>
            {t("tax")}
          </MenuItem>
          <MenuItem onClick={() => handleMenuChoose("employees")}>
            {t("employee")}
          </MenuItem>
          <MenuItem onClick={() => handleMenuChoose("contractors")}>
            {t("contractor")}
          </MenuItem>
        </Menu>
        {showCategories && (
          <CategoryTree
            sales
            products={products}
            onProductSelect={onProductSelect}
          />
        )}
        <Dialog onClose={handleDialogClose} open={open} maxWidth="xl" fullWidth>
          <DialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
            {t(selected)}
          </DialogTitle>
          <DialogContent dividers>
            <iframe
              className="frontdesk-iframe"
              style={{ margin: "1%" }}
              width="98%"
              height="96%"
              src={`/s/${selected}/create`}
              title={t(selected)}
            />
          </DialogContent>
        </Dialog>
        {children}
      </ResourceSinglePage>
    </>
  );
}
