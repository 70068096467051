import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "name",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "assignee",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "assignees.username",
  },
  {
    name: "assigner",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "assigners.username",
  },
  {
    name: "project",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "projects.name",
  },
  {
    name: "due",
    type: "datetime",
    sortable: true,
    searchable: true,
  },
  {
    name: "body",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "completed",
    type: "boolean",
    sortable: true,
    searchable: true,
  },
];

export default function List({ url = "tasks" }) {
  return <ResourceListPage url={url} columns={columns} />;
}
