import React, { useState, useEffect, Fragment, useCallback } from "react";
import useTranslate from "commons/hooks/useTranslate";
import {
  Box,
  ListItemIcon,
  ListItemText,
  Hidden,
  Drawer,
  Divider,
  List,
  ListItem,
  Collapse,
} from "@material-ui/core";
import {
  Settings,
  ExpandMore,
  BusinessCenter,
  AccountTree,
  Business,
  TableChart,
  People,
  HourglassEmptyOutlined,
  ExpandLess,
  School,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import {
  BrowserRouter as Router,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { PrintTemplatesContext } from "commons/helpers/contexts";

import api from "commons/helpers/api";
import { compose, prop, groupBy } from "ramda";
import MainAppBar from "commons/components/MainAppBar";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  appBarWithDrawer: {
    [theme.breakpoints.up("sm")]: {
      transition: "all 200ms",
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  appBarWithoutDrawer: {
    transition: "all 200ms",
    width: `100%`,
  },
  contentWithDrawer: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
    },
    transition: "all 200ms",
    padding: theme.spacing(2),
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  contentWithoutDrawer: {
    transition: "all 200ms",
    padding: theme.spacing(2),
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  nested: {
    paddingLeft: theme.spacing(8),
  },
  routesWrapper: {
    flex: 1,
  },
}));

export default function MainDashboard({
  onLogout,
  user,
  companyName = "",
  SoftwareLogo,
  messages = false,
  notifications = false,
  ongoing = false,
  children,
}) {
  const initialDrawerState = () =>
    window.localStorage.getItem("showDrawer") === "true";
  const [showDrawer, setShowDrawer] = useState(initialDrawerState);
  const [templates] = useResourcesByQuery("print-templates", true);
  const classes = useStyles();

  const toggleDrawer = () => {
    setShowDrawer(!showDrawer);
    window.localStorage.setItem("showDrawer", !showDrawer);
  };

  return (
    <PrintTemplatesContext.Provider value={{ templates }}>
      <Router>
        <MainAppBar
          onLogout={onLogout}
          toggleDrawer={toggleDrawer}
          companyName={companyName}
          user={user}
          className={
            showDrawer ? classes.appBarWithDrawer : classes.appBarWithoutDrawer
          }
          messages={messages}
          notifications={notifications}
          ongoing={ongoing}
        />
        <MyDrawer
          toggleDrawer={toggleDrawer}
          showDrawer={showDrawer}
          SoftwareLogo={SoftwareLogo}
        />
        <main
          className={
            showDrawer
              ? classes.contentWithDrawer
              : classes.contentWithoutDrawer
          }
        >
          <div className={classes.toolbar} />
          <div className={classes.routesWrapper}>{children}</div>
        </main>
      </Router>
    </PrintTemplatesContext.Provider>
  );
}

const groupIcons = {
  operations: <BusinessCenter />,
  manage: <AccountTree />,
  finance: <Business />,
  organize: <TableChart />,
  hr: <People />,
  businessPlan: <HourglassEmptyOutlined />,
  settings: <Settings />,
  "teachers-ops": <School />,
};

function MyDrawer({ showDrawer, toggleDrawer, SoftwareLogo }) {
  const { t } = useTranslate();
  const classes = useStyles();
  const [menu, setMenu] = useState([]);
  const [expandedGroup, setExpandedGroup] = useState(false);
  const location = useLocation();
  const isActive = useCallback(
    (name) => location.pathname.startsWith("/s/" + name.toLowerCase()),
    [location]
  );

  useEffect(() => {
    api
      .service("menu-items")
      .find({ query: { $sort: { id: 1 } } })
      .then(compose(setMenu, groupBy(prop("group"))));
  }, []);

  const onExpandedChange = (group) => () => {
    setExpandedGroup(expandedGroup !== group ? group : false);
  };

  // find if a group should be expanded
  useEffect(() => {
    const activeGroup = Object.keys(menu).find(
      (group) => menu[group].filter((item) => isActive(item.name)).length > 0
    );
    if (activeGroup) {
      setExpandedGroup(activeGroup);
    }
  }, [menu, isActive]);

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <Box
          p={1}
          display="flex"
          alignItems="center"
          justifyContent="space-around"
        >
          <img src={SoftwareLogo} alt="Logo" width="50px" />
          <div>
            <h1 style={{ margin: "0" }}>{t("program_name")}</h1>
            <p style={{ margin: "0" }}>{t("version")}</p>
          </div>
        </Box>
      </div>
      <Divider />
      <List component="nav">
        {Object.keys(menu).map((group) => (
          <Fragment key={group}>
            <ListItem button onClick={onExpandedChange(group)}>
              <ListItemIcon>{groupIcons[group]}</ListItemIcon>
              <ListItemText primary={t(group)} />
              {expandedGroup === group ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={expandedGroup === group}>
              <List component="div" disablePadding>
                {menu[group].map((item) => (
                  <ListItem
                    button
                    key={item.name}
                    className={classes.nested}
                    component={RouterLink}
                    to={`/s/${item.name}`}
                    selected={isActive(item.name)}
                  >
                    <ListItemText primary={t(item.name)} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </Fragment>
        ))}
      </List>
    </div>
  );

  return (
    <nav className={classes.drawer} aria-label="mailbox folders">
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Hidden smUp>
        <Drawer
          variant="temporary"
          open={showDrawer}
          onClose={toggleDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          variant="persistent"
          open={showDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}
