import React, { useState, useMemo, useRef, useEffect } from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormSelectField from "commons/components/FormSelectField";
import useTranslate from "commons/hooks/useTranslate";
import {
  FormDateTimeField,
  FormDateField,
} from "commons/components/FormDateField";
import FormTextField from "commons/components/FormTextField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import CardSection from "commons/components/CardSection";
import {
  Grid,
  Typography,
  Box,
  makeStyles,
  IconButton,
  TextField,
} from "@material-ui/core";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  KeyboardArrowDown,
  FileCopyOutlined,
  Add,
  KeyboardArrowUp,
  Delete,
} from "@material-ui/icons";
import {
  prop,
  groupBy,
  compose,
  sum,
  map,
  omit,
  assoc,
  adjust,
  remove,
} from "ramda";
import dayjs from "dayjs";
import { FormMoneyField } from "commons/components/FormMoneyField";
import { FormQuantityField } from "commons/components/FormQuantityField";
import FormSwitch from "commons/components/FormSwitch";
import { toggle } from "commons/helpers/utils";
import { v4 as uuidv4 } from "uuid";

const base = "transfers";
const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.1)",
  },
}));

export default function Single() {
  const { t } = useTranslate();
  const {
    current,
    send,
    model,
    updateModel,
    mergeModel,
    rules,
  } = useControlledResourcePage(base, { date: dayjs() }, false);

  const isSaved = Boolean(model.id);
  const [facilities] = useResourcesByQuery("facilities", true);
  const [source, setSource] = useState(null);
  const [destination, setDestination] = useState(null);

  const types = [
    { id: "add", name: t("add") },
    { id: "deduct", name: t("deduct") },
    { id: "move", name: t("move") },
    // { id: "assemble", name: t("assemble") },
    // { id: "disassemble", name: t("disassemble") }
  ];

  const getFacilityName = (id) => {
    const facility = facilities.find((fac) => fac.id === id);
    return facility ? facility.name : "";
  };

  const transferDirection = model.type === "add" ? 1 : -1;

  const updateLines = (updates) => {
    const lines = updates.map((line) => ({
      ...line,
      quantity: Math.abs(line.quantity) * transferDirection,
      total_value: Math.abs(line.total_value) * transferDirection,
      requested: model.date,
      fulfilled: model.date,
      facility_id: source,
      operation_type: "transfer",
    }));
    const summary = lines.reduce(
      (acc, line) =>
        (acc += "(" + Math.abs(line.quantity) + " " + line.name + ") "),
      `[${getFacilityName(source)}] ${
        model.type === "move" && destination
          ? "-> [" + getFacilityName(destination) + "] "
          : ""
      }`
    );
    mergeModel({ lines, summary });
  };

  // TODO
  // WHEN Date changes, update each of the dates
  // WHEN TYPE changes, update each of the lines based on direction
  // WHEN Facility Change, reset
  // WHEN deduct or move, make sure settings of allowNegativeStocks and ENOUGH_STOCKS

  return (
    // <>
    //   {JSON.stringify(model)}
    <ResourceSinglePage title={base} current={current} send={send}>
      <FormSelectField
        grid={2}
        label="type"
        options={types}
        value={model.type}
        onChange={updateModel("type")}
        error={rules.type}
        disabled={isSaved}
      />
      <FormDateTimeField
        grid={2}
        label="date"
        value={model.date}
        onChange={updateModel("date")}
        error={rules.date}
        disabled={isSaved}
      />
      <FormTextField
        grid={2}
        label="reference"
        value={model.reference}
        onChange={updateModel("reference")}
        error={rules.reference}
        disabled={isSaved}
      />
      {Boolean(model.type) && (
        <FormSelectField
          grid={3}
          label={model.type === "move" ? t("from") : t("facility")}
          value={source}
          options={facilities}
          onChange={setSource}
          disabled={isSaved}
        />
      )}
      {model.type === "move" && (
        <FormSelectField
          grid={3}
          label="to"
          value={destination}
          options={facilities}
          onChange={setDestination}
          disabled={isSaved}
        />
      )}
      {isSaved && <StockLinesViewer lines={model.lines} />}
      {!isSaved && source && model.type && (
        <StockLinesManager
          source={source}
          type={model.type}
          value={model.lines}
          onChange={updateLines}
        />
      )}
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.reference}
      />
    </ResourceSinglePage>
    // </>
  );
}

function StockLinesViewer({ lines = [] }) {
  const { t } = useTranslate();
  const [codeFilter, setCodeFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");

  return (
    <Grid item sm={12}>
      <CardSection>
        <Grid container spacing={2}>
          <FormTextField
            grid={3}
            label="searchByCode"
            value={codeFilter}
            onChange={setCodeFilter}
          />
          <FormTextField
            grid={3}
            label="searchByName"
            value={nameFilter}
            onChange={setNameFilter}
          />
        </Grid>
      </CardSection>
      <CardSection>
        <Grid container spacing={1} alignItems="center">
          <ValueViewer value={t("code")} grid={2} />
          <ValueViewer value={t("product")} grid={3} />
          <ValueViewer value={t("unit_value")} />
          <ValueViewer value={t("quantity")} />
          <ValueViewer value={t("total_value")} />
          <ValueViewer value={t("serial")} />
          <ValueViewer value={t("mfg_date")} />
          <ValueViewer value={t("exp_date")} />
          <ValueViewer value={t("notice")} />
        </Grid>
      </CardSection>
      <Box minHeight="50vh">
        {lines.map((line, index) => (
          <Grid key={index} container alignItems="center">
            <ValueViewer value={line.code} grid={2} />
            <ValueViewer value={line.product} grid={3} />
            <ValueViewer value={line.value / 100} />
            <ValueViewer value={line.quantity} />
            <ValueViewer value={line.total_value} />
            <ValueViewer value={line.serial} />
            <ValueViewer value={line.mfg_date} />
            <ValueViewer value={line.exp_date} />
            <ValueViewer value={line.notice} />
          </Grid>
        ))}
      </Box>
    </Grid>
  );
}

function ValueViewer({ value, grid = 1 }) {
  return (
    <Grid item sm={grid}>
      <Typography variant="subtitle1" align="center">
        {value}
      </Typography>
    </Grid>
  );
}

function StockLinesManager({ value = [], onChange, source = null }) {
  const { t, language } = useTranslate();
  const listRef = useRef(null);
  const [codeFilter, setCodeFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [showChangesOnly, setShowChangesOnly] = useState(false);
  const [showHasQuantityOnly, setShowHasQuantityOnly] = useState(false);
  const [openedLines, setOpenedLines] = useState([]);
  const [allOpened, setAllOpened] = useState(false);
  const [products] = useResourcesByQuery("facility-product", true, {
    facility_id: source,
  });
  const [levels] = useResourcesByQuery("stock-levels", true, {
    facility_id: source,
  });

  const levelsByKey = useMemo(() => groupBy(prop("product_id"), levels), [
    levels,
  ]);
  const changesByKey = useMemo(() => groupBy(prop("product_id"), value), [
    value,
  ]);

  const onRowChange = (product_id) => (changes) => {
    onChange(Object.values({ ...changesByKey, [product_id]: changes }).flat());
  };

  const getItemSize = (index) => {
    const product = products[index];
    const levels = levelsByKey[product.product_id] || [];
    const changes = changesByKey[product.product_id] || [];
    // return 60 + levels.length * 60 + changes.length * 60 + 60;
    if (isOpened(product.product_id)) {
      return 60 + levels.length * 60 + changes.length * 60 + 60;
    }
    return 60;
  };

  useEffect(() => {
    if (listRef && listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [products, levelsByKey, changesByKey]);

  const toggleLineOpened = (product_id) => {
    setOpenedLines(toggle(Number(product_id), openedLines));
    listRef.current.resetAfterIndex(0);
  };

  const isOpened = (product_id) =>
    allOpened || openedLines.includes(product_id);

  const filteredProducts = useMemo(
    () =>
      products.filter((product) => {
        const hasCode = product.code.startsWith(codeFilter);
        const hasName = product.name.includes(nameFilter);
        const hasChange = showChangesOnly
          ? Boolean(changesByKey[product.product_id])
          : true;
        const hasQuantity = showHasQuantityOnly
          ? Boolean(levelsByKey[product.product_id])
          : true;
        return hasCode && hasName && hasChange && hasQuantity;
      }),
    [
      products,
      changesByKey,
      levelsByKey,
      codeFilter,
      nameFilter,
      showChangesOnly,
      showHasQuantityOnly,
    ]
  );

  return (
    <Grid item sm={12}>
      <CardSection>
        <Grid container spacing={2}>
          <FormTextField
            grid={3}
            label="searchByCode"
            value={codeFilter}
            onChange={setCodeFilter}
          />
          <FormTextField
            grid={3}
            label="searchByName"
            value={nameFilter}
            onChange={setNameFilter}
          />
          <FormSwitch
            grid={3}
            label="showHasQuantityOnly"
            value={showHasQuantityOnly}
            onChange={setShowHasQuantityOnly}
          />
          <FormSwitch
            grid={3}
            label="showChangesOnly"
            value={showChangesOnly}
            onChange={setShowChangesOnly}
          />
        </Grid>
      </CardSection>
      <CardSection p={0}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs sm="auto">
            <IconButton size="small" onClick={() => setAllOpened(!allOpened)}>
              {allOpened ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Grid>
          <Grid item container sm spacing={1} alignItems="center">
            <ValueViewer value={t("code")} grid={2} />
            <ValueViewer value={t("product")} grid={4} />
            <ValueViewer value={t("unit_value")} />
            <ValueViewer value={t("current_qty")} />
            <ValueViewer value={t("change_qty")} />
            <ValueViewer value={t("after_qty")} />
            <ValueViewer value={t("total_value")} />
            <ValueViewer value={t("notice")} />
          </Grid>
        </Grid>
      </CardSection>
      <Box minHeight="50vh">
        <AutoSizer disableWidth>
          {({ height }) => (
            <VariableSizeList
              ref={listRef}
              direction={language === "ar" ? "rtl" : "ltr"}
              height={height}
              itemCount={filteredProducts.length}
              itemSize={getItemSize}
              width="100%"
              overscanCount={5}
              useIsScrolling
              itemData={{
                products: filteredProducts,
                levelsByKey,
                changesByKey,
                onRowChange,
                toggleLineOpened,
                isOpened,
              }}
            >
              {Row}
            </VariableSizeList>
          )}
        </AutoSizer>
      </Box>
    </Grid>
  );
}

// Can Duplicate a record with same data.
// on Each change * amount with 1 or -1 based on type
// if Saved Show all with nothing to change

function Row({ data, index, style, isScrolling }) {
  const {
    products,
    levelsByKey,
    changesByKey,
    onRowChange,
    toggleLineOpened,
    isOpened,
  } = data;
  const product = products[index];
  const classes = useStyles();
  const levels = levelsByKey[product.product_id] || [];
  const changes = changesByKey[product.product_id] || [];

  const total_value = compose(sum, map(prop("total_value")))(levels);
  const total_quantity = compose(sum, map(prop("total_quantity")))(levels);
  const change_qty = compose(sum, map(prop("quantity")))(changes);
  const change_value = compose(sum, map(prop("total_value")))(changes);

  const quantity_after = total_quantity + change_qty;
  const total_value_after = total_value + change_value;
  const unit_value =
    quantity_after > 0 ? total_value_after / quantity_after : 0;

  const onTotalChange = (value) => {
    if (changes.length > 0) {
      onRowChange(product.product_id)(
        adjust(0, assoc("quantity", value), changes)
      );
    } else if (levels.length > 0) {
      const record = omit(["id"], {
        ...levels[0],
        quantity: value,
        name: product.name,
      });
      onRowChange(product.product_id)([record]);
    } else {
      addRecord(value);
    }
  };

  const addRecord = (qty = 0) => {
    onRowChange(product.product_id)([
      ...changes,
      calculate({
        id: uuidv4(),
        product_id: product.product_id,
        name: product.name,
        quantity: qty,
        value: 0,
        serial: null,
        mfg_date: null,
        exp_date: null,
      }),
    ]);
  };

  const deleteRecord = (index) => {
    onRowChange(product.product_id)(remove(index, 1, changes));
  };

  const calculate = (line) => {
    return { ...line, total_value: line.quantity * line.value };
  };

  const updateField = (index, field) => (value) => {
    onRowChange(product.product_id)(
      adjust(index, compose(calculate, assoc(field, value)), changes)
    );
  };

  return (
    <div style={style} className={index % 2 ? classes.strip : ""}>
      {isScrolling ? (
        <p>Calculating...</p>
      ) : (
        <>
          <Grid container alignItems="center">
            <Grid item xs sm="auto">
              <IconButton
                size="small"
                onClick={() => toggleLineOpened(product.product_id)}
              >
                {isOpened(product.product_id) ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown />
                )}
              </IconButton>
            </Grid>
            <Grid item container sm spacing={1} alignItems="center">
              <Grid item xs sm={2}>
                <Typography variant="subtitle1">{product.code}</Typography>
              </Grid>
              <Grid item xs sm={4}>
                <Typography variant="subtitle1">{product.name}</Typography>
              </Grid>
              <Grid item xs sm={1}>
                <Typography variant="subtitle1" align="center">
                  {unit_value / 100}
                </Typography>
              </Grid>
              <Grid item xs sm={1}>
                <Typography variant="subtitle1" align="center">
                  {total_quantity}
                </Typography>
              </Grid>
              <FormQuantityField
                grid={1}
                size="small"
                margin="dense"
                value={change_qty}
                onChange={onTotalChange}
              />
              <Grid item xs sm={1}>
                <Typography variant="subtitle1" align="center">
                  {Math.abs(quantity_after)}
                </Typography>
              </Grid>
              <Grid item xs sm={1}>
                <Typography variant="subtitle1" align="center">
                  {Math.abs(total_value_after / 100)}
                </Typography>
              </Grid>
              <Grid item xs sm={1}></Grid>
            </Grid>
          </Grid>
          {isOpened(product.product_id) && (
            <div>
              {levels.map((level, index) => (
                <Grid container alignItems="center" key={index}>
                  <Grid item xs sm="auto">
                    <IconButton size="small">
                      <FileCopyOutlined />
                    </IconButton>
                  </Grid>
                  <Grid item container sm spacing={1} alignItems="center">
                    <Grid item xs sm={2}>
                      {level.serial}
                    </Grid>
                    <Grid item xs sm={2}>
                      {level.mfg_date}
                    </Grid>
                    <Grid item xs sm={2}>
                      {level.exp_date}
                    </Grid>
                    <Grid item xs sm={1}>
                      <TextField
                        size="small"
                        margin="dense"
                        value={level.value}
                        disabled
                      />
                    </Grid>
                    <Grid item xs sm={1}>
                      <TextField
                        size="small"
                        margin="dense"
                        value={level.total_quantity}
                        disabled
                      />
                    </Grid>
                    <Grid item xs sm={1}></Grid>
                    <Grid item xs sm={1}></Grid>
                    <Grid item xs sm={1}>
                      <TextField
                        size="small"
                        margin="dense"
                        value={level.total_value}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              {changes.map((change, index) => (
                <Grid container alignItems="center" key={change.id}>
                  <Grid item xs sm="auto">
                    <IconButton
                      size="small"
                      onClick={() => deleteRecord(index)}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                  <Grid item container sm spacing={1} alignItems="center">
                    <FormTextField
                      grid={2}
                      size="small"
                      margin="dense"
                      label="serial"
                      value={change.serial}
                      onChange={updateField(index, "serial")}
                    />
                    <FormDateField
                      grid={2}
                      size="small"
                      margin="dense"
                      label="mfg_date"
                      value={change.mfg_date}
                      onChange={updateField(index, "mfg_date")}
                    />
                    <FormDateField
                      grid={2}
                      size="small"
                      margin="dense"
                      label="exp_date"
                      value={change.exp_date}
                      onChange={updateField(index, "exp_date")}
                    />
                    <FormMoneyField
                      grid={1}
                      size="small"
                      margin="dense"
                      value={change.value}
                      onChange={updateField(index, "value")}
                    />
                    <Grid item xs sm={1}></Grid>

                    <FormQuantityField
                      grid={1}
                      size="small"
                      margin="dense"
                      value={change.quantity}
                      onChange={updateField(index, "quantity")}
                    />
                    <Grid item xs sm={1}></Grid>
                    <Grid item xs sm={1}>
                      <Typography variant="subtitle1" align="center">
                        {Math.abs(change.total_value / 100)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
              <Grid container alignItems="center">
                <Grid item xs sm="auto">
                  <IconButton size="small" onClick={() => addRecord()}>
                    <Add />
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      )}
    </div>
  );
}
