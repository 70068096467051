import React from "react";
import ReportManager from "commons/components/ReportManager";
import dayjs from "dayjs";

const filters = [
  {
    type: "select",
    name: "users",
    key: "user_id",
    extras: { optionLabel: "username" },
  },
  { type: "select", name: "projects", key: "project_id" },
];

const columns = [
  { name: "started", type: "datetime" },
  { name: "finished", type: "datetime" },
  { name: "duration", type: "number" },
  { name: "actions", type: "text" },
  { name: "comment", type: "text" },
  { name: "task", type: "text" },
  { name: "user", type: "text" },
];

const recordsFn = (records) =>
  records.map((rec) => {
    if (rec.started && rec.finished) {
      const curr = dayjs(rec.finished).diff(dayjs(rec.started), "minute");
      return { ...rec, duration: curr };
    }
    return { ...rec, duration: 0 };
  });

export default function TasksReport() {
  return (
    <ReportManager
      url="task-steps"
      title="tasksReport"
      columns={columns}
      recordsFunc={recordsFn}
      filterByDate
      dateField="started"
      filters={filters}
    />
  );
}
