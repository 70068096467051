import React from "react";
import { Box, Paper, Grow } from "@material-ui/core";

export default function PageCard({ children }) {
  return (
    <Grow in style={{ transformOrigin: "50% 0 0" }}>
      <Paper elevation={4}>
        <Box p={2}>{children}</Box>
      </Paper>
    </Grow>
  );
}
