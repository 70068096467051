import { sumField, parseValue } from "commons/helpers/utils";

export const calcLine = (line) => {
  const actual = line.quantity - line.returned;
  const subtotal = line.price * actual;
  const discounts = line.discounts.map((discount) => ({
    ...discount,
    applied_value: calcDiscount(subtotal, actual, discount),
  }));
  const commissions = line.commissions.map((commission) => ({
    ...commission,
    applied_value: calcCommission(subtotal, commission),
  }));

  const discount = sumField("applied_value")(discounts);
  const commission = sumField("applied_value")(commissions);
  const total = Math.max(0, subtotal - discount);
  const cost = Number(line.savedCost) + sumField("total_value")(line.stocks);
  return {
    ...line,
    actual,
    discounts,
    commissions,
    cost_price: Math.floor(cost / actual),
    subtotal,
    discount,
    total,
    cost,
    commission,
    profit: total - cost - commission,
  };
};

export const calcModel = (model) => {
  const subtotal = sumField("subtotal")(model.lines);

  const discounts = model.discounts.map((discount) => ({
    ...discount,
    applied_value: calcDiscount(subtotal, model.lines.length, discount),
  }));
  const taxes = model.taxes.map((tax) => ({
    ...tax,
    applied_value: calcTax(subtotal, tax),
  }));
  const commissions = model.commissions.map((commission) => ({
    ...commission,
    applied_value: calcCommission(subtotal, commission),
  }));
  const model_discounts = sumField("applied_value")(discounts);
  const lines_discounts = sumField("discount")(model.lines);
  const discount = model_discounts + lines_discounts;

  const tax = sumField("applied_value")(taxes);

  const model_commissions = sumField("applied_value")(commissions);
  const lines_commissions = sumField("commission")(model.lines);
  const commission = model_commissions + lines_commissions;

  const lines_total = sumField("total")(model.lines);
  const total = lines_total + tax - model_discounts;

  const cost = sumField("cost")(model.lines);
  const profit = total - cost - commission;

  return {
    ...model,
    discounts,
    commissions,
    taxes,
    subtotal,
    discount,
    commission,
    tax,
    total,
    cost,
    profit,
  };
};

const calcDiscount = (amount, qty, discount) => {
  const { value, min_quantity, min_price, max_value } = discount;
  if (qty < Number(min_quantity) || amount < Number(min_price)) return 0;
  return parseValue(value, amount, 0, Number(max_value));
};

const calcTax = (amount, tax) => {
  const { value, type, min_price, max_value } = tax;
  if (amount < Number(min_price)) return 0;
  const applied = parseValue(value, amount, 0, Number(max_value));
  if (type === "minimum_charge") {
    return amount > applied ? 0 : applied - amount;
  }
  return applied;
};

const calcCommission = (amount, commission) => {
  const { value, min_price, max_value } = commission;
  if (amount < Number(min_price)) return 0;
  return parseValue(value, amount, 0, Number(max_value));
};
