import React from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormSelectField from "commons/components/FormSelectField";
import FormTextField from "commons/components/FormTextField";
import { FormDateField } from "commons/components/FormDateField";
import { FormMoneyField } from "commons/components/FormMoneyField";
// import { PaymentForm } from "/commons/components/Balance";

const base = "rewards";

export default function Single({ query, title, label }) {
  const {
    current,
    send,
    model,
    updateModel,
    rules,
  } = useControlledResourcePage(base, {}, true, title);
  const [employees] = useResourcesByQuery("employees", true, query);

  return (
    <ResourceSinglePage title={title} current={current} send={send}>
      <FormSelectField
        grid={4}
        options={employees}
        label={label}
        value={model.employee_id}
        onChange={updateModel("employee_id")}
        error={rules.employee_id}
      />
      <FormDateField
        grid={4}
        label="date"
        value={model.date}
        onChange={updateModel("date")}
        error={rules.date}
      />
      <FormMoneyField
        grid={4}
        label="amount"
        value={model.amount}
        onChange={updateModel("amount")}
        error={rules.amount}
      />
      <FormTextField
        multiline
        label="reason"
        value={model.reason}
        onChange={updateModel("reason")}
        error={rules.reason}
      />
    </ResourceSinglePage>
  );
  //  {Boolean(model.id) && (
  //   <div className="size-12">
  //     <PaymentForm
  //       base="employee-payments"
  //       field="employee_id"
  //       id={model.employee_id}
  //       onChange={updateModel("employee_payment_id")}
  //       recordID={model.employee_payment_id}
  //       noReset
  //     />
  //   </div>
  // )}
}
