import React from "react";
import { Typography, Hidden, Grow } from "@material-ui/core";

export default function Home({ logo, companyName }) {
  return (
    <Grow in>
      <div
        style={{
          height: "100%",
          textAlign: "center",
          paddingTop: "25vh",
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{ maxWidth: "50%", maxHeight: "50vh" }}
        />
        <Hidden xsDown>
          <Typography style={{ fontWeight: "bold" }} variant="h1">
            {companyName}
          </Typography>
        </Hidden>
        <Hidden smUp>
          <Typography style={{ fontWeight: "bold" }} variant="h4">
            {companyName}
          </Typography>
        </Hidden>
      </div>
    </Grow>
  );
}
