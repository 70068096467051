import { useCallback, useEffect } from "react";
import { useMachine } from "@xstate/react";
import { createResourceMachine } from "commons/machines/resourceMachine";
import api from "commons/helpers/api";
import { equals } from "ramda";

export default function useResourceMachine(
  url,
  id,
  initialModel = {},
  autosave = true
) {
  const machine = createResourceMachine(
    url,
    id === "create" ? initialModel : { id },
    autosave
  );
  const [current, send, service] = useMachine(machine);
  const { model, rules, error } = current.context;

  const updateModel = useCallback(
    (propery) => (val) => send("UPDATE", { data: { [propery]: val } }),
    [send]
  );

  const mergeModel = useCallback((obj) => send("UPDATE", { data: obj }), [
    send,
  ]);

  const onDataChange = useCallback(
    (obj) => {
      if (model.id === obj.id && !equals(model, obj)) {
        send("OVERRIDE", { data: obj });
      }
    },
    [model, send]
  );

  useEffect(() => {
    api.service(url).on("updated", onDataChange);
    api.service(url).on("patched", onDataChange);
    return () => {
      api.service(url).removeListener("updated", onDataChange);
      api.service(url).removeListener("patched", onDataChange);
    };
  }, [url, onDataChange]);

  return {
    id,
    model,
    rules,
    error,
    send,
    updateModel,
    mergeModel,
    current,
    service,
  };
}
