import React, { useState, useEffect, useMemo } from "react";
import PageCard from "commons/components/PageCard";
import ResourceToolbar from "commons/components/ResourceToolbar";
import ResourceEditor from "commons/components/ResourceEditor";
import FormTextField from "commons/components/FormTextField";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import LinkTabs from "commons/components/LinkTabs";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@material-ui/core";
import FormFileField from "commons/components/FormFileField";
import FormSwitch from "commons/components/FormSwitch";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { sortBy, prop } from "ramda";
import { DragHandle } from "@material-ui/icons";
import CardSection from "commons/components/CardSection";
import useTranslate from "commons/hooks/useTranslate";
import api from "commons/helpers/api";
import LoadingIndicator from "commons/components/LoadingIndicator";
import ErrorAlert from "commons/components/ErrorAlert";

const base = "categories";
const initialModel = {
  saleable: true,
  purchasable: true,
};

export default function Single() {
  const { current, send, model, updateModel, id } = useControlledResourcePage(
    base,
    initialModel
  );
  const hasResource = Boolean(model.id);

  return (
    <PageCard>
      <ResourceToolbar title={base} current={current} send={send} accessGroup />
      <LinkTabs
        show={hasResource}
        tabs={[
          {
            name: "editor",
            path: `/s/categories/${id}`,
            component: (
              <Editor current={current} send={send} updateModel={updateModel} />
            ),
          },
          {
            name: "category_sorting",
            path: `/s/categories/${id}/sorting`,
            component: <Sorting id={id} />,
          },
        ]}
      />
    </PageCard>
  );
}

export function Editor({ current, send, updateModel, ...props }) {
  const { model, rules } = current.context;
  const [categories] = useResourcesByQuery("categories", true);

  return (
    <ResourceEditor current={current} send={send} {...props}>
      <Grid item xs={12} sm={3}>
        <FormFileField
          label="image"
          value={model.image}
          onChange={updateModel("image")}
          error={rules.image}
          preview
        />
      </Grid>
      <Grid item xs={12} container sm={9} spacing={2}>
        <FormTextField
          label="name"
          value={model.name}
          onChange={updateModel("name")}
          error={rules.name}
        />
        <FormSelectField
          options={categories}
          label="category_id"
          value={model.category_id}
          onChange={updateModel("category_id")}
          error={rules.category_id}
        />
        <FormSwitch
          grid={6}
          label="sales"
          value={model.sales}
          onChange={updateModel("sales")}
        />
        <FormSwitch
          grid={6}
          label="purchases"
          value={model.purchases}
          onChange={updateModel("purchases")}
        />
      </Grid>
    </ResourceEditor>
  );
}

export function Sorting({ id }) {
  const [categories] = useResourcesByQuery("categories", true, {
    category_id: id,
  });
  const [products] = useResourcesByQuery("products", true, {
    "products.category_id": id,
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <ResourcesSorting
          base="categories"
          title="categories"
          items={categories}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ResourcesSorting base="products" title="offerings" items={products} />
      </Grid>
    </Grid>
  );
}

function ResourcesSorting({ base, title, items }) {
  const { t } = useTranslate();
  const [models, setModels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setModels(sortBy(prop("order_in_category"), items));
  }, [items]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const changes = useMemo(
    () =>
      models.reduce((acc, curr, index) => {
        if (curr.order_in_category !== index) {
          return { ...acc, [curr.id]: index };
        }
        return acc;
      }, {}),
    [models]
  );

  const onDragEnd = (result) => {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;
    setModels((old) =>
      reorder(old, result.source.index, result.destination.index)
    );
  };

  useEffect(() => {
    if (Object.keys(changes).length > 0) {
      setLoading(true);
      const ops = Object.keys(changes).map((key) =>
        api.service(base).patch(key, {
          order_in_category: changes[key],
        })
      );
      Promise.all(ops)
        .catch(setError)
        .finally(() => {
          setLoading(false);
        });
    }
  }, [base, changes]);

  return (
    <CardSection>
      <Typography variant="h6">{t(title)}</Typography>
      <LoadingIndicator show={loading} />
      <ErrorAlert error={error} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={base}>
          {({ innerRef, droppableProps, placeholder }) => (
            <List ref={innerRef} {...droppableProps}>
              {models.map((model, index) => (
                <Draggable
                  key={model.id}
                  draggableId={String(model.id)}
                  index={index}
                >
                  {({ innerRef, dragHandleProps, draggableProps }) => (
                    <ListItem
                      ref={innerRef}
                      {...draggableProps}
                      {...dragHandleProps}
                    >
                      <ListItemIcon>
                        <DragHandle />
                      </ListItemIcon>
                      <ListItemText primary={model.name} />
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </CardSection>
  );
}
