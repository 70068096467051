import { useContext } from "react";
import { PrintTemplatesContext } from "commons/helpers/contexts";

export default function usePrintTemplates() {
  const { templates } = useContext(PrintTemplatesContext);

  return {
    templates,
  };
}
