import { useState, useEffect, useMemo, useCallback } from "react";
import dayjs from "dayjs";
import { update, remove, insert, compose } from "ramda";
import { sumField } from "commons/helpers/utils";
import { calcLine, calcModel } from "../utils/calculators";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import { useFrontDeskExtras } from "..";
import { v4 as uuidv4 } from "uuid";
import {
  prepareDiscount,
  prepareCommission,
  getProductTypeFields,
  prepareTax,
} from "../utils/initializers";
import api from "commons/helpers/api";

export default function useControlledFrontdeskModel(base) {
  const {
    current,
    send,
    model,
    updateModel,
    mergeModel,
    rules,
  } = useControlledResourcePage(base, {}, false);
  const payment_field = base === "sales" ? "customer_id" : "supplier_id";

  const onPayment = (payment) => {
    updateModel("payments")([
      ...model.payments,
      {
        ...payment,
        [payment_field]: model[payment_field],
      },
    ]);
    send("SAVE");
  };

  const onChangeExtras = (name) => (updates) => {
    mergeModel(calcModel({ ...model, [name]: updates }));
  };

  const all = useFrontDeskModel(model, mergeModel, base);

  return {
    ...all,
    model,
    current,
    send,
    updateModel,
    rules,
    onPayment,
    onChangeExtras,
  };
}

function stocksByProduct(acc, curr) {
  return {
    ...acc,
    [curr.product_id]: [
      ...(acc[curr.product_id] ? acc[curr.product_id] : []),
      curr,
    ],
  };
}

function useFrontdeskStocks() {
  const [rawSettings] = useResourcesByQuery("settings", true);
  const [stockLevels, send] = useResourcesByQuery("stock-levels", true);

  const onDataChange = useCallback(() => {
    send("SET_QUERY", { query: {} });
  }, [send]);

  useEffect(() => {
    api.service("sales").on("created", onDataChange);
    api.service("sales").on("updated", onDataChange);
    api.service("sales").on("removed", onDataChange);
    api.service("purchases").on("created", onDataChange);
    api.service("purchases").on("updated", onDataChange);
    api.service("purchases").on("removed", onDataChange);
    return () => {
      api.service("sales").removeListener("created", onDataChange);
      api.service("sales").removeListener("updated", onDataChange);
      api.service("sales").removeListener("removed", onDataChange);
      api.service("purchases").removeListener("created", onDataChange);
      api.service("purchases").removeListener("updated", onDataChange);
      api.service("purchases").removeListener("removed", onDataChange);
    };
  }, [onDataChange]);

  const settings = useMemo(() => {
    return rawSettings.reduce((acc, curr) => {
      if (
        [
          "autoStockChange",
          "autoFulfilStocks",
          "autoPrintOnStockChange",
          "autoPrintOnPayment",
          "allowNegativeStocks",
        ].includes(curr.key)
      ) {
        if (curr.value === "true") {
          return { ...acc, [curr.key]: true };
        }
        return { ...acc, [curr.key]: false };
      }
      return acc;
    }, {});
  }, [rawSettings]);

  return [stockLevels.reduce(stocksByProduct, {}), settings];
}

export function useFrontDeskModel(model, mergeModel, base) {
  const [products] = useResourcesByQuery("facility-product", true, {
    active: true,
  });
  const [policies] = useResourcesByQuery("policy-product", true);
  const [facilities] = useResourcesByQuery("facilities", true);
  const [locations] = useResourcesByQuery("product-service-location", true);
  const [error, setError] = useState(null);
  const [stockLevels, settings] = useFrontdeskStocks();
  const { discounts, commissions, taxes, employees } = useFrontDeskExtras();

  useEffect(() => {
    if (Object.keys(model).length === 0) {
      mergeModel({
        facility_id: null,
        date: dayjs(),
        lines: [],
        payments: [],
        discounts: [],
        taxes: [],
        commissions: [],
        stocks: [],
      });
    }
  }, [model, mergeModel]);

  const lineFromProduct = (product) => ({
    id: uuidv4(),
    name: product.name,
    code: product.code,
    type: product.type,
    date: model.date,
    savedQty: 0,
    savedReturned: 0,
    savedCost: 0,
    quantity: 0,
    returned: 0,
    actual: 0,
    product_id: product.product_id,
    stocks: [],
    discounts: discounts
      .filter(discountMatchesProduct(model, product.product_id))
      .map(prepareDiscount),
    commissions: commissions
      .filter(commissionMatchesProduct(product.product_id))
      .map(prepareCommission),
    ...getProductPrice(
      policies,
      model.policy_id,
      product,
      base === "sales" ? "sale_price" : "purchase_price"
    ),
    ...getProductTypeFields(product),
  });

  const onProductSelect = (product_id) => {
    const index = model.lines.findIndex(
      (line) => line.product_id === product_id
    );
    const product = products.find((prod) => prod.product_id === product_id);
    if (!product) {
      setError({ message: "PRODUCT_NOT_FOUND", product: product_id });
      return;
    }
    const line = index !== -1 ? model.lines[index] : lineFromProduct(product);
    const updated = validateStockMove(line, line.quantity + 1);
    if (updated === null) return;
    const changed_lines =
      index !== -1
        ? update(index, calcLine(updated), model.lines)
        : [...model.lines, calcLine(updated)];
    updateLines(changed_lines);
  };

  const createStockLine = (product_id, value, quantity) => {
    return {
      id: Date.now(),
      product_id,
      value,
      quantity,
      facility_id: model.facility_id,
      requested: model.date,
      fulfilled: settings["autoFulfilStocks"] ? model.date : null,
      serial: null,
      mfg_date: null,
      exp_date: null,
      total_value: value * quantity,
    };
  };

  const getStockLine = (product_id, stock_id) => {
    const lineIndex = model.lines.findIndex(
      (line) => line.product_id === product_id
    );
    if (lineIndex === -1) return null;
    const line = model.lines[lineIndex];
    const stockIndex = line.stocks.findIndex((stock) => stock.id === stock_id);
    if (stockIndex === -1) return null;
    return [lineIndex, stockIndex];
  };

  const onStockChangeData = (product_id, stock_id, field) => (value) => {
    const result = getStockLine(product_id, stock_id);
    if (result === null) return;
    const [lineIndex, stockIndex] = result;
    const line = model.lines[lineIndex];
    const stock = line.stocks[stockIndex];
    const updatedStocks = update(
      stockIndex,
      { ...stock, [field]: value },
      line.stocks
    );
    const updatedLines = update(
      lineIndex,
      calcLine({ ...line, stocks: updatedStocks }),
      model.lines
    );
    updateLines(updatedLines);
  };

  const onStockRemove = (product_id, stock_id) => {
    const result = getStockLine(product_id, stock_id);
    if (result === null) return;
    const [lineIndex, stockIndex] = result;
    const line = model.lines[lineIndex];
    const updatedStocks = remove(stockIndex, 1, line.stocks);
    const updatedLines = update(
      lineIndex,
      calcLine({ ...line, stocks: updatedStocks }),
      model.lines
    );
    updateLines(updatedLines);
  };

  const onStockSplit = (product_id, stock_id) => {
    const result = getStockLine(product_id, stock_id);
    if (result === null) return;
    const [lineIndex, stockIndex] = result;
    const line = model.lines[lineIndex];
    const stock = line.stocks[stockIndex];
    const dir = stock.quantity < 0 ? -1 : 1;
    const updatedStocks = compose(
      insert(stockIndex + 1, {
        ...stock,
        quantity: dir,
        id: Date.now(),
        total_value: stock.value * dir,
      }),
      update(stockIndex, {
        ...stock,
        quantity: stock.quantity - dir,
        total_value: stock.value * (stock.quantity - dir),
      })
    )(line.stocks);
    const updatedLines = update(
      lineIndex,
      calcLine({ ...line, stocks: updatedStocks }),
      model.lines
    );
    updateLines(updatedLines);
  };

  const validateStockMove = (line, qty) => {
    setError(null);
    const product = products.find(
      (prod) => prod.product_id === line.product_id
    );
    if (!product) {
      setError({ message: "PRODUCT_NOT_FOUND", product: line.code });
      return null;
    }
    const max_in_sale = Number(product.max_in_sale);
    if (base === "sales" && max_in_sale > 0 && max_in_sale < qty) {
      setError({ message: "MAX_IN_SALE", product: line.code });
      return null;
    }
    if (!product.stockable || !settings["autoStockChange"]) {
      return { ...line, quantity: qty };
    }
    // if already saved, must be retuned not decreased.
    if (line.savedQty * 1 > qty) {
      setError({ message: "RETURN_QUANTITY", product: line.code });
      return null;
    }
    if (base === "purchases") {
      return {
        ...line,
        quantity: qty,
        stocks: [
          createStockLine(line.product_id, line.price, qty - line.savedQty),
        ],
      };
    } else {
      const levels = stockLevels[product.product_id] || [];
      const max = sumField("quantity")(levels);
      const change = qty - line.savedQty;
      if (max < change && !settings["allowNegativeStocks"]) {
        setError({ message: "NO_STOCKS", product: line.code });
        return null;
      }
      return {
        ...line,
        quantity: qty,
        stocks: getStocksChanges(
          line,
          levels.filter((level) => level.quantity > 0),
          change,
          -1
        ),
      };
    }
  };

  const validateReturnStockMove = (line, qty) => {
    setError(null);
    const product = products.find(
      (prod) => prod.product_id === line.product_id
    );
    if (!product) return null;
    if (!product.stockable || !settings["autoStockChange"]) {
      return { ...line, returned: qty };
    }
    // CANNOT RETURN MORE THAN WHAT WAS SAVED, OR REDUCE WHAT WAS REDUCED.
    if (line.savedQty * 1 < qty || line.savedReturned * 1 > qty) {
      setError({ message: "CANNOT_RETURN", product: line.code });
      return null;
    }
    const levels = model.stocks.filter(
      (stock) => stock.product_id === line.product_id
    );
    const max = sumField("quantity")(levels);
    const change = qty - line.savedReturned;
    const notEnoushStocks =
      base === "purchases"
        ? Math.abs(max) < change && !settings["allowNegativeStocks"]
        : Math.abs(max) < change;
    console.log(max, change, notEnoushStocks);
    if (notEnoushStocks) {
      setError({ message: "NO_STOCKS", product: line.code });
      return null;
    }
    return {
      ...line,
      returned: qty,
      stocks: getStocksChanges(
        line,
        levels.filter((level) => level.quantity > 0),
        change,
        base === "purchases" ? -1 : 1
      ),
    };
  };

  const getStocksChanges = (line, levels = [], qty, direction) => {
    if (qty === 0) return [];
    if (levels.length === 0)
      return [createStockLine(line.product_id, line.price, qty * direction)];
    const [curr, ...rest] = levels;
    const currQty = Number(curr.quantity);
    const currHasEnoughStocks = currQty >= qty;
    const quantity = currHasEnoughStocks ? qty : currQty;
    const remaining = currHasEnoughStocks ? 0 : currQty - qty;
    return [
      {
        ...curr,
        id: Date.now(),
        requested: dayjs(),
        fulfilled: settings["autoFulfilStocks"] ? dayjs() : null,
        quantity: quantity * direction,
        total_value: curr.value * quantity * direction,
      },
      ...getStocksChanges(line, rest, remaining, direction),
    ];
  };

  const onChangeLineQuantity = (index, qty, extras = {}) => {
    const line = model.lines[index];
    const updated = validateStockMove(line, qty);
    if (updated === null) return;

    updateLines(
      update(index, calcLine({ ...updated, ...extras }), model.lines)
    );
  };

  const onChangeReturnedQuantity = (index, qty, extras = {}) => {
    const line = model.lines[index];
    const updated = validateReturnStockMove(line, qty);
    if (updated === null) return;
    updateLines(
      update(index, calcLine({ ...updated, ...extras }), model.lines)
    );
  };

  const updateLines = (lines) => {
    const summary = lines.reduce(
      (acc, line) =>
        (acc += "(" + Math.abs(line.actual) + " " + line.name + ") "),
      ""
    );
    mergeModel({ lines, summary, ...calcModel({ ...model, lines }) });
  };

  const onCustomerChange = (customer) => {
    mergeModel({ customer_id: customer.id, policy_id: customer.policy_id });
    updateLinesPrices(customer.policy_id);
    updateActiveExtras(customer.policy_id);
  };

  const onSupplierChange = (supplier) => {
    mergeModel({ supplier_id: supplier.id, policy_id: supplier.policy_id });
    updateLinesPrices(supplier.policy_id);
    // updateActiveExtras(customer.policy_id);
  };

  const updateLinesPrices = (policy_id) => {
    if (policy_id) {
      const lines = model.lines.map((line) => {
        const policy = policies.find(
          (pol) =>
            pol.policy_id === policy_id && pol.product_id === line.product_id
        );
        if (policy) {
          return calcLine({ ...line, price: policy.sale_price });
        } else {
          return line;
        }
      });
      updateLines(lines);
    }
  };

  const updateActiveExtras = (policy_id) => {
    mergeModel(
      calcModel({
        ...model,
        taxes: taxes.filter(isActive(model)).map(prepareTax),
        discounts: discounts.filter(isActive(model)).map(prepareDiscount),
      })
    );
  };

  return {
    error,
    onProductSelect,
    updateLines,
    onChangeLineQuantity,
    onChangeReturnedQuantity,
    products,
    facilities,
    policies,
    locations,
    stockLevels,
    settings,
    discounts,
    commissions,
    taxes,
    employees,
    onCustomerChange,
    onSupplierChange,
    onStockChangeData,
    onStockRemove,
    onStockSplit,
  };
}

const discountMatchesProduct = (model, product_id) => (discount) =>
  discount.products.includes(product_id) && isActive(model)(discount);

const commissionMatchesProduct = (product_id) => (commission) =>
  commission.product_id === product_id && commission.permanent === true;

const getProductPrice = (
  policies,
  policy_id,
  product,
  field = "sale_price"
) => {
  const policy = policies.find(
    (pol) =>
      pol.policy_id === policy_id && pol.product_id === product.product_id
  );
  return {
    price: policy ? policy.price : product[field],
    cost_price: product.cost_price,
  };
};

const isActive = ({ date, policy_id }) => (item) =>
  (item.policies.includes(policy_id) || item.policies.length === 0) &&
  dayjs(date).isBetween(item.active_from, item.active_to);
