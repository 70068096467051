import React, { useMemo } from "react";
import {
  useControlledFrontDeskModel,
  FrontdeskMain,
  TotalsCard,
  PaymentsCard,
  ExtrasCard,
  LinesManager,
  FrontdeskData,
  EntityCard,
} from "commons/frontdesk";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import Stack from "commons/components/Stack";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import { Grid } from "@material-ui/core";
import FormTextField from "commons/components/FormTextField";

dayjs.extend(isBetween);
const base = "sales";

export default function Single() {
  const {
    model,
    updateModel,
    rules,
    error,
    current,
    send,
    onProductSelect,
    updateLines,
    products,
    locations,
    stockLevels,
    discounts,
    commissions,
    taxes,
    employees,
    onPayment,
    onChangeExtras,
    onCustomerChange,
    onChangeLineQuantity,
  } = useControlledFrontDeskModel(base);
  const [customers] = useResourcesByQuery("customers", true);

  const filteredProducts = useMemo(
    () => products.filter((prod) => prod.facility_id === model.facility_id),
    [products, model.facility_id]
  );

  const filteredCustomers = useMemo(
    () => customers.filter((cust) => cust.facility_id === model.facility_id),
    [customers, model.facility_id]
  );

  return (
    <FrontdeskMain
      base={base}
      error={error}
      current={current}
      send={send}
      products={filteredProducts}
      onProductSelect={onProductSelect}
    >
      <FrontdeskData
        model={model}
        rules={rules}
        updateModel={updateModel}
        products={filteredProducts}
        onProductSelect={onProductSelect}
        grid={8}
      >
        <EntityCard
          entities={filteredCustomers}
          type="customers"
          model={model}
          rules={rules}
          onEntityChange={onCustomerChange}
        />
      </FrontdeskData>
      <Grid item sm={9}>
        <LinesManager
          value={model.lines}
          onChange={updateLines}
          onChangeLineQuantity={onChangeLineQuantity}
          discounts={discounts}
          commissions={commissions}
          products={filteredProducts}
          locations={locations}
          stockLevels={stockLevels}
        />
      </Grid>
      <Grid item sm={3}>
        <Stack>
          <TotalsCard model={model} showCost showProfit />
          <PaymentsCard
            payments={model.payments}
            total={model.total}
            onPayment={onPayment}
            disabled={!Boolean(model.customer_id)}
          />
          <ExtrasCard
            items={model.discounts}
            options={discounts}
            title="discounts"
            onChange={onChangeExtras("discounts")}
          />
          <ExtrasCard
            items={model.taxes}
            options={taxes}
            title="taxes"
            onChange={onChangeExtras("taxes")}
          />
          <ExtrasCard
            items={model.commissions}
            options={employees}
            title="commissions"
            onChange={onChangeExtras("commissions")}
          />
        </Stack>
      </Grid>
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.reference}
      />
    </FrontdeskMain>
  );
}
