import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";
import { IconButton, Tooltip } from "@material-ui/core";
import { Timer } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import useTranslate from "commons/hooks/useTranslate";

const columns = [
  {
    name: "employee",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "employees.name",
  },
  {
    name: "enter",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "exit",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "hours",
    type: "number",
    sortable: true,
    searchable: true,
  },
];

export default function List({ title, query }) {
  const { t } = useTranslate();
  return (
    <ResourceListPage
      url="attendances"
      columns={columns}
      title={title}
      baseQuery={query}
      actions={
        <Tooltip title={t("shifts")}>
          <IconButton
            component={RouterLink}
            to={`/s/attendances/manage-by-shift`}
          >
            <Timer />
          </IconButton>
        </Tooltip>
      }
    />
  );
}
