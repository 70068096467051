import React from "react";
import { adjust, remove, assoc } from "ramda";
import { Grid, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import FormSelectField from "commons/components/FormSelectField";
import FormTextField from "commons/components/FormTextField";
import CardSection from "commons/components/CardSection";
import {
  prepareDiscount,
  prepareCommission,
  prepareTax,
} from "../utils/initializers";

const getExtrasProps = (name) => {
  switch (name) {
    case "discounts":
      return {
        key: "discount_id",
        prepare: prepareDiscount,
      };
    case "commissions":
      return {
        key: "employee_id",
        prepare: prepareCommission,
      };
    default:
      return {
        key: "tax_id",
        prepare: prepareTax,
      };
  }
};

export default function ExtrasCard({
  title,
  options = [],
  items = [],
  onChange,
  Wrapper = CardSection,
}) {
  const { key, prepare } = getExtrasProps(title);

  const onItemSelect = (id) => {
    const exists = items.find((item) => item[key] === id);
    const item = options.find((item) => item.id === id);

    if (!exists && item) {
      onChange([...items, prepare(item)]);
    }
  };

  const onItemValueChange = (index, field) => (value) => {
    onChange(adjust(index, assoc(field, value), items));
  };
  const onRemove = (index) => {
    onChange(remove(index, 1, items));
  };

  return (
    <Wrapper>
      <Grid container spacing={1}>
        <FormSelectField
          label={title}
          value={null}
          options={options}
          onChange={onItemSelect}
        />
      </Grid>
      {items.map((item, index) => (
        <Grid container spacing={1} key={item[key]} alignItems="center">
          <Grid item xs>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={4}>
                {item.name}
              </Grid>
              <FormTextField
                grid={4}
                size="small"
                margin="dense"
                value={item.value}
                onChange={onItemValueChange(index, "value")}
              />
              <FormTextField
                grid={4}
                size="small"
                margin="dense"
                value={item.applied_value / 100}
                disabled
              />
            </Grid>
          </Grid>
          <Grid item xs="auto">
            <IconButton size="small" onClick={() => onRemove(index)}>
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </Wrapper>
  );
}
