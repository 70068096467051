import React, { useMemo } from "react";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";
import FormManager from "./FormManager";

export default function Editor({
  current,
  updateModel,
  disableCustomer = false,
}) {
  const { model, rules } = current.context;
  const [templates] = useResourcesByQuery("form-templates", true);
  const [customers] = useResourcesByQuery("customers", true);

  const template = useMemo(() => {
    if (!model.form_template_id) return null;
    const template = templates.find(
      (template) => template.id === model.form_template_id
    );
    if (template) {
      return template.body;
    }
    return null;
  }, [templates, model.form_template_id]);

  return (
    <>
      <FormSelectField
        grid={6}
        options={templates}
        label="form_template_id"
        value={model.form_template_id}
        onChange={updateModel("form_template_id")}
        error={rules.form_template_id}
      />
      <FormSelectField
        grid={6}
        options={customers}
        label="customer_id"
        value={model.customer_id}
        onChange={updateModel("customer_id")}
        error={rules.customer_id}
        disabled={disableCustomer}
      />
      {template && (
        <FormManager
          template={template}
          value={
            typeof model.body === "string" ? JSON.parse(model.body) : model.body
          }
          onChange={(val) => updateModel("body")(JSON.stringify(val))}
        />
      )}
    </>
  );
}
