import React from "react";
import useTranslate from "commons/hooks/useTranslate";
import { useMutation } from "react-query";
import { Tooltip, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import LoadingIndicator from "./LoadingIndicator";
import ErrorAlert from "./ErrorAlert";
import api from "commons/helpers/api";
import { replace } from "ramda";

function deleteResources({ url, resources }) {
  return api.service(url).remove(null, {
    query: {
      [`${replace("-", "_", url)}.id`]: {
        $in: resources,
      },
    },
  });
}

export default function ResourceRemoveButton({
  url,
  resources,
  onResourcesRemoved,
}) {
  const { t } = useTranslate();
  const [mutate, { status, error }] = useMutation(deleteResources, {
    throwOnError: true,
  });

  const handleResourceRemove = async () => {
    try {
      await mutate({ url, resources });
    } catch (e) {
      console.log(e);
    }
  };

  const isLoading = status === "loading";

  return (
    <>
      <LoadingIndicator show={isLoading} />
      <ErrorAlert error={error} />
      <Tooltip title={t("delete")}>
        <IconButton onClick={handleResourceRemove}>
          <Delete />
        </IconButton>
      </Tooltip>
    </>
  );
}
