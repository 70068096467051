import React from "react";
import { Grid, Typography, Divider, Box } from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";
import { prop } from "ramda";
import CardSection from "commons/components/CardSection";

export function TotalsLine({ title, value }) {
  return (
    <Box p={1}>
      <Grid container>
        <Grid item xs>
          <Typography variant="subtitle1">{title}</Typography>
        </Grid>
        <Grid item xs="auto">
          <Typography variant="subtitle1">{value}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default function TotalsCard({
  model,
  showCost = false,
  showProfit = false,
}) {
  const { t } = useTranslate();
  const count =
    model && model.lines
      ? new Set(model.lines.map(prop("product_id"))).size
      : 0;
  const subtotal = model.subtotal || 0;
  const discount = model.discount || 0;
  const tax = model.tax || 0;
  const commission = model.commission || 0;
  const total = model.total || 0;
  const cost = model.cost || 0;
  const profit = model.profit || 0;
  return (
    <CardSection p={0}>
      <TotalsLine title={t("count")} value={count} />
      <Divider />
      <TotalsLine title={t("subtotal")} value={subtotal / 100} />
      <Divider />
      <TotalsLine title={t("discounts")} value={discount / 100} />
      <Divider />
      <TotalsLine title={t("taxes")} value={tax / 100} />
      <Divider />
      <Box p={1} bgcolor="primary.main">
        <Grid container>
          <Grid item xs>
            <Typography variant="h6">{t("total")}</Typography>
          </Grid>
          <Grid item xs="auto">
            <Typography variant="h6">{total / 100}</Typography>
          </Grid>
        </Grid>
      </Box>
      <Divider />
      <TotalsLine title={t("commission")} value={commission / 100} />
      <Divider />
      {showCost && (
        <>
          <TotalsLine title={t("cost")} value={cost / 100} />
          <Divider />
        </>
      )}
      {showProfit && (
        <>
          <TotalsLine title={t("profit")} value={profit / 100} />
          <Divider />
        </>
      )}
    </CardSection>
  );
}
