import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "date",
    type: "datetime",
    sortable: true,
    searchable: true,
  },
  {
    name: "type",
    sortable: true,
    searchable: true,
    type: "translate",
  },
  {
    name: "reference",
    sortable: true,
    searchable: true,
    type: "text",
  },
  {
    name: "notes",
    sortable: true,
    searchable: true,
    type: "text",
  },
  {
    name: "summary",
    sortable: true,
    searchable: true,
    type: "text",
  },
];

export default function List() {
  return <ResourceListPage url="transfers" columns={columns} />;
}
