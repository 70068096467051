import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "name",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "parent_name",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "facility",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "facilities.name",
  },
  {
    name: "address",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "dob",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "nid",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "primary_phone",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "secondary_phone",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "email",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "dob_certificate",
    type: "boolean",
    sortable: true,
    searchable: true,
  },
  {
    name: "notes",
    sortable: true,
    searchable: true,
    type: "text",
  },
];

export default function List({ title = "customers" }) {
  return <ResourceListPage url="customers" columns={columns} title={title} />;
}
