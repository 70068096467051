import feathers from "@feathersjs/client";
import io from "socket.io-client";
// console.log("env", process.env.NODE_ENV);
const url =
  process.env.NODE_ENV === "development" ? "http://localhost:3030" : "";

const socket = io(url, {
  transports: ["websocket"],
  forceNew: true
});
const client = feathers();

client.configure(feathers.socketio(socket));
client.configure(feathers.authentication({ storage: window.localStorage }));
export default client;
