import React, { useState, useEffect, useMemo } from "react";
import PageCard from "commons/components/PageCard";
import LoadingIndicator from "commons/components/LoadingIndicator";
import ErrorAlert from "commons/components/ErrorAlert";
import Stack from "commons/components/Stack";
import useTranslate from "commons/hooks/useTranslate";
import {
  Grid,
  Tooltip,
  IconButton,
  Typography,
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TableBody,
  Checkbox,
  TextField,
  Portal,
  Zoom,
  Fab,
  Box,
} from "@material-ui/core";
import { Print, Save } from "@material-ui/icons";
import { ExcelExporterButton } from "commons/components/ResourceListPage";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";
import { eqProps, uniqWith } from "ramda";
import api from "commons/helpers/api";
import DataCard from "commons/components/DataCard";

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
}));

export default function Manager() {
  const { t } = useTranslate();
  const [customers] = useResourcesByQuery("customers", true);
  const [schools] = useResourcesByQuery("facilities", true);
  const [courses, sendCourses] = useResourcesByQuery("sale-lines");
  const [sessions, sendSessions] = useResourcesByQuery("sessions");
  const [attendances, sendAttendances] = useResourcesByQuery(
    "session-attendances"
  );
  const [changes, setChanges] = useState({});

  const [school, setSchool] = useState(null);
  const [course, setCourse] = useState(null);
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (school) {
      sendCourses("SET_QUERY", {
        query: {
          "sales.facility_id": school,
          "products.type": "COURSE",
        },
      });
    }
  }, [school, sendCourses]);

  useEffect(() => {
    if (school && course) {
      sendSessions("SET_QUERY", {
        query: {
          product_id: course,
        },
      });
    }
  }, [school, course, sendSessions]);

  useEffect(() => {
    if (session) {
      sendAttendances("SET_QUERY", {
        query: {
          session_id: session,
        },
      });
    }
  }, [session, sendAttendances]);

  const uniqCourses = useMemo(() => {
    return uniqWith(eqProps("product_id"), courses);
  }, [courses]);

  const students = useMemo(() => {
    return uniqWith(
      eqProps("customer_id"),
      courses.filter((curr) => curr.product_id === course)
    );
  }, [course, courses]);

  const studentsByKey = useMemo(() => {
    return customers.reduce(
      (acc, curr) => ({ ...acc, [curr.id]: curr.name }),
      {}
    );
  }, [customers]);

  const attendanceByStudent = useMemo(() => {
    return attendances.reduce(
      (acc, curr) => ({ ...acc, [curr.customer_id]: curr }),
      {}
    );
  }, [attendances]);

  const all = useMemo(() => ({ ...attendanceByStudent, ...changes }), [
    attendanceByStudent,
    changes,
  ]);

  const updateAttendance = (customer_id, field, value) => {
    const rec = all[customer_id];
    if (rec) {
      setChanges((old) => ({
        ...old,
        [customer_id]: {
          ...rec,
          [field]: value,
        },
      }));
    } else {
      setChanges((old) => ({
        ...old,
        [customer_id]: {
          customer_id,
          session_id: session,
          notes: "",
          [field]: value,
        },
      }));
    }
  };

  const onSave = () => {
    setLoading(true);
    const records = Object.values(changes);
    const creates = records.filter((rec) => !Boolean(rec.id));
    const updates = records.filter((rec) => Boolean(rec.id));
    const createOp = api.service("session-attendances").create(creates);
    const updateOps = updates.map((rec) =>
      api.service("session-attendances").update(rec.id, rec)
    );
    Promise.all([createOp, ...updateOps])
      .then(() => {
        setChanges({});
      })
      .catch(setError)
      .finally((_) => setLoading(false));
  };

  const present_count = useMemo(
    () => Object.values(all).filter((rec) => rec.status === "PRESENT").length,
    [all]
  );
  const absent_count = useMemo(
    () => Object.values(all).filter((rec) => rec.status === "ABSENT").length,
    [all]
  );
  const execused_count = useMemo(
    () => Object.values(all).filter((rec) => rec.status === "EXECUSED").length,
    [all]
  );

  return (
    <PageCard>
      <LoadingIndicator show={loading} />
      <ErrorAlert error={error} />
      <Stack>
        <SimpleResourceToolbar label="session-attendances" />
        <Grid container spacing={2}>
          <FormSelectField
            grid={4}
            label="facility"
            options={schools}
            value={school}
            onChange={setSchool}
          />
          <FormSelectField
            grid={4}
            label="group"
            options={uniqCourses}
            optionKey="product_id"
            value={course}
            onChange={setCourse}
          />
          <FormSelectField
            grid={4}
            label="session"
            options={sessions}
            optionLabel="starts"
            value={session}
            onChange={setSession}
          />
        </Grid>
        <Grid container spacing={1}>
          <DataCard grid={3} title={t("customers")} value={students.length} />
          <DataCard grid={3} title={t("PRESENT")} value={present_count} />
          <DataCard grid={3} title={t("ABSENT")} value={absent_count} />
          <DataCard grid={3} title={t("EXECUSED")} value={execused_count} />
        </Grid>
        {students.length > 0 && session && (
          <TableContainer>
            <Table>
              <TableHead className={classes.highlight}>
                <TableRow>
                  <TableCell>{t("customer")}</TableCell>
                  <TableCell>{t("PRESENT")}</TableCell>
                  <TableCell>{t("ABSENT")}</TableCell>
                  <TableCell>{t("EXECUSED")}</TableCell>
                  <TableCell>{t("notes")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map(({ customer_id }, index) => {
                  const record = all[customer_id] || false;
                  return (
                    <TableRow
                      key={customer_id}
                      className={index % 2 ? classes.strip : ""}
                      hover
                    >
                      <TableCell>{studentsByKey[customer_id]}</TableCell>
                      <TableCell padding="none">
                        <Checkbox
                          checked={record && record.status === "PRESENT"}
                          onChange={() =>
                            updateAttendance(customer_id, "status", "PRESENT")
                          }
                        />
                      </TableCell>
                      <TableCell padding="none">
                        <Checkbox
                          checked={record && record.status === "ABSENT"}
                          onChange={() =>
                            updateAttendance(customer_id, "status", "ABSENT")
                          }
                        />
                      </TableCell>
                      <TableCell padding="none">
                        <Checkbox
                          checked={record && record.status === "EXECUSED"}
                          onChange={() =>
                            updateAttendance(customer_id, "status", "EXECUSED")
                          }
                        />
                      </TableCell>
                      <TableCell padding="none">
                        {record && (
                          <TextField
                            size="small"
                            value={record.notes}
                            onChange={(e) =>
                              updateAttendance(
                                customer_id,
                                "notes",
                                e.target.value
                              )
                            }
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
      <Portal>
        <Box position="fixed" bottom={24} right={24}>
          <Zoom in style={{ transitionDelay: "250ms" }}>
            <Fab
              color="primary"
              onClick={onSave}
              disabled={Object.keys(changes).length === 0}
            >
              <Save />
            </Fab>
          </Zoom>
        </Box>
      </Portal>
    </PageCard>
  );
}

export function SimpleResourceToolbar({ label }) {
  const { t } = useTranslate();

  return (
    <Toolbar disableGutters>
      <Typography style={{ flex: "1 1 100%" }} variant="h4">
        {t(label)}
      </Typography>
      <ExcelExporterButton data={[]} filename={t(label)} />
      <Tooltip title={t("print")}>
        <IconButton>
          <Print />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}
