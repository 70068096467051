import { useMemo } from "react";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import { prop, propEq } from "ramda";

export default function useFrontDeskExtras() {
  const [discountRaw] = useResourcesByQuery("discounts", true);
  const [discountProduct] = useResourcesByQuery("discount-product", true);
  const [discountPolicy] = useResourcesByQuery("discount-policy", true);

  const [taxRaw] = useResourcesByQuery("taxes", true);
  const [taxPolicy] = useResourcesByQuery("policy-tax", true);

  const [employees] = useResourcesByQuery("employees", true);
  const [commissions] = useResourcesByQuery("employee-product", true);

  const discounts = useMemo(() => {
    return discountRaw.map((discount) => ({
      ...discount,
      products: discountProduct
        .filter(propEq("discount_id", discount.id))
        .map(prop("product_id")),
      policies: discountPolicy
        .filter(propEq("discount_id", discount.id))
        .map(prop("policy_id")),
    }));
  }, [discountRaw, discountProduct, discountPolicy]);

  const taxes = useMemo(() => {
    return taxRaw.map((tax) => ({
      ...tax,
      policies: taxPolicy
        .filter(propEq("tax_id", tax.id))
        .map(prop("policy_id")),
    }));
  }, [taxRaw, taxPolicy]);

  return {
    discounts,
    commissions,
    taxes,
    employees,
  };
}
