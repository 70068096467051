import React, { useState, useEffect, useMemo } from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import FormSelectField from "commons/components/FormSelectField";
import FormSwitch from "commons/components/FormSwitch";
import {
  useBalance,
  TotalsGroup,
  TabbedSection,
  OperationsBalance,
} from "commons/components/Balance";
import Stack from "commons/components/Stack";
import { Grid, Button } from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";
import CardSection from "commons/components/CardSection";
import api from "commons/helpers/api";
import { useMutation } from "react-query";
import LoadingIndicator from "commons/components/LoadingIndicator";
import ErrorAlert from "commons/components/ErrorAlert";

const base = "projects";

export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules,
  } = useControlledResourcePage(base);
  const [customers] = useResourcesByQuery("customers", true);

  return (
    <ResourceSinglePage title={base} current={current} send={send}>
      <FormSelectField
        grid={6}
        options={customers}
        label="customer_id"
        value={model.customer_id}
        onChange={updateModel("customer_id")}
        error={rules.customer_id}
      />
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormSwitch
        label="closed"
        value={model.closed}
        onChange={updateModel("closed")}
      />
      {Boolean(model.id) && (
        <Grid item xs={12}>
          <Balance id={model.id} customer={model.customer_id} />
        </Grid>
      )}
    </ResourceSinglePage>
  );
}

function Balance({ id, customer }) {
  const { balance } = useBalance(id, "balance", "projects", [
    "sales",
    "purchases",
  ]);

  const filter = useMemo(() => {
    if (customer) {
      return {
        customer_id: customer,
      };
    }
    return null;
  }, [customer]);

  const purchasesFilter = useMemo(() => {
    return {};
  }, []);

  return (
    <Stack>
      <TotalsGroup labels={["sales", "purchases", "profit"]} values={balance} />
      <TabbedSection
        labels={["sales", "purchases"]}
        sections={[
          <div>
            <OperationConnect id={id} url="sales" filter={filter} />
            <OperationsBalance url="sales" filter={{ project_id: id }} />
          </div>,
          <div>
            <OperationConnect
              id={id}
              url="purchases"
              filter={purchasesFilter}
            />
            <OperationsBalance url="purchases" filter={{ project_id: id }} />
          </div>,
        ]}
      />
    </Stack>
  );
}

function connect({ id, url, operation }) {
  return api.service(url).patch(operation, {
    project_id: id,
  });
}

function OperationConnect({ url, filter = {}, id }) {
  const { t } = useTranslate();
  const [operation, setOperation] = useState(null);
  const [operations, send] = useResourcesByQuery(url, false);
  const [mutate, { status, error }] = useMutation(connect, {
    throwOnError: true,
  });

  const handleConnect = async () => {
    try {
      await mutate({ id, url, operation });
      setOperation(null);
    } catch (e) {
      console.log(e);
    }
  };

  const isLoading = status === "loading";

  useEffect(() => {
    if (filter) {
      send("SET_QUERY", { query: filter });
    }
  }, [send, filter]);

  return (
    <CardSection>
      <LoadingIndicator show={isLoading} />
      <ErrorAlert error={error} />
      <Grid container spacing={2} alignItems="center">
        <FormSelectField
          grid={4}
          label={url}
          value={operation}
          options={operations}
          optionLabel="reference"
          onChange={setOperation}
        />
        <Grid item sm={2}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={handleConnect}
            disabled={!operation}
          >
            {t("connect")}
          </Button>
        </Grid>
      </Grid>
    </CardSection>
  );
}
