export const prepareDiscount = (discount) => ({
  discount_id: discount.id,
  name: discount.name,
  value: discount.value,
  min_quantity: discount.min_quantity,
  min_price: discount.min_price,
  max_value: discount.max_value,
  applied_value: 0,
});

export const prepareTax = (tax) => ({
  tax_id: tax.id,
  name: tax.name,
  value: tax.value,
  type: tax.type,
  min_price: tax.min_price,
  max_value: tax.max_value,
  applied_value: 0,
});

export const prepareCommission = (commission) =>
  commission.product_id
    ? prepareProductCommission(commission)
    : prepareEmployeeCommission(commission);

const prepareProductCommission = (commission) => ({
  employee_id: commission.employee_id,
  name: commission.name,
  value: commission.value,
  min_price: commission.min_price,
  max_value: commission.max_value,
  applied_value: 0,
});

const prepareEmployeeCommission = (employee) => ({
  employee_id: employee.id,
  name: employee.name,
  commission: employee.sale_commission || "0",
  value: employee.sale_commission || "0",
  min_price: null,
  max_value: null,
  applied_value: 0,
});

export const getProductTypeFields = (product) => {
  switch (product.type) {
    case "AREA":
      return { length: 1, width: 1 };
    case "VOLUME":
      return { length: 1, width: 1, height: 1 };
    case "CAR_RENTAL":
      return { length: product.threshold };
    case "ONCE":
    case "HOURLY":
    case "DAILY":
    case "MONTHLY":
    case "YEARLY":
      return {
        duration_start: product.duration_start,
        duration_end: product.duration_end,
        threshold: product.threshold,
      };
    default:
      return {};
  }
};
