import React from "react";
import FormTextField from "./FormTextField";
import { InputAdornment } from "@material-ui/core";

export function FormQuantityField({
  value,
  min = 0,
  step = 1,
  max = null,
  ...props
}) {
  return (
    <FormTextField
      type="number"
      value={Math.abs(value)}
      inputProps={{
        min: min,
        step: step,
        // ...(max ? { max } : {}),
      }}
      InputProps={{
        endAdornment: max ? (
          <InputAdornment position="start">
            <strong>{max}</strong>
          </InputAdornment>
        ) : null,
      }}
      {...props}
    />
  );
}
