import React, { useMemo, useState, useEffect } from "react";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import {
  Grid,
  Checkbox,
  Typography,
  Box,
  Zoom,
  Fab,
  makeStyles,
} from "@material-ui/core";
import { prop, uniqWith, assoc, adjust, compose } from "ramda";
import useTranslate from "commons/hooks/useTranslate";
import CardSection from "./CardSection";
import { Save } from "@material-ui/icons";
import LoadingIndicator from "./LoadingIndicator";
import ErrorAlert from "./ErrorAlert";
import api from "commons/helpers/api";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import FormTextField from "./FormTextField";

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
}));

const isSameProduct = (a, b) => a.product_id === b.product_id;

export default function ProductConnector({
  id,
  relation,
  relationKey,
  services = false,
  rowRenderer,
  model = {},
  header = null,
}) {
  const { t, language } = useTranslate();
  const [products, , , , hasMoreProducts] = useResourcesByQuery(
    "products",
    true,
    {
      type: {
        $in: services
          ? [
              "ONCE",
              "HOURLY",
              "DAILY",
              "MONTHLY",
              "YEARLY",
              "CAR_RENTAL",
              "CRUISE",
            ]
          : ["PIECE", "WEIGHT", "VOLUME", "AREA"],
      },
    }
  );
  const [allRelations, , , hasMoreRelations] = useResourcesByQuery(
    relation,
    true,
    {
      [relationKey]: id,
      type: {
        $in: services
          ? [
              "ONCE",
              "HOURLY",
              "DAILY",
              "MONTHLY",
              "YEARLY",
              "CAR_RENTAL",
              "CRUISE",
            ]
          : ["PIECE", "WEIGHT", "VOLUME", "AREA"],
      },
    }
  );

  const [relations, setRelations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!hasMoreProducts && !hasMoreRelations) {
      const idles = products.map((prod) => ({
        product_id: prod.id,
        name: prod.name,
        code: prod.code,
        STATUS: "IDLE",
        ...model,
      }));
      const filtered = allRelations.map((rec) => ({ ...rec, STATUS: "SAVED" }));
      setRelations((old) =>
        uniqWith(isSameProduct, [...old, ...filtered, ...idles])
      );
    }
  }, [allRelations, products, model, hasMoreProducts, hasMoreRelations]);

  const onItemChange = (index) => (field) => (val) => {
    const change = compose(assoc("STATUS", "EDITED"), assoc(field, val));
    setRelations(adjust(index, change));
  };

  const onItemAdd = (index) => {
    setRelations(adjust(index, assoc("STATUS", "EDITED")));
  };
  const onItemRemove = (index) => {
    setRelations(adjust(index, assoc("STATUS", "REMOVED")));
  };

  const onSelect = (index, record) => {
    switch (record.STATUS) {
      case "SAVED":
      case "EDITED":
        onItemRemove(index);
        break;
      default:
        //IDLE
        //REMOVED
        onItemAdd(index);
    }
  };

  const allSelected = useMemo(() => {
    return relations.every((relation) =>
      ["EDITED", "SAVED"].includes(relation.STATUS)
    );
  }, [relations]);

  const toggleAllSelected = () => {
    if (allSelected) {
      setRelations((old) => old.map((rec) => ({ ...rec, STATUS: "REMOVED" })));
    } else {
      setRelations((old) =>
        old.map((rec) => {
          if (["REMOVED", "IDLE"].includes(rec.STATUS)) {
            return { ...rec, STATUS: "EDITED" };
          }
          return rec;
        })
      );
    }
  };

  const noChanges = useMemo(() => {
    return relations.every((relation) =>
      ["IDLE", "SAVED"].includes(relation.STATUS)
    );
  }, [relations]);

  const onSubmit = () => {
    setLoading(true);
    setError(false);
    const removedRecords = relations
      .filter((rec) => rec.id && rec.STATUS === "REMOVED")
      .map(prop("id"));

    const updateRecords = relations.filter(
      (rec) => rec.id && rec.STATUS === "EDITED"
    );
    const createRecords = relations.filter(
      (rec) => !rec.id && rec.STATUS === "EDITED"
    );
    const creates = createRecords.map((record) => {
      return api.service(relation).create(record);
    });
    const updates = updateRecords.map((record) => {
      return api.service(relation).update(record.id, record);
    });
    const remove = api.service(relation).remove(null, {
      query: {
        "facility_product.id": {
          $in: removedRecords,
        },
      },
    });
    Promise.all([
      ...updates,
      ...creates,
      ...(removedRecords.length > 0 ? [remove] : []),
    ])
      .then(() => {
        setRelations((old) =>
          old.map((rec) => {
            if (rec.STATUS === "REMOVED") {
              return { ...rec, STATUS: "IDLE" };
            }
            if (rec.STATUS === "EDITED") {
              return { ...rec, STATUS: "SAVED" };
            }
            return rec;
          })
        );
      })
      .catch(setError)
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <LoadingIndicator show={loading || hasMoreRelations || hasMoreProducts} />
      <ErrorAlert error={error} />
      <CardSection p={0}>
        <Grid
          container
          spacing={1}
          alignContent="center"
          justify="center"
          alignItems="center"
        >
          <Grid item xs sm="auto">
            <Checkbox checked={allSelected} onChange={toggleAllSelected} />
          </Grid>
          <Grid item container sm spacing={1}>
            <Grid item xs sm={1}>
              <Typography variant="subtitle1">{t("code")}</Typography>
            </Grid>
            <Grid item xs sm={3}>
              <Typography variant="subtitle1">{t("name")}</Typography>
            </Grid>
            <Grid item container sm={8} spacing={1}>
              {header}
            </Grid>
          </Grid>
        </Grid>
      </CardSection>
      <Box mt={1} minHeight="50vh">
        <AutoSizer disableWidth>
          {({ height }) => (
            <FixedSizeList
              direction={language === "ar" ? "rtl" : "ltr"}
              height={height}
              itemCount={relations.length}
              itemSize={50}
              width="100%"
              overscanCount={5}
              itemData={{
                relations,
                onSelect,
                rowRenderer,
                onItemChange,
              }}
            >
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Box>
      <Box position="fixed" bottom={24} right={24}>
        <Zoom in style={{ transitionDelay: "500ms" }}>
          <Fab color="primary" onClick={onSubmit} disabled={noChanges}>
            <Save />
          </Fab>
        </Zoom>
      </Box>
    </div>
  );
}

function Row({ data, index, style }) {
  const { relations, onSelect, rowRenderer, onItemChange } = data;
  const record = relations[index];
  const classes = useStyles();
  return (
    <div style={style} className={index % 2 ? classes.strip : ""}>
      <Grid container key={record.id}>
        <Grid item xs sm="auto">
          <Checkbox
            checked={["EDITED", "SAVED"].includes(record.STATUS)}
            onChange={() => onSelect(index, record)}
          />
        </Grid>
        <Grid item container sm spacing={1}>
          <FormTextField grid={1} size="small" value={record.code} disabled />
          <FormTextField grid={3} size="small" value={record.name} disabled />
          <Grid item container sm={8} spacing={1}>
            {rowRenderer(record, onItemChange(index, record))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
