import React from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import TemplateBuilder from "./TemplateBuilder";

const base = "form-templates";

export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules,
  } = useControlledResourcePage(base, {}, false);

  return (
    <ResourceSinglePage title={base} current={current} send={send} accessGroup>
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormTextField
        grid={6}
        label="type"
        value={model.type}
        onChange={updateModel("type")}
        error={rules.type}
      />
      <TemplateBuilder
        value={
          typeof model.body === "string" ? JSON.parse(model.body) : model.body
        }
        onChange={(val) => updateModel("body")(JSON.stringify(val))}
        error={rules.body}
      />
    </ResourceSinglePage>
  );
}
