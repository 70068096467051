import React from "react";
import useTranslate from "commons/hooks/useTranslate";
import { prop, remove } from "ramda";
import {
  Grid,
  Tooltip,
  IconButton,
  TextField,
  Box,
  Menu,
  Button,
} from "@material-ui/core";
import {
  Title,
  TextFields,
  ShortText,
  CalendarToday,
  RadioButtonUnchecked,
  CheckBox,
  Delete,
  ArrowDownward,
  ArrowUpward,
  Settings,
  TableChart,
  Add,
  FileCopy,
  ArrowDropDownCircle,
  CloudUpload,
  RemoveCircle,
} from "@material-ui/icons";
import { ComponentsMap } from "./BuilderComponents";
import CardSection from "commons/components/CardSection";
import { Alert } from "@material-ui/lab";
import Stack from "commons/components/Stack";
import FormSwitch from "commons/components/FormSwitch";
import FormTextField from "commons/components/FormTextField";
import { FormNumberField } from "commons/components/FormNumberField";
import FormSelectField from "commons/components/FormSelectField";

const tableCell = {
  value: "",
  readOnly: false,
  // highlighted: false,
  colSpan: 1,
  rowSpan: 1,
};

const addFieldProps = (type) => {
  switch (type) {
    case "heading":
      return { fontSize: 32 };
    case "select":
      return { service: "employees", value: null };
    case "singlechoose":
      return { value: null, options: [] };
    case "multichoose":
      return { value: [], options: [] };
    case "table":
      return {
        value: [
          [{ ...tableCell }, { ...tableCell }],
          [{ ...tableCell }, { ...tableCell }],
        ],
        showIndex: true,
      };
    default:
      return {};
  }
};

export default function FormBuilder({ value = [], onChange, error }) {
  const { t } = useTranslate();

  const generateID = () =>
    value.length > 0 ? Math.max(...value.map(prop("id"))) + 1 : 1;

  const onRemove = (item) => onChange(value.filter((i) => i.id !== item.id));
  const onDuplicate = (item) => {
    onChange([...value, { ...item, id: generateID() }]);
  };

  const switchItemsByIndex = (fst, snd) => {
    const item = value[fst];
    const replaced = value[snd];
    const newValue = value.map((original, i) => {
      if (i === fst) return replaced;
      if (i === snd) return item;
      return original;
    });
    onChange(newValue);
  };

  const onMoveUp = (item) => {
    const index = value.findIndex((i) => i.id === item.id);
    if (index - 1 < 0) return;
    switchItemsByIndex(index, index - 1);
  };

  const onMoveDown = (item) => {
    const index = value.findIndex((i) => i.id === item.id);
    if (index + 1 >= value.length) return;
    switchItemsByIndex(index, index + 1);
  };

  const addItem = (item) => onChange([...value, { id: generateID(), ...item }]);
  const onItemChange = (item) =>
    onChange(value.map((i) => (i.id === item.id ? item : i)));

  const addField = (type) => () => {
    addItem({
      type,
      label: t("label"),
      value: "",
      size: 12,
      ...addFieldProps(type),
    });
  };

  return (
    <Grid item xs={12}>
      {error && <Alert severity="error">{t("rules." + error)}</Alert>}
      <div>
        <Tooltip title={t("addHeading")}>
          <IconButton color="secondary" onClick={addField("heading")}>
            <Title />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("addTextField")}>
          <IconButton color="secondary" onClick={addField("textfield")}>
            <TextFields />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("addTextarea")}>
          <IconButton color="secondary" onClick={addField("textarea")}>
            <ShortText />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("addDateField")}>
          <IconButton color="secondary" onClick={addField("datefield")}>
            <CalendarToday />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("addSingleChoose")}>
          <IconButton color="secondary" onClick={addField("singlechoose")}>
            <RadioButtonUnchecked />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("addMultiChoose")}>
          <IconButton color="secondary" onClick={addField("multichoose")}>
            <CheckBox />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("addSelect")}>
          <IconButton color="secondary" onClick={addField("select")}>
            <ArrowDropDownCircle />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("addTable")}>
          <IconButton color="secondary" onClick={addField("table")}>
            <TableChart />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("addUpload")}>
          <IconButton color="secondary" onClick={addField("upload")}>
            <CloudUpload />
          </IconButton>
        </Tooltip>
      </div>
      <CardSection>
        <Grid container spacing={2} alignItems="flex-start">
          {value.map((item, index) => {
            const CurrentComponent = ComponentsMap[item.type];
            return (
              <Grid item sm={parseInt(item.size)} key={item.id}>
                <Box display="flex" alignItems="flex-start">
                  <CurrentComponent item={item} onChange={onItemChange} />
                  <SettingsComponent
                    item={item}
                    onChange={onItemChange}
                    onRemove={onRemove}
                    onDuplicate={onDuplicate}
                    onMoveUp={onMoveUp}
                    onMoveDown={onMoveDown}
                  />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </CardSection>
    </Grid>
  );
}

const SettingsComponents = {
  heading: HeadingSettings,
  textfield: TextfieldSettings,
  textarea: TextfieldSettings,
  datefield: TextfieldSettings,
  upload: TextfieldSettings,
  singlechoose: RadioSettings,
  multichoose: RadioSettings,
  table: TableSettings,
  select: SelectSettings,
};

function SettingsComponent({
  item,
  onChange,
  onRemove,
  onDuplicate,
  onMoveUp,
  onMoveDown,
}) {
  const Comp = SettingsComponents[item.type];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="settings of component"
        aria-controls="settings"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        <Settings />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        <div style={{ padding: "0.5em" }}>
          <Comp item={item} onChange={onChange} />
          <div className="settings-buttons">
            <IconButton onClick={() => onRemove(item)}>
              <Delete />
            </IconButton>
            <IconButton onClick={() => onDuplicate(item)}>
              <FileCopy />
            </IconButton>
            <IconButton onClick={() => onMoveUp(item)}>
              <ArrowUpward />
            </IconButton>
            <IconButton onClick={() => onMoveDown(item)}>
              <ArrowDownward />
            </IconButton>
          </div>
        </div>
      </Menu>
    </>
  );
}

function HeadingSettings({ item, onChange }) {
  const { t } = useTranslate();
  return (
    <Stack>
      <TextField
        name="title"
        label={t("label")}
        value={item.label}
        onChange={(e) => onChange({ ...item, label: e.target.value })}
      />
      <TextField
        name="fontSize"
        label={t("fontSize")}
        type="number"
        min="12"
        value={item.fontSize}
        onChange={(e) => onChange({ ...item, fontSize: e.target.value })}
      />
    </Stack>
  );
}

function TextfieldSettings({ item, onChange }) {
  const { t } = useTranslate();
  return (
    <Stack>
      <TextField
        name="label"
        label={t("label")}
        value={item.label}
        onChange={(e) => onChange({ ...item, label: e.target.value })}
      />
      <TextField
        name="size"
        label={t("size")}
        type="number"
        min="1"
        max="12"
        value={item.size}
        onChange={(e) => onChange({ ...item, size: e.target.value })}
      />
    </Stack>
  );
}

function RadioSettings({ item, onChange }) {
  const { t } = useTranslate();
  return (
    <Stack>
      <TextField
        name="label"
        label={t("label")}
        value={item.label}
        onChange={(e) => onChange({ ...item, label: e.target.value })}
      />
      <TextField
        name="size"
        label={t("size")}
        type="number"
        min="1"
        max="12"
        value={item.size}
        onChange={(e) => onChange({ ...item, size: e.target.value })}
      />
      <TextField
        name="options"
        label={t("options")}
        value={item.options.join("|")}
        onChange={(e) =>
          onChange({ ...item, options: e.target.value.split("|") })
        }
      />
    </Stack>
  );
}

function SelectSettings({ item, onChange }) {
  const { t } = useTranslate();
  return (
    <Box maxWidth="300px">
      <Grid container spacing={2}>
        <FormTextField
          name="label"
          label={t("label")}
          value={item.label}
          onChange={(e) => onChange({ ...item, label: e })}
        />
        <FormNumberField
          label="size"
          min="1"
          value={item.size}
          onChange={(e) => onChange({ ...item, size: e })}
        />
        <FormSelectField
          label="options"
          value={item.service}
          options={[
            { id: "customers", name: t("customers") },
            { id: "employees", name: t("employees") },
            { id: "products", name: t("products") },
          ]}
          onChange={(e) => onChange({ ...item, service: e })}
        />
      </Grid>
    </Box>
  );
}

function TableSettings({ item, onChange }) {
  const { t } = useTranslate();
  return (
    <Box maxWidth="300px">
      <Grid container spacing={2}>
        <FormTextField
          grid={6}
          label="label"
          value={item.label}
          onChange={(e) => onChange({ ...item, label: e })}
        />
        <FormNumberField
          grid={6}
          label="size"
          min={1}
          max={12}
          value={item.size}
          onChange={(e) => onChange({ ...item, size: e })}
        />
        <Grid item sm={6}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => {
              const columns = Math.max(...item.value.map((row) => row.length));
              onChange({
                ...item,
                value: [
                  ...item.value,
                  Array(columns)
                    .fill()
                    .map((col) => ({ ...tableCell })),
                ],
              });
            }}
            startIcon={<Add />}
          >
            {t("addRow")}
          </Button>
        </Grid>
        <Grid item sm={6}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() => {
              onChange({
                ...item,
                value: remove(item.value.length - 1, 1, item.value),
              });
            }}
            startIcon={<RemoveCircle />}
          >
            {t("removeRow")}
          </Button>
        </Grid>
        <Grid item sm={6}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disableElevation
            onClick={() => {
              const updated = item.value.map((row) => {
                return [...row, { ...tableCell }];
              });
              onChange({
                ...item,
                value: updated,
              });
            }}
            startIcon={<Add />}
          >
            {t("addColumn")}
          </Button>
        </Grid>

        <Grid item sm={6}>
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            disableElevation
            onClick={() => {
              const updated = item.value.map((row) => {
                return remove(row.length - 1, 1, row);
              });
              onChange({
                ...item,
                value: updated,
              });
            }}
            startIcon={<RemoveCircle />}
          >
            {t("removeColumn")}
          </Button>
        </Grid>
        <FormSwitch
          label="showIndex"
          value={item.showIndex}
          onChange={(e) => onChange({ ...item, showIndex: e })}
        />
      </Grid>
    </Box>
  );
}
