import React from "react";
import PageCard from "./PageCard";
import ResourceToolbar from "./ResourceToolbar";
import ResourceEditor from "./ResourceEditor";

export default function ResourceSinglePage({
  title,
  current,
  send,
  children,
  accessGroup,
  notifications,
  toolbarIcons,
  ...props
}) {
  return (
    <PageCard>
      {/* <p>{JSON.stringify(current.context.model)}</p> */}
      <ResourceToolbar
        title={title}
        current={current}
        send={send}
        accessGroup={accessGroup}
        notifications={notifications}
        extras={toolbarIcons}
      />
      <ResourceEditor current={current} send={send} {...props}>
        {children}
      </ResourceEditor>
    </PageCard>
  );
}
