import React from "react";
import ReportManager from "commons/components/ReportManager";

const filters = [
  {
    type: "select",
    name: "service-locations",
    key: "service_location_id",
  },
];

const columns = [
  { name: "service_location", type: "text" },
  { name: "code", type: "text" },
  { name: "name", type: "text" },
  { name: "customer", type: "text" },
  { name: "phone", type: "text" },
  { name: "duration_start", type: "datetime" },
  {
    name: "complete",
    type: "completebutton",
    field: "duration_end",
    base: "sale-lines",
    key: "sale_id",
  },
];

export default function OngoingServicesReport() {
  return (
    <ReportManager
      url="sale-lines"
      title="ongoing-services"
      columns={columns}
      filterByDate
      dateField="sale_lines.duration_start"
      filters={filters}
      initialQuery={{
        "sale_lines.type": "ONCE",
        "sale_lines.duration_end": null,
      }}
    />
  );
}
