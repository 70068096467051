import React from "react";
import PageCard from "commons/components/PageCard";
import ResourceToolbar from "commons/components/ResourceToolbar";
import ResourceEditor from "commons/components/ResourceEditor";
import FormTextField from "commons/components/FormTextField";
import { FormDateField } from "commons/components/FormDateField";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import LinkTabs from "commons/components/LinkTabs";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import Forms from "./Forms";
import {
  useBalance,
  TotalsGroup,
  TabbedSection,
  OperationsBalance,
  PaymentsBalance,
} from "commons/components/Balance";
import Stack from "commons/components/Stack";

const base = "customers";

export default function Standalone() {
  const { current, send, model, updateModel, id } = useControlledResourcePage(
    base
  );
  const hasResource = Boolean(model.id);

  return (
    <PageCard>
      <ResourceToolbar title={base} current={current} send={send} accessGroup />
      <LinkTabs
        show={hasResource}
        tabs={[
          {
            name: "editor",
            path: `/s/customers/${id}`,
            component: (
              <Editor current={current} send={send} updateModel={updateModel} />
            ),
          },
          {
            name: "balance",
            path: `/s/customers/${id}/balance`,
            component: <Balance id={id} type="customers" />,
          },
          {
            name: "forms",
            path: `/s/customers/${id}/forms`,
            component: <Forms id={id} />,
          },
        ]}
      />
    </PageCard>
  );
}

export function Editor({ current, send, updateModel, ...props }) {
  const { model, rules } = current.context;
  const [policies] = useResourcesByQuery("policies", true, { type: "SALES" });

  return (
    <ResourceEditor current={current} send={send} {...props}>
      <FormTextField
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormSelectField
        grid={4}
        label="policy_id"
        options={policies}
        value={model.policy_id}
        onChange={updateModel("policy_id")}
        error={rules.policy_id}
      />
      <FormTextField
        grid={4}
        label="address"
        value={model.address}
        onChange={updateModel("address")}
        error={rules.address}
      />
      <FormDateField
        grid={4}
        label="dob"
        value={model.dob}
        onChange={updateModel("dob")}
        error={rules.dob}
      />
      <FormTextField
        grid={4}
        label="primary_phone"
        value={model.primary_phone}
        onChange={updateModel("primary_phone")}
        error={rules.primary_phone}
      />
      <FormTextField
        grid={4}
        label="secondary_phone"
        value={model.secondary_phone}
        onChange={updateModel("secondary_phone")}
        error={rules.secondary_phone}
      />
      <FormTextField
        grid={4}
        label="email"
        value={model.email}
        onChange={updateModel("email")}
        error={rules.email}
      />
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.notes}
      />
    </ResourceEditor>
  );
}

export function Balance({ id }) {
  const { balance } = useBalance(id, "balance", "customers", [
    "sales",
    "customer-payments",
  ]);

  return (
    <Stack>
      <TotalsGroup
        labels={["sales", "payments", "refunds", "balance"]}
        values={balance}
      />
      <TabbedSection
        labels={["sales", "payments", "refunds"]}
        sections={[
          <OperationsBalance url="sales" filter={{ customer_id: id }} />,
          <PaymentsBalance
            id={id}
            url="customer-payments"
            field="customer_id"
          />,
          <PaymentsBalance
            id={id}
            url="customer-payments"
            field="customer_id"
            refund
          />,
        ]}
      />
    </Stack>
  );
}
