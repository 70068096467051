import React from "react";

export default function Heading({ item }) {
  return (
    <p
      style={{
        margin: "0",
        whiteSpace: "pre",
        fontSize: item.fontSize + "px",
        fontWeight: item.bold ? "bold" : "normal",
        fontStyle: item.italic ? "italic" : "normal",
        textDecoration: item.underlined ? "underline" : "none",
      }}
    >
      {item.value}
    </p>
  );
}
