import React, { useState, useEffect, useCallback } from "react";
import { Grid, Tabs, Tab, IconButton, Box } from "@material-ui/core";
import CardSection from "commons/components/CardSection";
import ResourceListPage from "commons/components/ResourceListPage";
import DataCard from "commons/components/DataCard";
import useTranslate from "commons/hooks/useTranslate";
import api from "commons/helpers/api";
import useResourceMachine from "commons/hooks/useResourceMachine";
import dayjs from "dayjs";
import { FormDateTimeField } from "commons/components/FormDateField";
import { FormMoneyField } from "commons/components/FormMoneyField";
import FormSelectField, {
  DefaultFormSelectField,
} from "commons/components/FormSelectField";
import FormTextField from "commons/components/FormTextField";
import { Save } from "@material-ui/icons";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";

const saleColumns = [
  {
    name: "date",
    type: "datetime",
    sortable: true,
  },
  {
    name: "reference",
    type: "text",
    sortable: true,
  },
  {
    name: "customer",
    type: "text",
    sortable: true,
  },
  {
    name: "total",
    type: "money",
    sortable: true,
  },
  {
    name: "summary",
    type: "text",
    sortable: true,
  },
];

const paymentColumns = [
  {
    name: "date",
    type: "datetime",
    sortable: true,
  },
  {
    name: "amount",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "method",
    type: "translate",
    sortable: true,
    searchable: true,
  },
  {
    name: "reference",
    type: "text",
    sortable: true,
    searchable: true,
  },
];

const commissionsColumns = [
  {
    name: "date",
    type: "datetime",
    sortable: true,
  },
  {
    name: "reference",
    type: "text",
    sortable: true,
  },
  {
    name: "applied_value",
    type: "money",
    sortable: true,
  },
  {
    name: "summary",
    type: "text",
    sortable: true,
  },
];

export function PaymentForm({ url, id, field, refund = false }) {
  const { t } = useTranslate();
  const [currencies] = useResourcesByQuery("currencies", true);

  const { send, updateModel, mergeModel, model, rules } = useResourceMachine(
    url,
    "create",
    {
      [field]: id,
      date: dayjs(),
      method: "CASH",
    },
    false
  );
  const submit = (e) => {
    e.preventDefault();
    send("SAVE");
  };

  const updateAmountInCurrency = (val) => {
    mergeModel({
      amount_in_currency: val,
      amount: (val * model.rate) / 100,
    });
  };

  useEffect(() => {
    if (model.id) {
      send("RESET", { data: {} });
    }
  }, [send, model]);

  const onChangeCurrency = (value) => {
    const currency = currencies.find((cur) => cur.id === value);
    if (currency) {
      mergeModel({
        currency_id: value,
        rate: currency.rate,
        amount_in_currency: 0,
        amount: 0,
      });
    }
  };

  return (
    <CardSection>
      <form onSubmit={submit}>
        <Grid container spacing={1}>
          <Grid item xs>
            <Grid container spacing={1}>
              <FormDateTimeField
                grid={2}
                label="date"
                value={model.date}
                onChange={updateModel("date")}
                error={rules.date}
              />
              <DefaultFormSelectField
                grid={1}
                label="currency"
                options={currencies}
                optionLabel="code"
                value={model.currency_id}
                onChange={onChangeCurrency}
                storageKey="currency"
              />
              <FormMoneyField
                grid={1}
                label="rate"
                value={model.rate}
                disabled
              />
              <FormMoneyField
                grid={2}
                label="amount_in_currency"
                value={Math.abs(model.amount_in_currency)}
                onChange={updateAmountInCurrency}
                error={rules.amount_in_currency}
                disabled={!model.currency_id || !model.rate}
              />
              <FormMoneyField
                grid={2}
                label="amount"
                value={Math.abs(model.amount)}
                error={rules.amount}
                disabled
              />
              <FormSelectField
                grid={2}
                label="payment_method"
                options={[
                  {
                    id: "CASH",
                    name: t("CASH"),
                  },
                  {
                    id: "CREDIT_CARD",
                    name: t("CREDIT_CARD"),
                  },
                  {
                    id: "WIRE_TRANSFER",
                    name: t("WIRE_TRANSFER"),
                  },
                  {
                    id: "CHEQUE",
                    name: t("CHEQUE"),
                  },
                ]}
                value={model.method}
                onChange={updateModel("method")}
                error={rules.method}
              />
              <FormTextField
                grid={2}
                label="reference"
                value={model.reference}
                onChange={updateModel("reference")}
                error={rules.reference}
              />
            </Grid>
          </Grid>
          <Grid item xs="auto">
            <IconButton onClick={submit} type="submit">
              <Save />
            </IconButton>
          </Grid>
        </Grid>
      </form>
    </CardSection>
  );
}

export function useBalance(id, url, type, services = []) {
  const [balance, setBalance] = useState({});

  useEffect(() => {
    if (id) {
      api.service(url).get(id, { query: { type } }).then(setBalance);
    }
  }, [id, url, type]);

  const onDataChange = useCallback(
    (obj) => {
      // const field = getFieldFromType(type);
      // if (parseInt(obj[field]) === parseInt(id)) {
      api.service(url).get(id, { query: { type } }).then(setBalance);
      // }
    },
    [id, url, type]
  );

  useEffect(() => {
    for (let i = 0; i < services.length; i++) {
      const service = services[i];
      api.service(service).on("created", onDataChange);
      api.service(service).on("updated", onDataChange);
      api.service(service).on("patched", onDataChange);
      api.service(service).on("removed", onDataChange);
    }
    return () => {
      for (let i = 0; i < services.length; i++) {
        const service = services[i];
        api.service(service).removeListener("created", onDataChange);
        api.service(service).removeListener("updated", onDataChange);
        api.service(service).removeListener("patched", onDataChange);
        api.service(service).removeListener("removed", onDataChange);
      }
    };
  }, [type, services, onDataChange]);

  return { balance };
}

export function TotalsGroup({ labels = [], values = {}, grids = [] }) {
  return (
    <CardSection>
      <Grid container spacing={1}>
        {labels.map((label, i) => (
          <DataCard
            key={label}
            title={label}
            value={values[label] || 0}
            grid={grids[i]}
          />
        ))}
      </Grid>
    </CardSection>
  );
}

export function TabbedSection({ labels = [], sections = [] }) {
  const { t } = useTranslate();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <CardSection p={0}>
      <Box p={1}>
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          {labels.map((label, i) => (
            <Tab key={label} label={t(label)} />
          ))}
        </Tabs>
        {sections.map((section, i) => (
          <div key={labels[i]} hidden={i !== value}>
            {section}
          </div>
        ))}
      </Box>
    </CardSection>
  );
}

export function PaymentsBalance({ id, url, field, refund = false }) {
  return (
    <>
      <ResourceListPage
        Wrapper={CardSection}
        hideToolbar
        url={url}
        columns={paymentColumns}
        baseQuery={{ [field]: id, amount: { [refund ? "$lt" : "$gt"]: 0 } }}
        createButton={null}
      />
      <PaymentForm url={url} field={field} id={id} refund={refund} />
    </>
  );
}

export function OperationsBalance({ url, filter = {} }) {
  return (
    <ResourceListPage
      Wrapper={CardSection}
      hideToolbar
      url={url}
      columns={saleColumns}
      baseQuery={filter}
      createButton={null}
    />
  );
}

export function CommissionsBalance({ url, filter = {} }) {
  return (
    <ResourceListPage
      Wrapper={CardSection}
      hideToolbar
      url={url}
      columns={commissionsColumns}
      baseQuery={filter}
      createButton={null}
    />
  );
}
