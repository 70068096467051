import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import FormSelectField, {
  DefaultFormSelectField,
} from "commons/components/FormSelectField";
import FormTextField from "commons/components/FormTextField";
import Stack from "commons/components/Stack";
import { FormDateTimeField } from "commons/components/FormDateField";

export default function FrontdeskData({
  model,
  updateModel,
  rules,
  facilities = [],
  products = [],
  onProductSelect,
  children,
  grid = 8,
}) {
  const [code, setCode] = useState("");
  const [name, setName] = useState("");

  const onCodeChange = (e, value) => {
    setCode(value);
  };
  const onNameChange = (e, value) => {
    setName(value);
  };

  const onProductChosen = (product_id) => {
    setCode("");
    setName("");
    onProductSelect(product_id);
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={grid}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Stack>
                <Grid container spacing={2}>
                  <DefaultFormSelectField
                    grid={6}
                    label="facility"
                    options={facilities}
                    value={model.facility_id}
                    onChange={updateModel("facility_id")}
                    error={rules.facility_id}
                    storageKey="sales_facility"
                    disabled={model.lines && model.lines.length > 0}
                  />
                  <FormTextField
                    grid={6}
                    label="reference"
                    value={model.reference}
                    onChange={updateModel("reference")}
                    error={rules.reference}
                  />
                </Grid>
                <FormDateTimeField
                  label="date"
                  value={model.date}
                  onChange={updateModel("date")}
                  error={rules.date}
                />
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack>
                <FormSelectField
                  label="searchByCode"
                  options={products}
                  optionLabel="code"
                  optionKey="product_id"
                  value={null}
                  inputValue={code}
                  onInputChange={onCodeChange}
                  onChange={onProductChosen}
                  autoHighlight
                />
                <FormSelectField
                  label="searchByName"
                  options={products}
                  optionKey="product_id"
                  value={null}
                  onChange={onProductChosen}
                  inputValue={name}
                  onInputChange={onNameChange}
                  autoHighlight
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}
