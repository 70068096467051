import { useMachine } from "@xstate/react";
import { Machine, assign } from "xstate";
import api from "commons/helpers/api";
import { useEffect, useState, useCallback } from "react";

export default function useResourcesByQuery(
  base,
  autoStart = false,
  autoStartQuery = {}
) {
  const machine = resourcesByQueryMachine(base);
  const [current, send] = useMachine(machine);
  const { records, error, query, hasMore } = current.context;
  const [autoStarted, setAutoStarted] = useState(false);

  useEffect(() => {
    if (autoStart && !autoStarted) {
      send("SET_QUERY", { query: autoStartQuery });
      setAutoStarted(true);
    }
  }, [autoStart, autoStarted, autoStartQuery, send]);

  const onDataChange = useCallback(() => {
    send("SET_QUERY", { query });
  }, [send, query]);

  useEffect(() => {
    api.service(base).on("created", onDataChange);
    api.service(base).on("updated", onDataChange);
    api.service(base).on("patched", onDataChange);
    api.service(base).on("removed", onDataChange);
    return () => {
      api.service(base).removeListener("created", onDataChange);
      api.service(base).removeListener("updated", onDataChange);
      api.service(base).removeListener("patched", onDataChange);
      api.service(base).removeListener("removed", onDataChange);
    };
  }, [base, onDataChange]);

  const isLoading = current.matches("fetch") || current.matches("evaluate");

  return [records, send, error, isLoading, hasMore];
}

/********************
 **** The State Machine ****
 *********************/

const setQuery = assign((ctx, evt) => ({
  query: evt.query,
  records: [],
  hasMore: null,
}));
const hasMoreRecords = ({ hasMore }) => Boolean(hasMore);

const invokeFetch = ({ base, query, records }) => {
  // console.log(base, query);
  return api.service(base).find({
    query: { $sort: { id: 1 }, $skip: records.length, ...query },
  });
};
const onSuccess = assign({
  error: () => null,
  hasMore: (_, event) =>
    event.data.limit ? event.data.limit === event.data.data.length : false,
  records: ({ records }, { data }) =>
    data.limit ? [...records, ...data.data] : data,
});
const onError = assign({
  error: (_, { data: error }) => {
    console.log(error.message);
    return error;
  },
});

export const resourcesByQueryMachine = (base) => {
  return Machine({
    id: base,
    context: {
      base,
      query: {},
      records: [],
      hasMore: null,
      error: null,
    },
    initial: "idle",
    states: {
      idle: { id: "idle" },
      fetch: {
        invoke: {
          id: "fetch",
          src: invokeFetch,
          onDone: {
            target: "evaluate",
            actions: onSuccess,
          },
          onError: {
            target: "error",
            actions: onError,
          },
        },
      },
      error: {},
      evaluate: {
        always: [{ target: "fetch", cond: hasMoreRecords }, { target: "idle" }],
      },
    },
    on: {
      SET_QUERY: {
        target: "fetch",
        actions: [setQuery],
      },
    },
  });
};
