import React, { useState, useEffect } from "react";
import useTranslate from "commons/hooks/useTranslate";
import {
  Toolbar,
  Typography,
  IconButton,
  Box,
  TextField,
  MenuItem,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";

import {
  ViewList,
  Add,
  Notifications,
  Print,
  Lock,
  Save,
  History,
  FileCopy,
  Edit,
} from "@material-ui/icons";
import { Link as RouterLink, useHistory } from "react-router-dom";
import ResourceRemoveButton from "./ResourceRemoveButton";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import CardSection from "./CardSection";
import { FormDateTimeField } from "./FormDateField";
import FormTextField from "./FormTextField";
import useResourceMachine from "commons/hooks/useResourceMachine";
import LoadingIndicator from "./LoadingIndicator";
import api from "commons/helpers/api";
import ErrorAlert from "./ErrorAlert";
import dayjs from "dayjs";
import { omit } from "ramda";
import FormSelectField from "./FormSelectField";

export default function ResourceToolbar({
  title,
  current,
  send,
  accessGroup = false,
  notifications = false,
  logs = true,
  extras = null,
}) {
  const { t } = useTranslate();
  const history = useHistory();
  const { model, url } = current.context;

  const onReset = () => send("RESET", { data: {} });
  const onDuplicate = () => send("RESET", { data: omit(["id"], model) });
  const goToList = () => history.push(`/s/${url}`);

  const hasResource = Boolean(model.id);
  const [showNotify, setShowNotify] = useState(false);
  const [showLogs, setShowLogs] = useState(false);

  return (
    <>
      <Toolbar className="resource-toolbar" disableGutters>
        <Typography style={{ flex: "1 1 100%" }} variant="h4">
          {t(title)}
        </Typography>
        <IconButton onClick={onReset}>
          <Add />
        </IconButton>
        <IconButton onClick={onDuplicate}>
          <FileCopy />
        </IconButton>
        <IconButton component={RouterLink} to={`/s/${title}`}>
          <ViewList />
        </IconButton>
        {extras}
        {hasResource && (
          <>
            {notifications && (
              <IconButton
                onClick={() => {
                  setShowNotify((old) => !old);
                }}
              >
                <Notifications />
              </IconButton>
            )}
            {logs && (
              <IconButton
                onClick={() => {
                  setShowLogs((old) => !old);
                }}
              >
                <History />
              </IconButton>
            )}
            <IconButton onClick={window.print}>
              <Print />
            </IconButton>
            <ResourceRemoveButton
              url={url}
              resources={[model.id]}
              onResourcesRemoved={goToList}
            />
          </>
        )}
        {accessGroup && (
          <AccessGroupButton value={model.access_group_id} send={send} />
        )}
      </Toolbar>
      {showNotify && <NotificationManager id={model.id} url={url} />}
      {showLogs && <AuditManager id={model.id} url={url} send={send} />}
    </>
  );
}

export function AccessGroupButton({ value, send }) {
  const { t } = useTranslate();
  const [showAccessGroup, setShowAccessGroup] = useState(false);
  const [groups] = useResourcesByQuery("access-groups", true);

  const onAccessGroupChange = (e) =>
    send("UPDATE", { data: { access_group_id: e.target.value } });

  return (
    <>
      <IconButton onClick={() => setShowAccessGroup((old) => !old)}>
        <Lock />
      </IconButton>
      {showAccessGroup && (
        <Box width={400}>
          <TextField
            select
            label={t("access-group")}
            value={value}
            onChange={onAccessGroupChange}
          >
            {groups.map((group) => (
              <MenuItem key={group.id} value={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}
    </>
  );
}

function NotificationManager({ id, url }) {
  const { t } = useTranslate();
  const {
    model,
    updateModel,
    rules,
    send,
    current,
    error,
  } = useResourceMachine(
    "notifications",
    "create",
    {
      resource_id: id,
      resource_type: url,
    },
    false
  );

  const isDirty = current.matches("dirty") || current.matches("invalid");
  const isLoading = current.matches("fetching") || current.matches("saving");
  const [users] = useResourcesByQuery("users", true);

  useEffect(() => {
    api
      .service("notifications")
      .find({
        query: {
          resource_id: id,
          resource_type: url,
        },
      })
      .then((res) => {
        if (res.length > 0) {
          send("OVERRIDE", { data: res[0] });
        }
      });
  }, [id, url, send]);

  const onRemoveSuccess = () => {
    send("RESET", {
      data: {
        resource_id: id,
        resource_type: url,
      },
    });
  };

  return (
    <CardSection>
      <LoadingIndicator show={isLoading} />
      <ErrorAlert error={error} />
      <Typography variant="h6">{t("notification")}</Typography>
      <Grid container spacing={2}>
        <Grid item container sm spacing={2}>
          <FormSelectField
            grid={4}
            multiple
            disableCloseOnSelect
            label="users"
            options={users}
            optionLabel="username"
            value={model.users}
            onChange={updateModel("users")}
            error={rules.users}
          />
          <FormTextField
            grid={4}
            label="notes"
            value={model.notes}
            onChange={updateModel("notes")}
            error={rules.notes}
          />
          <FormDateTimeField
            grid={4}
            label="reminder"
            value={model.date}
            onChange={updateModel("date")}
            error={rules.date}
          />
        </Grid>
        <Grid item xs sm="auto">
          <IconButton onClick={() => send("SAVE")} disabled={!isDirty}>
            <Save />
          </IconButton>
          {model.id && (
            <ResourceRemoveButton
              url="notifications"
              resources={[model.id]}
              onResourcesRemoved={onRemoveSuccess}
            />
          )}
        </Grid>
      </Grid>
    </CardSection>
  );
}

function AuditManager({ id, url, send }) {
  const [logs] = useResourcesByQuery("audit-logs", true, {
    auditable_id: id,
    auditable_type: url,
  });

  const onSelect = (log) => send("OVERRIDE", { data: log.data });

  return (
    <CardSection p={1}>
      <List>
        {logs.map((log) => (
          <ListItem button key={log.id} onClick={() => onSelect(log)}>
            <ListItemIcon>
              {log.event === "create" ? <Add /> : <Edit />}
            </ListItemIcon>
            <ListItemText
              primary={`${log.user} - ${dayjs(log.date).format(
                "YYYY-MM-DD HH:mm"
              )}`}
            />
          </ListItem>
        ))}
      </List>
    </CardSection>
  );
}

// TODO relation with no audit right now
// Users
// Roles
// Access Groups

// add Salary lines
// All That Has SynMany, should have audti after action, not all
// History of Forms and Templates, some error happens
// Add to Accounts
