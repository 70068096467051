import React from "react";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";
import { Grid } from "@material-ui/core";
import FormFileField from "commons/components/FormFileField";
import FormTextField from "commons/components/FormTextField";
import { FormMoneyField } from "commons/components/FormMoneyField";
import FormSwitch from "commons/components/FormSwitch";
import useTranslate from "commons/hooks/useTranslate";
import ResourceRelations from "commons/components/ResourceRelations";
import { FormNumberField } from "commons/components/FormNumberField";

export default function Editor({ current, send, updateModel }) {
  const { model, rules } = current.context;
  const { t } = useTranslate();
  const [categories] = useResourcesByQuery("categories", true);

  return (
    <>
      <Grid item xs={12} sm={3}>
        <FormFileField
          label="image"
          value={model.image}
          onChange={updateModel("image")}
          error={rules.image}
          preview
        />
        <FormSwitch
          label="saleable"
          value={model.saleable}
          onChange={updateModel("saleable")}
        />
        <FormSwitch
          label="purchasable"
          value={model.purchasable}
          onChange={updateModel("purchasable")}
        />
        <FormSwitch
          label="stockable"
          value={model.stockable}
          onChange={updateModel("stockable")}
        />
        <FormSwitch
          label="active"
          value={model.active}
          onChange={updateModel("active")}
        />
      </Grid>
      <Grid item xs={12} container sm={9} spacing={2}>
        <FormTextField
          grid={6}
          label="name"
          value={model.name}
          onChange={updateModel("name")}
          error={rules.name}
        />
        <FormTextField
          grid={3}
          label="code"
          value={model.code}
          onChange={updateModel("code")}
          error={rules.code}
        />
        <FormTextField
          grid={3}
          label="supplier_code"
          value={model.supplier_code}
          onChange={updateModel("supplier_code")}
          error={rules.supplier_code}
        />
        <FormMoneyField
          grid={2}
          label="sale_price"
          value={model.sale_price}
          onChange={updateModel("sale_price")}
          error={rules.sale_price}
        />
        <FormMoneyField
          grid={2}
          label="purchase_price"
          value={model.purchase_price}
          onChange={updateModel("purchase_price")}
          error={rules.purchase_price}
        />
        <FormMoneyField
          grid={2}
          label="cost_price"
          value={model.cost_price}
          onChange={updateModel("cost_price")}
          error={rules.cost_price}
        />
        <FormSelectField
          grid={3}
          options={categories}
          label="category_id"
          value={model.category_id}
          onChange={updateModel("category_id")}
          error={rules.category_id}
        />
        <FormSelectField
          grid={3}
          options={[
            { id: "PIECE", name: t("PIECE") },
            { id: "WEIGHT", name: t("WEIGHT") },
            { id: "AREA", name: t("AREA") },
            { id: "VOLUME", name: t("VOLUME") }
          ]}
          label="type"
          value={model.type}
          onChange={updateModel("type")}
          error={rules.type}
        />
        <FormTextField
          multiline
          label="description"
          value={model.description}
          onChange={updateModel("description")}
        />
        <PolicyManager
          value={model.policies}
          onChange={updateModel("policies")}
        />
        <VariantManager
          product={model.id}
          value={model.variants}
          onChange={updateModel("variants")}
        />
        <ComponentManager
          product={model.id}
          value={model.components}
          onChange={updateModel("components")}
        />
      </Grid>
    </>
  );
}

function PolicyManager({ value, onChange }) {
  const [policies] = useResourcesByQuery("policies", true);

  return (
    <ResourceRelations
      title="policies"
      value={value}
      onChange={onChange}
      model={{
        policy_id: null,
        sale_price: 0
      }}
      rowRenderer={(record, onItemChange) => (
        <>
          <FormSelectField
            grid={6}
            options={policies}
            label="policy_id"
            value={record.policy_id}
            onChange={onItemChange("policy_id")}
          />
          <FormMoneyField
            grid={6}
            label="sale_price"
            value={record.sale_price}
            onChange={onItemChange("sale_price")}
          />
        </>
      )}
    />
  );
}

function VariantManager({ product, value, onChange }) {
  const [products] = useResourcesByQuery("products", true, {
    "products.id": {
      $ne: product
    }
  });

  return (
    <ResourceRelations
      title="variants"
      value={value}
      onChange={onChange}
      model={{
        variant_id: null,
        product_ratio: 1,
        variant_ratio: 1
      }}
      rowRenderer={(record, onItemChange) => (
        <>
          <FormSelectField
            grid={6}
            options={products}
            label="variant"
            value={record.variant_id}
            onChange={onItemChange("variant_id")}
          />
          <FormNumberField
            grid={3}
            label="product_ratio"
            value={record.product_ratio}
            onChange={onItemChange("product_ratio")}
          />
          <FormNumberField
            grid={3}
            label="variant_ratio"
            value={record.variant_ratio}
            onChange={onItemChange("variant_ratio")}
          />
        </>
      )}
    />
  );
}

function ComponentManager({ product, value, onChange }) {
  const [products] = useResourcesByQuery("products", true, {
    "products.id": { $ne: product }
  });
  return (
    <ResourceRelations
      title="components"
      value={value}
      onChange={onChange}
      model={{
        component_id: null,
        product_ratio: 1,
        component_ratio: 1
      }}
      rowRenderer={(record, onItemChange) => (
        <>
          <FormSelectField
            grid={6}
            options={products}
            label="component"
            value={record.component_id}
            onChange={onItemChange("component_id")}
          />
          <FormNumberField
            grid={3}
            label="product_ratio"
            value={record.product_ratio}
            onChange={onItemChange("product_ratio")}
          />
          <FormNumberField
            grid={3}
            label="component_ratio"
            value={record.component_ratio}
            onChange={onItemChange("component_ratio")}
          />
        </>
      )}
    />
  );
}
