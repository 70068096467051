import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "template",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "form_templates.name",
  },
  {
    name: "customer",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "customers.name",
  },
  {
    name: "user",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "users.username",
  },
  {
    name: "created",
    type: "datetime",
    sortable: true,
    searchable: true,
  },
];

export default function List() {
  return <ResourceListPage url="forms" columns={columns} />;
}
