import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import FormSwitch from "commons/components/FormSwitch";
import ResourceRelations from "commons/components/ResourceRelations";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import { FormDateTimeField } from "commons/components/FormDateField";
import dayjs from "dayjs";
import { Typography, Box, IconButton, Grid } from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";
import {
  PlayCircleFilledOutlined,
  PauseCircleFilledOutlined,
} from "@material-ui/icons";

// const base = "tasks";

export default function Single({ base = "tasks" }) {
  const {
    current,
    send,
    model,
    updateModel,
    rules,
  } = useControlledResourcePage(base);
  const [users] = useResourcesByQuery("users", true);
  const [projects] = useResourcesByQuery("projects", true, { closed: false });

  return (
    <ResourceSinglePage
      title={base}
      current={current}
      send={send}
      accessGroup
      notifications
    >
      <FormTextField
        grid={3}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormSelectField
        grid={3}
        options={users}
        optionLabel="username"
        label="assigned_to"
        value={model.assigned_to}
        onChange={updateModel("assigned_to")}
        error={rules.assigned_to}
      />
      <FormSelectField
        grid={3}
        options={projects}
        label="project"
        value={model.project_id}
        onChange={updateModel("project_id")}
        error={rules.project_id}
      />
      <FormDateTimeField
        grid={3}
        label="due"
        value={model.due}
        onChange={updateModel("due")}
        error={rules.due}
      />
      <FormTextField
        multiline
        rows={3}
        label="body"
        value={model.body}
        onChange={updateModel("body")}
        error={rules.body}
      />
      <FormSwitch
        label="completed"
        value={model.completed}
        onChange={updateModel("completed")}
        error={rules.completed}
      />
      <StepManager value={model.steps} onChange={updateModel("steps")} />
    </ResourceSinglePage>
  );
}

function StepManager({ value = [], onChange }) {
  const { t } = useTranslate();
  const duration = value.reduce((acc, rec) => {
    if (rec.started && rec.finished) {
      const curr = dayjs(rec.finished).diff(dayjs(rec.started), "minute");
      return acc + curr;
    }
    return acc;
  }, 0);

  const footer = (
    <Box p={2} textAlign="center">
      <Typography variant="h6">
        {t("elapsed_duration")}: {Math.floor(duration / 60)} {t("hour")}{" "}
        {duration % 60} {t("minute")}
      </Typography>
    </Box>
  );

  return (
    <ResourceRelations
      title="steps"
      value={value}
      onChange={onChange}
      model={{
        actions: "",
        comment: "",
        started: null,
        finished: null,
      }}
      footer={footer}
      rowRenderer={(record, onItemChange) => (
        <>
          {record.started ? (
            <FormDateTimeField
              grid={3}
              label="started"
              value={record.started}
              onChange={onItemChange("started")}
            />
          ) : (
            <Grid item xs={12} sm={3}>
              <Box textAlign="center">
                <IconButton
                  onClick={() => {
                    onItemChange("started")(dayjs());
                  }}
                >
                  <PlayCircleFilledOutlined />
                </IconButton>
              </Box>
            </Grid>
          )}
          {record.finished && (
            <FormDateTimeField
              grid={3}
              label="finished"
              value={record.finished}
              onChange={onItemChange("finished")}
            />
          )}
          {record.started && !record.finished && (
            <Grid item xs={12} sm={3}>
              <Box textAlign="center">
                <IconButton
                  onClick={() => {
                    onItemChange("finished")(dayjs());
                  }}
                >
                  <PauseCircleFilledOutlined />
                </IconButton>
              </Box>
            </Grid>
          )}
          {!record.started && !record.finished && <Grid item xs={12} sm={3} />}
          <FormTextField
            grid={3}
            label="actions"
            value={record.actions}
            onChange={onItemChange("actions")}
          />
          <FormTextField
            grid={3}
            label="comment"
            value={record.comment}
            onChange={onItemChange("comment")}
          />
        </>
      )}
    />
  );
}
