import React, { useState } from "react";
import useTranslate from "commons/hooks/useTranslate";
import api from "commons/helpers/api";
import { TextField, Box, Zoom, Grid, Fab } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import PageCard from "commons/components/PageCard";

export default function ChangePassword() {
  const { t } = useTranslate();
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const onSubmit = () => {
    api.service("change-password").create({
      password,
      oldPassword,
      passwordConfirm,
    });
  };

  return (
    <PageCard>
      <h2>{t("changePassword")}</h2>
      <form onSubmit={onSubmit} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <TextField
              type="password"
              label={t("oldPassword")}
              value={oldPassword}
              onChange={setOldPassword}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              type="password"
              label={t("password")}
              value={password}
              onChange={setPassword}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              type="password"
              name="passwordConfirm"
              label={t("passwordConfirm")}
              value={passwordConfirm}
              onChange={setPasswordConfirm}
              autoComplete="new-password"
            />
          </Grid>
        </Grid>
        <Box position="fixed" bottom={24} right={24}>
          <Zoom in style={{ transitionDelay: "500ms" }}>
            <Fab color="primary">
              <Save />
            </Fab>
          </Zoom>
        </Box>
      </form>
    </PageCard>
  );
}
