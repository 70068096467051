import React, { useState } from "react";
import useTranslate from "commons/hooks/useTranslate";
import PageCard from "./PageCard";
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Button,
  ButtonGroup,
  Box,
  Grid,
  Portal,
  Zoom,
  Fab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from "@material-ui/core";
import {
  Print,
  FilterList,
  ViewList,
  ArrowRight,
  ArrowLeft,
  Check,
  PieChart,
  CancelOutlined,
  CheckCircleOutline,
  DoneAll,
} from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import dayjs from "dayjs";
import CardSection from "./CardSection";
import { FormDateTimeField } from "commons/components/FormDateField";
import FormSelectField from "./FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import Stack from "./Stack";
import ErrorAlert from "./ErrorAlert";
import LoadingIndicator from "./LoadingIndicator";
import { ExcelExporterButton } from "commons/components/ResourceListPage";
import { prop, groupBy } from "ramda";
import { sumField } from "commons/helpers/utils";
import { VictoryChart, VictoryAxis, VictoryTheme, VictoryBar } from "victory";
import { useMutation } from "react-query";
import api from "commons/helpers/api";

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
}));

export default function ReportManager({
  url,
  title,
  filters = [],
  columns = [],
  totals = [],
  recordsFunc = (data) => data,
  filterByDate = false,
  hasChart = false,
  dateField = "date",
  initialQuery = {},
  children,
}) {
  const { t } = useTranslate();
  const [duration, setDuration] = useState({
    start: dayjs().startOf("DAY"),
    end: dayjs().endOf("DAY"),
  });
  const [showFilters, setShowFilters] = useState(false);
  // const [showChart, setShowChart] = useState(false);
  const [query, setQuery] = useState({});
  const [records, send, error, isLoading] = useResourcesByQuery(url, false);

  const apply = () => {
    const filters = Object.keys(query).reduce(
      (acc, key) => ({
        ...acc,
        ...(query[key] && query[key].length > 0
          ? { [key]: { $in: query[key] } }
          : {}),
      }),
      {}
    );

    send("SET_QUERY", {
      query: {
        ...filters,
        ...(filterByDate
          ? {
              [dateField]: {
                $gte: duration.start,
                $lte: duration.end,
              },
            }
          : {}),
        ...initialQuery,
      },
    });
  };

  const modifiedRecords = recordsFunc(records);

  return (
    <PageCard>
      <LoadingIndicator show={isLoading} />
      <ErrorAlert error={error} />
      <Stack>
        <ReportsToolbar
          title={title}
          filters={filters}
          onFiltersChange={() => setShowFilters(!showFilters)}
          columns={columns}
          records={modifiedRecords}
        />
        {filterByDate && (
          <DurationFilter duration={duration} onChange={setDuration} />
        )}
        {showFilters && (
          <CardSection>
            <Grid container spacing={2}>
              {filters.map(
                ({ name, key, options, extras = {}, filters = {} }) =>
                  !options ? (
                    <FilterField
                      key={name}
                      grid={4}
                      source={name}
                      filters={filters}
                      name={name}
                      label={t(name)}
                      value={query[key]}
                      onChange={(val) =>
                        setQuery((prev) => ({ ...prev, [key]: val }))
                      }
                      {...extras}
                    />
                  ) : (
                    <FormSelectField
                      multiple
                      options={options}
                      key={name}
                      grid={4}
                      label={t(name)}
                      value={query[key]}
                      onChange={(val) =>
                        setQuery((prev) => ({ ...prev, [key]: val }))
                      }
                      {...extras}
                    />
                  )
              )}
            </Grid>
          </CardSection>
        )}
        {children}
        {/* {showChart && <ReportChart data={modifiedRecords} columns={columns} />} */}
        {/* {JSON.stringify(records)} */}
        <ReportTable data={modifiedRecords} columns={columns} totals={totals} />
      </Stack>
      <Portal>
        <Box position="fixed" bottom={24} right={24}>
          <Zoom in style={{ transitionDelay: "250ms" }}>
            <Fab color="primary" onClick={apply}>
              <Check />
            </Fab>
          </Zoom>
        </Box>
      </Portal>
    </PageCard>
  );
}

export function ReportsToolbar({
  title,
  filters = [],
  onFiltersChange,
  columns,
  records,
}) {
  const { t } = useTranslate();

  return (
    <Toolbar disableGutters>
      <Typography style={{ flex: "1 1 100%" }} variant="h4">
        {t(title)}
      </Typography>

      {filters.length > 0 && (
        <IconButton onClick={onFiltersChange}>
          <FilterList />
        </IconButton>
      )}
      {/* {hasChart && (
    <IconButton onClick={() => setShowChart(!showChart)}>
      <PieChart />
    </IconButton>
  )} */}
      <IconButton component={RouterLink} to="/s/reports">
        <ViewList />
      </IconButton>
      <Tooltip title={t("print")}>
        <IconButton>
          <Print />
        </IconButton>
      </Tooltip>
      <ExcelExporterButton
        headers={columns.map(prop("name"))}
        data={records}
        filename={`${t(title)}-${t("report")}`}
      />
    </Toolbar>
  );
}

export function DurationFilter({ duration, onChange }) {
  const { t } = useTranslate();
  const { start, end } = duration;
  const [type, setType] = useState("DAY");

  const setDayDuration = () => {
    onChange({
      start: dayjs().startOf("DAY"),
      end: dayjs().endOf("DAY"),
    });
  };

  const setWeekDuration = () => {
    onChange({
      start: dayjs().startOf("WEEK"),
      end: dayjs().endOf("WEEK"),
    });
  };

  const setMonthDuration = () => {
    onChange({
      start: dayjs().startOf("MONTH"),
      end: dayjs().endOf("MONTH"),
    });
  };

  const setYearDuration = () => {
    onChange({
      start: dayjs().startOf("YEAR"),
      end: dayjs().endOf("YEAR"),
    });
  };

  const voidHandler = () => {};

  const types = [
    { title: "DAY", handler: setDayDuration },
    { title: "WEEK", handler: setWeekDuration },
    { title: "MONTH", handler: setMonthDuration },
    { title: "YEAR", handler: setYearDuration },
    { title: "CUSTOM", handler: voidHandler },
    // { title: "duration", handler: voidHandler }
  ];

  const getDurationText = () => {
    switch (type) {
      case "DAY":
        return dayjs(start).format("YYYY-MM-DD");
      case "WEEK":
        return `${dayjs(start).format("YYYY-MM-DD")} : ${dayjs(end).format(
          "YYYY-MM-DD"
        )}`;
      case "MONTH":
        return dayjs(start).format("YYYY-MM");
      case "YEAR":
        return dayjs(start).format("YYYY");
      case "CUSTOM":
        return `${dayjs(start).format("YYYY-MM-DD HH:mm:ss")} : ${dayjs(
          end
        ).format("YYYY-MM-DD HH:mm:ss")}`;
      default:
        return "-";
    }
  };

  const nextDuration = () => {
    onChange({
      start: dayjs(start).add(1, type),
      end: dayjs(end).add(1, type),
    });
  };

  const previousDuration = () => {
    onChange({
      start: dayjs(start).subtract(1, type),
      end: dayjs(end).subtract(1, type),
    });
  };

  return (
    <CardSection>
      <Toolbar disableGutters>
        <ButtonGroup>
          {types.map((item) => (
            <Button
              key={item.title}
              color="primary"
              variant={type === item.title ? "contained" : "outlined"}
              onClick={(_) => {
                item.handler();
                setType(item.title);
              }}
            >
              {t(item.title)}
            </Button>
          ))}
        </ButtonGroup>
        {type !== "CUSTOM" ? (
          <>
            <p style={{ flex: "1", textAlign: "center" }}>
              <strong>{getDurationText()}</strong>
            </p>
            <div className="arrows">
              <IconButton onClick={nextDuration}>
                <ArrowRight />
              </IconButton>
              <IconButton onClick={previousDuration}>
                <ArrowLeft />
              </IconButton>
            </div>
          </>
        ) : (
          <Box px={2}>
            <Grid container spacing={2}>
              <FormDateTimeField
                grid={6}
                name="start"
                label={t("start")}
                value={start}
                onChange={(val) => onChange({ end, start: val })}
              />
              <FormDateTimeField
                grid={6}
                name="end"
                label={t("end")}
                value={end}
                onChange={(val) => onChange({ start, end: val })}
              />
            </Grid>
          </Box>
        )}
      </Toolbar>
    </CardSection>
  );
}

function FilterField({ source, filters, ...props }) {
  const [items] = useResourcesByQuery(source, true, filters);

  return <FormSelectField multiple options={items} {...props} />;
}

function ReportChart({ data = [], columns = [] }) {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {data.length > 0 && (
        <VictoryChart
          height={200}
          padding={{ top: 10, bottom: 40, left: 20, right: 20 }}
          animate={{ duration: 250 }}
          theme={VictoryTheme.material}
        >
          <VictoryAxis
            style={{
              tickLabels: { fontSize: 6 },
            }}
          />
          <VictoryAxis
            dependentAxis
            tickFormat={(x) => `${x / 100}`}
            style={{
              tickLabels: { fontSize: 6, padding: 15 },
            }}
          />
          <VictoryBar alignment="start" data={data} x="reference" y="total" />
        </VictoryChart>
      )}
    </div>
  );
}

function ReportTable({ data = [], columns = [], totals = [] }) {
  const classes = useStyles();
  const { t } = useTranslate();

  const viewCol = (row, col) => {
    const { name, type } = col;
    const value = row[name];
    switch (type) {
      case "date":
        return !!value ? dayjs(value).format("YYYY-MM-DD") : "";
      case "datetime":
        return !!value ? dayjs(value).format("YYYY-MM-DD HH:mm") : "";
      case "money":
        return !!value ? Math.abs(Math.floor(value) / 100) : 0;
      case "balance":
        return !!value ? Math.floor(value) / 100 : 0;
      case "translate":
        return !!value ? t(value) : "";
      case "boolean":
        return !!value ? <CheckCircleOutline /> : <CancelOutlined />;
      case "multi":
        return Array.isArray(value) ? value.join(" - ") : "";
      case "completebutton":
        return (
          <CompleteButton
            id={row.id}
            field={col.field}
            base={col.base}
            extras={{ [col.key]: row[col.key] }}
          />
        );
      default:
        return value;
    }
  };

  return (
    <TableContainer>
      <Table>
        <TableHead className={classes.highlight}>
          <TableRow>
            <TableCell>{t("table_index")}</TableCell>
            {columns.map((col) => (
              <TableCell key={col.name}>{t(col.label || col.name)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        {data.length > 0 && (
          <tfoot className={classes.highlight}>
            <tr>
              <td></td>
              {columns.map((col) => (
                <td
                  key={col.name}
                  style={{
                    // textAlign: "center",
                    padding: "1em",
                    fontSize: "1rem",
                  }}
                >
                  {totals.includes(col.name) && (
                    <strong>
                      {Math.abs(
                        sumField(col.name)(data) /
                          (["balance", "money"].includes(col.type) ? 100 : 1)
                      )}
                    </strong>
                  )}
                </td>
              ))}
            </tr>
          </tfoot>
        )}
        <TableBody>
          {data.length === 0 && (
            <TableRow>
              <TableCell colSpan={columns.length + 1}>
                {t("noResources")}
              </TableCell>
            </TableRow>
          )}
          {data.length > 0 &&
            data.map((row, index) => (
              <TableRow
                className={index % 2 ? classes.strip : ""}
                hover
                key={index}
              >
                <TableCell>{index + 1}</TableCell>
                {columns.map((col) => (
                  <TableCell key={col.name}>
                    <Box display="block" maxWidth={200}>
                      <Typography variant="body1" noWrap>
                        {viewCol(row, col)}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function completeResource({ id, base, field, extras = {} }) {
  return api.service(base).patch(id, {
    [field]: dayjs(),
    ...extras,
  });
}

function CompleteButton({ id, field, base, extras }) {
  const [mutate, { status, error }] = useMutation(completeResource, {
    throwOnError: true,
  });

  const handleResourceCompleted = async () => {
    try {
      await mutate({ id, field, base, extras });
    } catch (e) {
      console.log(e);
    }
  };

  const isLoading = status === "loading";

  return (
    <>
      <LoadingIndicator show={isLoading} />
      <ErrorAlert error={error} />
      <IconButton
        variant="contained"
        color="primary"
        onClick={handleResourceCompleted}
      >
        <DoneAll />
      </IconButton>
    </>
  );
}

export const totalsColumns = [
  {
    name: "number_of_operations",
    type: "number",
  },
  {
    name: "minimum_ops",
    type: "money",
  },
  {
    name: "avg_ops",
    type: "money",
  },
  {
    name: "maximum_ops",
    type: "money",
  },
  {
    name: "subtotal",
    type: "money",
  },
  {
    name: "discount",
    type: "money",
  },
  {
    name: "tax",
    type: "money",
  },
  {
    name: "total",
    type: "money",
  },
  {
    name: "commission",
    type: "money",
  },
  {
    name: "cost",
    type: "money",
  },
];

export const linesColumns = [
  {
    name: "min_price",
    type: "money",
  },
  {
    name: "avg_price",
    type: "money",
  },
  {
    name: "max_price",
    type: "money",
  },
  {
    name: "avg_cost_price",
    type: "money",
  },
  {
    name: "quantity",
    type: "number",
  },

  {
    name: "returned",
    type: "number",
  },
  {
    name: "actual",
    type: "number",
  },
  {
    name: "subtotal",
    type: "money",
  },
  {
    name: "discount",
    type: "money",
  },
  {
    name: "total",
    type: "money",
  },
  {
    name: "cost",
    type: "money",
  },
  {
    name: "commission",
    type: "money",
  },
];

export const totalsTransformer = (groupFn, key) => (records) =>
  Object.values(groupBy(groupFn, records)).map((group) => {
    const totals = group.reduce(
      (sum, record, index) => ({
        id: record[key],
        ...sum,
        ...record,
        minimum_ops:
          sum.minimum_ops < Number(record.total) && sum.minimum_ops > 0
            ? sum.minimum_ops
            : Number(record.total),
        maximum_ops:
          sum.maximum_ops > Number(record.total)
            ? sum.maximum_ops
            : Number(record.total),
        subtotal: sum.subtotal + Number(record.subtotal),
        total: sum.total + Number(record.total),
        discount: sum.discount + Number(record.discount),
        tax: sum.tax + Number(record.tax),
        commission: sum.commission + Number(record.commission),
        cost: sum.cost + Number(record.cost),
        profit: sum.profit + Number(record.profit),
      }),
      {
        minimum_ops: 0,
        maximum_ops: 0,
        subtotal: 0,
        total: 0,
        discount: 0,
        tax: 0,
        commission: 0,
        cost: 0,
        profit: 0,
      }
    );

    // console.log(totals.total / group.length);

    return {
      ...totals,
      avg_ops: Math.round(totals.total / group.length),
      number_of_operations: group.length,
    };
  });

export const linesTransformer = (groupFn, key) => (records) =>
  Object.values(groupBy(groupFn, records)).map((group) => {
    const totals = group.reduce(
      (sum, record, index) => ({
        id: record[key],
        ...sum,
        ...record,
        min_price:
          sum.min_price < Number(record.price) && sum.min_price > 0
            ? sum.min_price
            : Number(record.price),
        max_price:
          sum.max_price > Number(record.price)
            ? sum.max_price
            : Number(record.price),
        quantity: sum.quantity + Number(record.quantity),
        returned: sum.returned + Number(record.returned),
        actual: sum.actual + Number(record.actual),
        subtotal: sum.subtotal + Number(record.subtotal),
        discount: sum.discount + Number(record.discount),
        total: sum.total + Number(record.total),
        cost: sum.cost + Number(record.cost),
        commission: sum.commission + Number(record.commission),
        profit: sum.profit + Number(record.profit),
      }),
      {
        min_price: 0,
        avg_price: 0,
        max_price: 0,
        avg_cost_price: 0,
        quantity: 0,
        returned: 0,
        actual: 0,
        subtotal: 0,
        discount: 0,
        total: 0,
        cost: 0,
        commission: 0,
        profit: 0,
      }
    );
    return {
      ...totals,
      avg_price: Math.round(totals.total / totals.actual),
      avg_cost_price: Math.round(totals.cost / totals.actual),
    };
  });
