import React from "react";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";
import { Grid } from "@material-ui/core";
import FormFileField from "commons/components/FormFileField";
import FormTextField from "commons/components/FormTextField";
import { FormMoneyField } from "commons/components/FormMoneyField";
import FormSwitch from "commons/components/FormSwitch";
import useTranslate from "commons/hooks/useTranslate";
import ResourceRelations from "commons/components/ResourceRelations";
import { FormNumberField } from "commons/components/FormNumberField";
import { FormDateTimeField } from "commons/components/FormDateField";
import ServiceLocationSelect from "commons/components/ServiceLocationSelect";

export default function Editor({ current, send, updateModel }) {
  const { model, rules } = current.context;
  const { t } = useTranslate();
  const [categories] = useResourcesByQuery("categories", true);

  return (
    <>
      <Grid item xs={12} sm={3}>
        <FormFileField
          label="image"
          value={model.image}
          onChange={updateModel("image")}
          error={rules.image}
          preview
        />
        <FormSwitch
          label="saleable"
          value={model.saleable}
          onChange={updateModel("saleable")}
        />
        <FormSwitch
          label="purchasable"
          value={model.purchasable}
          onChange={updateModel("purchasable")}
        />
        <FormSwitch
          label="active"
          value={model.active}
          onChange={updateModel("active")}
        />
      </Grid>
      <Grid item xs={12} container sm={9} spacing={2}>
        <FormTextField
          grid={6}
          label="name"
          value={model.name}
          onChange={updateModel("name")}
          error={rules.name}
        />
        <FormTextField
          grid={6}
          label="code"
          value={model.code}
          onChange={updateModel("code")}
          error={rules.code}
        />
        <FormMoneyField
          grid={3}
          label="sale_price"
          value={model.sale_price}
          onChange={updateModel("sale_price")}
          error={rules.sale_price}
        />
        <FormMoneyField
          grid={3}
          label="purchase_price"
          value={model.purchase_price}
          onChange={updateModel("purchase_price")}
          error={rules.purchase_price}
        />
        <FormMoneyField
          grid={3}
          label="cost_price"
          value={model.cost_price}
          onChange={updateModel("cost_price")}
          error={rules.cost_price}
        />
        <FormSelectField
          grid={3}
          options={categories}
          label="category_id"
          value={model.category_id}
          onChange={updateModel("category_id")}
          error={rules.category_id}
        />
        <FormSelectField
          grid={3}
          options={[
            { id: "ONCE", name: t("ONCE") },
            { id: "HOURLY", name: t("HOURLY") },
            { id: "DAILY", name: t("DAILY") },
            { id: "MONTHLY", name: t("MONTHLY") },
            { id: "YEARLY", name: t("YEARLY") },
          ]}
          label="type"
          value={model.type}
          onChange={updateModel("type")}
          error={rules.type}
        />
        <FormNumberField
          grid={3}
          label="threshold"
          value={model.threshold}
          onChange={updateModel("threshold")}
          error={rules.threshold}
          disabled={model.type === "ONCE"}
        />
        <FormDateTimeField
          grid={3}
          label="duration_start"
          value={model.duration_start}
          onChange={updateModel("duration_start")}
          error={rules.duration_start}
          disabled={model.type !== "ONCE"}
        />
        <FormDateTimeField
          grid={3}
          label="duration_end"
          value={model.duration_end}
          onChange={updateModel("duration_end")}
          error={rules.duration_end}
          disabled={model.type !== "ONCE"}
        />
        <FormTextField
          multiline
          label="description"
          value={model.description}
          onChange={updateModel("description")}
        />
        <PolicyManager
          value={model.policies}
          onChange={updateModel("policies")}
        />
        <LocationManager
          value={model.locations}
          onChange={updateModel("locations")}
        />
        {/* <SessionManager
          locations={model.locations}
          value={model.sessions}
          onChange={updateModel("sessions")}
        /> */}
        <ComponentManager
          product={model.id}
          value={model.components}
          onChange={updateModel("components")}
        />
      </Grid>
    </>
  );
}

function PolicyManager({ value, onChange }) {
  const [policies] = useResourcesByQuery("policies", true);

  return (
    <ResourceRelations
      title="policies"
      value={value}
      onChange={onChange}
      model={{
        policy_id: null,
        sale_price: 0,
      }}
      rowRenderer={(record, onItemChange) => (
        <>
          <FormSelectField
            grid={6}
            options={policies}
            label="policy_id"
            value={record.policy_id}
            onChange={onItemChange("policy_id")}
          />
          <FormMoneyField
            grid={6}
            label="sale_price"
            value={record.sale_price}
            onChange={onItemChange("sale_price")}
          />
        </>
      )}
    />
  );
}

export function LocationManager({ value, onChange }) {
  return (
    <ResourceRelations
      title="service-locations"
      value={value}
      onChange={onChange}
      model={{
        service_location_id: null,
        max_allowed: 0,
      }}
      rowRenderer={(record, onItemChange) => (
        <>
          <Grid item xs={12} sm={8}>
            <ServiceLocationSelect
              value={record.service_location_id}
              onChange={onItemChange("service_location_id")}
            />
          </Grid>
          <FormNumberField
            grid={4}
            label="max_allowed"
            value={record.max_allowed}
            onChange={onItemChange("max_allowed")}
          />
        </>
      )}
    />
  );
}

// function SessionManager({ locations = [], value, onChange }) {
//   const [allLocations] = useResourcesByQuery("service-locations", true);

//   const allowedLocations = useMemo(() => {
//     const ids = locations.map(prop("service_location_id"));
//     return allLocations.filter((loc) => ids.includes(loc.id));
//   }, [allLocations, locations]);

//   return (
//     <ResourceRelations
//       title="sessions"
//       value={value}
//       onChange={onChange}
//       model={{
//         service_location_id: null,
//         starts: null,
//         ends: null,
//       }}
//       rowRenderer={(record, onItemChange) => (
//         <>
//           <FormSelectField
//             grid={6}
//             options={allowedLocations}
//             label="service-location"
//             value={record.service_location_id}
//             onChange={onItemChange("service_location_id")}
//           />
//           <FormDateTimeField
//             grid={3}
//             label="starts"
//             value={record.starts}
//             onChange={onItemChange("starts")}
//           />
//           <FormDateTimeField
//             grid={3}
//             label="ends"
//             value={record.ends}
//             onChange={onItemChange("ends")}
//           />
//         </>
//       )}
//     />
//   );
// }

function ComponentManager({ product, value, onChange }) {
  const [products] = useResourcesByQuery("products", true, {
    "products.id": { $ne: product },
  });
  return (
    <ResourceRelations
      title="components"
      value={value}
      onChange={onChange}
      model={{
        component_id: null,
        product_ratio: 1,
        component_ratio: 1,
      }}
      rowRenderer={(record, onItemChange) => (
        <>
          <FormSelectField
            grid={6}
            options={products}
            label="component"
            value={record.component_id}
            onChange={onItemChange("component_id")}
          />
          <FormNumberField
            grid={3}
            label="product_ratio"
            value={record.product_ratio}
            onChange={onItemChange("product_ratio")}
          />
          <FormNumberField
            grid={3}
            label="component_ratio"
            value={record.component_ratio}
            onChange={onItemChange("component_ratio")}
          />
        </>
      )}
    />
  );
}
