import React from "react";
import ReportManager from "commons/components/ReportManager";
import useTranslate from "commons/hooks/useTranslate";

const columns = [
  {
    name: "auditable_type",
    type: "translate",
  },
  {
    name: "auditable_id",
    type: "text",
  },
  {
    name: "event",
    type: "translate",
  },
  {
    name: "user",
    type: "text",
  },
];

export default function AuditReport() {
  const { t } = useTranslate();

  const filters = [
    {
      type: "select",
      name: "users",
      key: "user_id",
      extras: { optionLabel: "username" },
    },
    {
      type: "select",
      name: "event",
      key: "event",
      options: [
        { id: "create", name: t("create") },
        { id: "update", name: t("update") },
        { id: "remove", name: t("remove") },
      ],
    },
  ];

  return (
    <ReportManager
      url="audit-logs"
      title="audit-logs"
      filters={filters}
      columns={columns}
      filterByDate
      dateField="date"
    />
  );
}
