import {
  ifElse,
  includes,
  without,
  append,
  clamp,
  prop,
  map,
  sum,
  compose,
} from "ramda";

const withoutSingle = (a, list) => without([a], list);

export const toggle = ifElse(includes, withoutSingle, append);

export const rulesReducer = (acc, { field, validation }) => ({
  ...acc,
  [field]: validation,
});

// export const SumFieldReducer = (field) => (total, record) =>
//   (total += Number(record[field]) || 0);
export const sumField = (field) => compose(sum, map(prop(field)));

export const parseValue = (text, amount, min, max) => {
  const isPercentage = text ? text.indexOf("%") !== -1 : false;
  const value = parseFloat(text) * 100;
  const applied = isPercentage ? Math.round((value / 10000) * amount) : value;
  const op = applied < 0 ? -1 : 1;
  const clamped =
    min || max ? clamp(min, max, Math.abs(applied)) * op : applied;
  return clamped;
};

// export const formatDate = (date = Date.now()) => {
//   const d = new Date(date);
//   let month = "" + (d.getMonth() + 1);
//   let day = "" + d.getDate();
//   let year = d.getFullYear();

//   if (month.length < 2) month = "0" + month;
//   if (day.length < 2) day = "0" + day;

//   return [year, month, day].join("-");
// };

// const padded = value => String(value).padStart(2, "0");
